import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from "recharts";
import { jsPDF } from "jspdf";
import pptxgen from "pptxgenjs";
import html2canvas from "html2canvas";
import WordCloud from "react-wordcloud";
import "../CSS/CustomReports.css";
import "../CSS/ReportResults.css";
import Header from "../Components/Header";
import { Toaster, toast } from "sonner";
import { ThemeContext } from "../Components/ThemeContext";
import { FaEye, FaFilePowerpoint, FaFilePdf, FaChevronLeft } from "react-icons/fa";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import { FaChartSimple } from "react-icons/fa6";
import { PiCoins, PiCoinsBold } from "react-icons/pi";
import { LuCalendarClock } from "react-icons/lu";


const ReportResults = () => {
    const { orgId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const logoDark = process.env.PUBLIC_URL + '/socialDark.png';
    const [organizationData, setOrganizationData] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));
    const { theme } = useContext(ThemeContext);
    const { data, topSourcesData, categorizedData, wordCloudData, formData, organizationName, organizationAlias } = location.state || {};

    const sections = ["cover", "contents", "summary", "media", "insights", "reputation", "tonality", "wordcloud"];
    const [currentSection, setCurrentSection] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const textColor = getComputedStyle(document.body).getPropertyValue('--small-text-color')?.trim() || '#333';
    const tooltipBg = getComputedStyle(document.body).getPropertyValue('--filters-bg')?.trim() || '#555';

    // Get selected organization
    const selectedOrg = localStorage.getItem("selectedOrg") || orgId || "";

    // Function to get organization-specific styles
    const getThemeForOrg = (orgId) => {
        const themes = {
            "672a0e55dcdacf17623951c9": {
                background: "radial-gradient(circle, #009999 0%, #006666 100%)",
                borderColor: "#004d4d" // Darker teal
            },
            "672a1508dcdacf176239524b": {
                background: "radial-gradient(circle, #112369cc 20%, #112369 100%)",
                borderColor: "#0b1a4a" // Darker navy
            },
            "672a1e46dcdacf1762395320": {
                background: "radial-gradient(circle, rgba(24, 68, 124, 0.8) 20%, #18447c 100%)",
                borderColor: "#102f5a" // Darker blue
            },
            "67bb7c555a97202660dd4cc5": {
                background: "radial-gradient(circle, rgba(0, 160, 188, 0.6) 20%,rgb(0, 147, 173) 100%)",
                borderColor: "#0085a3" // Darker cyan
            },
            "67bcd15df8fb912e0469635c": {
                background: "radial-gradient(circle, rgba(0, 0, 0, 0.8) 20%, #000 100%)",
                borderColor: "#000" // Black (no darker shade needed)
            }
        };

        return themes[orgId] || {
            background: "radial-gradient(circle, #ccc 0%, #999 100%)",
            borderColor: "#666" // Default gray border
        };
    };

    // Get the theme for the current organization
    const orgTheme = getThemeForOrg(selectedOrg);

    if (!data) {
        return <div className="error-message">No report data available. Please generate a report first.</div>;
    }

    // Data for charts
    const chartData = data.map((item) => ({
        name: item.contentType.charAt(0).toUpperCase() + item.contentType.slice(1),
        value: Math.round((item.count / data.reduce((sum, item) => sum + item.count, 0)) * 100),
        count: item.count,
    }));

    const volumeData = topSourcesData.map((source) => ({
        name: source.source || "Unknown",
        volume: source.count
    }));

    const tonalityData = topSourcesData.map((source) => ({
        name: source.source || "Unknown",
        positive: source.positive,
        neutral: source.neutral,
        negative: source.negative
    }));

    // Function to generate insights for each content type
    const generateInsights = (entry) => {
        const contentData = data.find((item) => item.contentType === entry.name.toLowerCase()) || {};

        let reachText = contentData.totalReach > 1000000
            ? `garnered the highest audience reach with ${contentData.totalReach.toLocaleString()} impressions, making them a crucial driver of visibility.`
            : contentData.totalReach > 0
                ? `achieved a moderate reach of ${contentData.totalReach.toLocaleString()}, indicating steady engagement.`
                : "did not have detected audience reach data for this period.";

        let aveText = contentData.totalAVE > 500000
            ? `Its media value was exceptionally high at BWP ${contentData.totalAVE.toLocaleString()}, showing strong financial significance.`
            : contentData.totalAVE > 0
                ? `contributed an estimated BWP ${contentData.totalAVE.toLocaleString()} in media value.`
                : "did not generate any measurable media value in this period.";

        return `${entry.name} accounted for ${entry.value}% of the total media coverage. They ${reachText} ${aveText}`;
    };


    // Function to generate filename dynamically
    const generateFilename = (extension) => {
        const orgName = organizationName.replace(/\s+/g, "_"); // Replace spaces with underscores
        const startDate = formData.startDate?.toISOString().split("T")[0];
        const endDate = formData.endDate?.toISOString().split("T")[0];
        return `SL_${orgName}MediaReport_${startDate}-${endDate}.${extension}`;
    };


    const exportToPDF = () => {
        setIsModalOpen(false); // Close modal
        setLoading(true); // Show loading spinner
        document.body.classList.add("export-mode", "pdf");

        setTimeout(() => {
            const doc = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });
            const reportSections = document.querySelectorAll(".report-section");

            const sections = document.querySelectorAll(".report-section");
            sections.forEach((section) => section.classList.add("force-visible"));

            let yOffset = 10; // Margin from the top

            const captureSection = async (section, callback) => {
                await new Promise((resolve) => setTimeout(resolve, 300)); // Ensure elements load

                html2canvas(section, {
                    scale: 3, // Increase scale for better quality
                    useCORS: true,
                    width: section.scrollWidth, // Ensures full section width is captured
                    height: section.scrollHeight,// Capture full content height
                }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");

                    const pageWidth = 210; // A4 width in mm
                    const pageHeight = 297; // A4 height in mm

                    const imgWidth = pageWidth - 20; // Reduce by margins
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    const xPos = (pageWidth - imgWidth) / 2; // Centers horizontally
                    const yPos = 10; // Top margin

                    if (yOffset + imgHeight > pageHeight) {
                        doc.addPage();
                        yOffset = 10;
                    }

                    doc.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
                    yOffset += imgHeight + 10;
                    callback();
                }).catch((err) => {
                    console.error("Error capturing section:", err);
                });
            };

            const processSections = (index) => {
                if (index < reportSections.length) {
                    captureSection(reportSections[index], () => processSections(index + 1));
                } else {
                    doc.save(generateFilename("pdf"));
                    document.body.classList.remove("export-mode", "pdf");
                    sections.forEach((section) => section.classList.remove("force-visible"));
                    setLoading(false); // Hide loading spinner
                }
            };

            processSections(0);
        }, 1000);
    };


    const exportToPPT = () => {
        setIsModalOpen(false); // Close modal
        setLoading(true); // Show loading spinner
        document.body.classList.add("export-mode", "ppt");

        setTimeout(() => {
            let ppt = new pptxgen();
            ppt.layout = "LAYOUT_WIDE"; // Set 16:9 widescreen layout
            const reportSections = document.querySelectorAll(".report-section");

            const sections = document.querySelectorAll(".report-section");
            sections.forEach((section) => section.classList.add("force-visible"));

            const captureSection = async (section, callback) => {
                await new Promise((resolve) => setTimeout(resolve, 300)); // Ensure elements load

                html2canvas(section, {
                    scale: 3, // High resolution
                    useCORS: true,
                    width: 1350, // 16:9 width
                    height: 780, // 16:9 height
                }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");

                    let slide = ppt.addSlide();
                    slide.addImage({
                        data: imgData,
                        x: "10%", // Center horizontally
                        y: "10%", // Center vertically
                        w: "90%", // Use most of the slide width
                        h: "90%" // Use most of the slide height
                    });

                    callback();
                }).catch((err) => {
                    console.error("Error capturing section:", err);
                });
            };

            const processSections = (index) => {
                if (index < reportSections.length) {
                    captureSection(reportSections[index], () => processSections(index + 1));
                } else {
                    ppt.writeFile(generateFilename("pptx"));
                    document.body.classList.remove("export-mode", "ppt");
                    sections.forEach((section) => section.classList.remove("force-visible"));
                    setLoading(false); // Hide loading spinner
                }
            };

            processSections(0);
        }, 1000);
    };


    // Navigation functions
    const goToNext = () => {
        if (currentSection < sections.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const goToPrevious = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    return (
        <div>
            {
                loading && (
                    <div className="report-spinner-container">
                        <div className="report-spinner"></div>
                    </div>
                )
            }
            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="report-modal-content" onClick={(e) => e.stopPropagation()}>
                        <h4 className="report-modal-title">Export As:</h4>
                        <button onClick={exportToPDF} className="modal-btn">
                            <FaFilePdf className="icon pdf-icon" />
                            <span>Export PDF</span></button>
                        <button onClick={exportToPPT} className="modal-btn">
                            <FaFilePowerpoint className="icon ppt-icon" /><span>Export PPT</span></button>
                        <button className="report-modal-close" onClick={() => setIsModalOpen(false)}>&times;</button>
                    </div>
                </div>
            )}
            <div className="container">
                <Header
                    userName={`${user.firstName} ${user.lastName}`}
                    userRole={user.role}
                />
            </div>
            <div className="content">
                <div className="holder">
                    <div className="exports-area">
                        <h2 className="report-title">
                            <button className="report-back-btn" onClick={() => navigate(-1)}>
                                <FaChevronLeft /></button>
                            {organizationName}'s Reports</h2>

                        {/* Floating Export Buttons Modal */}
                        <button className="export-btn" onClick={() => setIsModalOpen(true)}>Export Report</button>

                    </div>

                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className={`report-section ${currentSection === index ? "visible" : "hidden"}`}
                        >
                            {section === "cover" && (
                                <div className="report-cover" style={{ background: orgTheme.background, border: `2px solid ${orgTheme.borderColor}` }}>
                                    {/* Cover Page */}
                                    <div className="cover-header">
                                        <Link to="/">
                                            <img src={logoDark} alt="logo" className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="cover-content">
                                        <h3 className="underline">Media Monitoring Analysis</h3>
                                        <p className="cover-subtitle">Insights on Recent Media Coverage</p>

                                    </div>
                                    <div className="prepared-section">
                                        <div className="prepared-box">
                                            <p className="cover-date">{formData.startDate?.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })} -
                                                {formData.endDate?.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                                            </p>
                                        </div>
                                        <div className="prepared-box">
                                            <p className="prepared-label">Prepared by Social Light Botswana for</p>
                                            <p className="prepared-name">{organizationAlias || organizationName}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {section === "contents" && (
                                <div className="report-contents">
                                    {/* Table of Contents */}
                                    <h3 className="underline">Report Data & Contents</h3>
                                    <div className="summary-contents">
                                        <ul className="contents-list">
                                            <li>Media Coverage Summary</li>
                                            <li>Key Insights</li>
                                            <li>Reputation Analysis</li>
                                            <li>Sentiment Trends</li>
                                            <li>Tonality Trends</li>
                                            <li>Word Cloud</li>
                                            <li>Observations</li>
                                        </ul>
                                    </div>
                                </div>
                            )}


                            {section === "summary" && (
                                <div className="report-summary">
                                    {/* Summary Page */}
                                    <h3 className="underline">Summary</h3>
                                    <div className="summary-metrics">
                                        <div className="metric-item">
                                            <FaChartSimple className="summary-icon" />
                                            <p className="metric-label">Volume:</p>
                                            <p className="metric-value">{data.reduce((sum, item) => sum + item.count, 0)}</p>
                                        </div>
                                        <div className="metric-item">
                                            <PiCoinsBold className="summary-icon" />
                                            <p className="metric-label">Total AVE:</p>
                                            <p className="metric-value">
                                                BWP {data.reduce((sum, item) => sum + item.totalAVE, 0).toLocaleString()}
                                            </p>
                                        </div>
                                        <div className="metric-item">
                                            <FaEye className="summary-icon" />
                                            <p className="metric-label">Total Reach:</p>
                                            <p className="metric-value">
                                                {data.reduce((sum, item) => sum + item.totalReach, 0).toLocaleString()}
                                            </p>
                                        </div>
                                        <div className="metric-item">
                                            <LuCalendarClock className="summary-icon" />
                                            <p className="metric-label">Time Period:</p>
                                            <p className="metric-value">
                                                {formData.startDate?.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })} -
                                                {formData.endDate?.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="metric-note">
                                        *Volume refers to mentions across all sources during the selected period.
                                    </p>
                                </div>
                            )}


                            {/* Media Coverage Summary */}
                            {section === "media" && (
                                <div className="report-media">
                                    <h3 className="underline">Media Coverage Summary</h3>
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                                <th>Content Type</th>
                                                <th>Count</th>
                                                <th>Total AVE</th>
                                                <th>Total Reach</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.contentType || "Unknown"}</td>
                                                    <td>{item.count}</td>
                                                    <td>{item.totalAVE.toLocaleString() || "Unavailable"}</td>
                                                    <td>{item.totalReach.toLocaleString() || "Unavailable"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <br />
                                    {/* Percentage Breakdown */}
                                    <h4 className="insights-title">Top Performing Content Types</h4>
                                    <div className="chart-grid">
                                        {chartData.map((entry, index) => (
                                            <div key={index} className="chart-item">
                                                <PieChart width={125} height={125}>
                                                    <Pie
                                                        data={[
                                                            { value: entry.value },
                                                            { value: 100 - entry.value }
                                                        ]}
                                                        startAngle={180}
                                                        endAngle={0}
                                                        innerRadius={25}
                                                        outerRadius={60}
                                                        dataKey="value"
                                                    >
                                                        <Cell fill="#1d6be8d4" />
                                                        <Cell fill="#352ff7d4" />
                                                    </Pie>
                                                </PieChart>
                                                <p className="chart-label">{entry.value}%</p>
                                                <p className="chart-name">{entry.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Key Insights Section */}
                            {section === "insights" && (
                                <div className="report-insights">
                                    <h3 className="underline">Key Insights</h3>
                                    <div className="insights-container">
                                        {chartData.map((entry, index) => (
                                            <div key={index} className="insight-box">
                                                <h3 className="insight-title">{entry.name}</h3>
                                                <p className="insight-text">{generateInsights(entry)}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}


                            {/* Reputation Pillars Section - Table Format */}
                            {section === "reputation" && (
                                <div className="report-reputation">
                                    <h3 className="underline">Reputation Analysis</h3>
                                    <table className="pillars-table">
                                        <thead>
                                            <tr>
                                                <th>Pillar</th>
                                                <th>Positive</th>
                                                <th>Neutral</th>
                                                <th>Negative</th>
                                                <th>Volume</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categorizedData.map((pillar, index) => (
                                                <tr key={index}>
                                                    <td>{pillar.pillar}</td>
                                                    <td>{pillar.positive}</td>
                                                    <td>{pillar.neutral}</td>
                                                    <td>{pillar.negative}</td>
                                                    <td>{pillar.count}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {/* Tonality */}
                            {section === "tonality" && (
                                <div className="report-tonality">
                                    <h3 className="underline">Tonality Trends</h3>
                                    <div className="charts-wrapper">
                                        <div className="chart-box">
                                            <h4>Volume</h4>
                                            <ResponsiveContainer width="100%" height={180}>
                                                <BarChart layout="vertical" data={volumeData} margin={{ left: 50 }}>
                                                    <XAxis type="number" />
                                                    <YAxis dataKey="name" type="category" width={150} tick={{
                                                        fill: textColor,
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                        fontFamily: "Raleway",
                                                    }} />
                                                    <Tooltip
                                                        contentStyle={{
                                                            backgroundColor: tooltipBg, // Tooltip background
                                                            borderRadius: "6px",
                                                            border: "none",
                                                            color: textColor, // Text color
                                                            fontFamily: "Nunito",
                                                            fontSize: "13px",
                                                            fontWeight: "400",
                                                        }}
                                                        itemStyle={{
                                                            color: textColor,
                                                        }}
                                                        labelStyle={{
                                                            color: textColor,
                                                            fontWeight: "700",
                                                            fontFamily: "Raleway", // Tooltip item text color
                                                        }}
                                                    />
                                                    <Bar dataKey="volume" fill="#327ed0" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>

                                        <div className="chart-box">
                                            <h4>Tonality</h4>
                                            <ResponsiveContainer width="100%" height={180}>
                                                <BarChart layout="vertical" data={tonalityData} margin={{ left: 50 }}>
                                                    <XAxis type="number" />
                                                    <YAxis dataKey="name" type="category" width={150} tick={{
                                                        fill: textColor,
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                        fontFamily: "Raleway",
                                                    }} />
                                                    <Tooltip
                                                        contentStyle={{
                                                            backgroundColor: tooltipBg, // Tooltip background
                                                            borderRadius: "6px",
                                                            border: "none",
                                                            color: textColor, // Text color
                                                            fontFamily: "Nunito",
                                                            fontSize: "13px",
                                                            fontWeight: "400",
                                                        }}
                                                        itemStyle={{
                                                            color: textColor,
                                                        }}
                                                        labelStyle={{
                                                            color: textColor,
                                                            fontWeight: "700",
                                                            fontFamily: "Raleway", // Tooltip item text color
                                                        }}
                                                    />
                                                    <Legend />
                                                    <Bar dataKey="negative" stackId="a" fill="#E74C3C" name="Negative" />
                                                    <Bar dataKey="neutral" stackId="a" fill="#F4D03F" name="Neutral" />
                                                    <Bar dataKey="positive" stackId="a" fill="#27AE60" name="Positive" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Word Cloud Page */}
                            {section === "wordcloud" && (
                                <div className="report-wordcloud">
                                    <h3 className="underline" style={{ marginBottom: "60px" }}>
                                        Top Keywords from Media Coverage
                                    </h3>
                                    <div className="insights-container">
                                        {/* Word Cloud */}
                                        <div className="wordcloud-circle">
                                            <ResponsiveContainer width="100%" height={400}>
                                                <WordCloud
                                                    words={wordCloudData}
                                                    options={{
                                                        rotations: 2,
                                                        rotationAngles: [-90, 0],
                                                        fontSizes: [15, 50],
                                                        scale: "sqrt",
                                                        spiral: "archimedean",
                                                        transitionDuration: 1000,
                                                        fontFamily: "Raleway",
                                                    }}
                                                />
                                            </ResponsiveContainer>
                                        </div>

                                        {/* Keyword List with Counts */}
                                        <div className="wordcloud-list">
                                            <h4>Keyword Frequency</h4>
                                            <ul>
                                                {wordCloudData
                                                    .sort((a, b) => b.value - a.value) // Sort by count (descending)
                                                    .map((word, index) => (
                                                        <li key={index}>
                                                            <strong>{word.text}:</strong> {word.value} mentions
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    {/* Pagination Buttons */}
                    <div className="pagination-buttons">
                        <button onClick={() => setCurrentSection((prev) => Math.max(prev - 1, 0))} disabled={currentSection === 0}>
                            <IoCaretBackSharp />
                            Previous
                        </button>
                        <button onClick={() => setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1))} disabled={currentSection === sections.length - 1}>
                            Next<IoCaretForwardSharp />
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ReportResults;
