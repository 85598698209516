import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import UserManagement from "../Components/UserManagement";
import OrganizationManagement from "../Components/OrganizationManagement";
import "../CSS/Profile.css";
import "../CSS/Dashboard.css";
import { MdPerson, MdPeople, MdSettings } from "react-icons/md";
import { TbBuildingBank } from "react-icons/tb";
import UserAvatar from "../Components/UserAvatar";

const Profile = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const [activeTab, setActiveTab] = useState("overview");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Initialize profileData state with user data
  const [profileData, setProfileData] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    contactNumber: user.contactNumber || "",
  });

  useEffect(() => {
    // Simulate a fetch request delay
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await axios.put(`/api/users/${user.id}`, profileData);
      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
        />
      </div>
      <div className="content">
        <h2>My Profile</h2>
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="profile-area">
            <div className="tabs">
              <a
                href="#overview"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("overview");
                }}
                className={`tab-link ${
                  activeTab === "overview" ? "active" : ""
                }`}
              >
                <MdPerson /> Overview
              </a>
              {user.role === "super_admin" && (
                <>
                  <a
                    href="#users"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("users");
                    }}
                    className={`tab-link ${
                      activeTab === "users" ? "active" : ""
                    }`}
                  >
                    <MdPeople /> User Management
                  </a>
                  <a
                    href="#organizations"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("organizations");
                    }}
                    className={`tab-link ${
                      activeTab === "organizations" ? "active" : ""
                    }`}
                  >
                    <TbBuildingBank /> Organization Settings
                  </a>
                </>
              )}
              <a
                href="#appsettings"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("appsettings");
                }}
                className={`tab-link ${
                  activeTab === "appsettings" ? "active" : ""
                }`}
              >
                <MdSettings /> Platform Settings
              </a>
            </div>

            {activeTab === "overview" && (
              <div className="alert-edits">
                <div className="right-edits">
                  <div className="associated-users-header">
                    <h4 className="underline">Profile Info</h4>
                  </div>
                  <div className="profile-container">
                    <UserAvatar
                      userName={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="user-profile-info">
                      <p>
                        Name: {user.firstName} {user.lastName}
                      </p>
                      <p>Mobile: {profileData.contactNumber || "N/A"}</p>
                      <p>Email: {user.email}</p>
                    </div>
                  </div>
                </div>
                <div className="about-alert">
                  <h4 className="underline">Edit Profile</h4>
                  <div style={{ marginTop: "40px", marginLeft: "10px" }}>
                    <div>
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={profileData.firstName}
                        onChange={handleChange}
                        style={{
                          width: "60%",
                          fontSize: "14px",
                          fontWeight: "normal",
                          padding: "10px",
                        }}
                      />
                    </div>
                    <div>
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={profileData.lastName}
                        onChange={handleChange}
                        style={{ width: "60%" }}
                      />
                    </div>
                    <div>
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={profileData.email}
                        onChange={handleChange}
                        style={{ width: "60%" }}
                      />
                    </div>
                    <div>
                      <label>Contact Number</label>
                      <input
                        type="text"
                        name="contactNumber"
                        value={profileData.contactNumber}
                        onChange={handleChange}
                        style={{ width: "60%" }}
                      />
                    </div>
                    <button className="updating" onClick={handleUpdateProfile}>
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "users" && user.role === "super_admin" && (
              <UserManagement />
            )}
            {activeTab === "organizations" && user.role === "super_admin" && (
              <OrganizationManagement />
            )}
            {activeTab === "appsettings" && (
              <div className="alert-edits">appsettings</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
