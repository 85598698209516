/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../Components/Header";
import "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";
import { FaEllipsisV, FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import "../CSS/Dashboard.css";
import "../CSS/Competitors.css";
import "../App.css";
import { LuThumbsDown, LuThumbsUp, LuCalendarDays } from "react-icons/lu";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { MdOutlineSentimentNeutral } from "react-icons/md";
import { Toaster, toast } from "sonner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "../Components/ThemeContext";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

function Competitors() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  console.log("Loaded selectedOrg from localStorage:", selectedOrg);
  const { orgId } = useParams();
  const [sourceDistribution, setSourceDistribution] = useState({});
  const [pieData, setPieData] = useState({});
  const [timeFilter, setTimeFilter] = useState("monthly");
  const [articleSentiment, setArticleSentiment] = useState("");
  const [articleVisibility, setArticleVisibility] = useState("");
  const [focusOfCoverage, setFocusOfCoverage] = useState("");
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [reachSortOrder, setReachSortOrder] = useState("");
  const [stationType, setStationType] = useState("");
  const [keyword, setKeyword] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState(20);
  const { theme } = useContext(ThemeContext);
  const [uniqueKeywords, setUniqueKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [broadcastArticles, setBroadcastArticles] = useState([]);
  const [onlineArticles, setOnlineArticles] = useState([]);
  const [printMediaArticles, setPrintMediaArticles] = useState([]);
  const [filteredPrintMediaArticles, setFilteredPrintMediaArticles] = useState(
    []
  );
  const [filteredOnlineArticles, setFilteredOnlineArticles] = useState([]);
  const [filteredBroadcastArticles, setFilteredBroadcastArticles] = useState(
    []
  );
  const [visibleOnlineArticles, setVisibleOnlineArticles] = useState(20);
  const [visibleBroadcastArticles, setVisibleBroadcastArticles] = useState(20);
  const [visiblePrintMediaArticles, setVisiblePrintMediaArticles] =
    useState(20);
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        let response;
        console.log(
          "Fetching data for role:",
          user.role,
          "with selectedOrg:",
          selectedOrg
        );

        if (user.role === "super_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
          );
        } else if (user.role === "org_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`
          );
        }

        setOrganizationData(response.data);
      } catch (error) {
        setError("Failed to load organization data.");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
    fetchOrgData();
  }, [user.role, selectedOrg, navigate, user.organizationId]);

  // Debswana's organization ID (Replace with actual ID)
  const debswanaId = "672a1e46dcdacf1762395320";

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        if (selectedOrg === debswanaId) {
          // Only fetch competitor articles for Debswana
          console.log("Fetching competitor articles for Debswana...");
          const response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/competitorArticles`
          );
          setOnlineArticles(response.data);
        } else {
          //  Fetch all three for other orgs
          console.log("Fetching all media types for non-Debswana org...");
          const [competitorRes, printRes, broadcastRes] = await Promise.all([
            axios.get(
              `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/competitorArticles`
            ),
            axios.get(
              `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/printMedia`
            ),
            axios.get(
              `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/broadcastMedia`
            ),
          ]);

          setOnlineArticles(competitorRes.data);
          setPrintMediaArticles(printRes.data);
          setBroadcastArticles(broadcastRes.data);
        }
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) fetchArticles();
  }, [selectedOrg]);

  // Generate a random hue for each data slice (0-360 for full color range)
  const getRandomHue = () => Math.floor(Math.random() * 360);

  // Function to create a radial gradient
  const getGradient = (ctx, hue) => {
    const gradient = ctx.createRadialGradient(100, 100, 50, 100, 100, 200);
    gradient.addColorStop(0, `hsla(${hue}, 80%, 45%, 1)`); // Darker center
    gradient.addColorStop(1, `hsla(${hue}, 80%, 75%, 1)`); // Lighter outer edge
    return gradient;
  };

  // Function to generate a stable color palette
  const getColorPalette = (labels, ctx) => {
    const newColorMap = { ...colorMap };

    return labels.map((label) => {
      if (!newColorMap[label]) {
        newColorMap[label] = getGradient(ctx, getRandomHue());
      }
      return newColorMap[label];
    });
  };

  // Pie chart data for media source distribution
  useEffect(() => {
    const currentMonth = new Date().toLocaleString("default", {
      month: "short",
    });

    const relevantArticles =
      selectedOrg === debswanaId
        ? onlineArticles
        : [...broadcastArticles, ...printMediaArticles, ...onlineArticles];

    const keywordDistribution = relevantArticles.reduce((acc, article) => {
      const label = article.company || article.keyword;
      if (!label) return acc;

      const rawLabel = Array.isArray(article.company)
        ? article.company.join(", ") // Convert array to a readable string
        : article.company || article.keyword;

      if (!rawLabel || typeof rawLabel !== "string") return acc;

      // Normalize label safely
      const normalizedLabel =
        rawLabel.trim().charAt(0).toUpperCase() +
        rawLabel.slice(1).toLowerCase();

      const articleDate = new Date(
        article.mentionDT || article.publication_date || article.publicationDate
      );
      const articleMonth = articleDate.toLocaleString("default", {
        month: "short",
      });

      if (timeFilter === "monthly") {
        if (articleMonth === currentMonth) {
          acc[normalizedLabel] = (acc[normalizedLabel] || 0) + 1;
        }
      } else {
        acc[normalizedLabel] = (acc[normalizedLabel] || 0) + 1;
      }

      return acc;
    }, {});

    const labels = Object.keys(keywordDistribution);
    const values = Object.values(keywordDistribution);

    if (labels.length > 0) {
      const newColorMap = { ...colorMap }; // ✅ define here

      const backgroundColors = (context) => {
        const chart = context.chart;
        const ctx = chart.ctx;

        return labels.map((label) => {
          if (!newColorMap[label]) {
            const hue = Math.floor(Math.random() * 360);
            newColorMap[label] = getGradient(ctx, hue);
          }
          return newColorMap[label];
        });
      };

      setPieData({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: backgroundColors,
            borderColor: "transparent",
            borderWidth: 2,
            borderRadius: 5,
            spacing: 2,
            // offset: values.map((val) => {
            //   const total = values.reduce((a, b) => a + b, 0);
            //   return val / total < 0.2 ? 30 : 0; // Offset small slices (less than 10%)
            // }),
            // hoverOffset: 25,
            // hoverBorderWidth: 4,
          },
        ],
      });

      setColorMap(newColorMap); // update state
    } else {
      setPieData({
        labels: ["No Data Available"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#d3d3d3"],
            borderColor: "transparent",
            borderWidth: 2,
            offset: [0],
            hoverOffset: 0,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOrg,
    broadcastArticles,
    onlineArticles,
    printMediaArticles,
    timeFilter,
  ]);

  // Pie chart options
  const pieOptions = {
    responsive: true,
    cutout: "30%", // Adjust for a donut-style effect
    layout: {
      padding: {
        top: 120,
        left: 80,
        right: 80,
        bottom: 10,
      },
    },
    rotation: Math.PI / 1, // Rotate the chart to start from the bottom
    plugins: {
      legend: {
        display: false, // Hides the default legend
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}%`,
        },
      },
      datalabels: {
        display: false,
        anchor: "end", // Moves label outside the slice
        align: "end",
        offset: 20, // Moves labels further away from the pie
        color: theme === "light" ? "#333" : "#fff",
        font: { weight: "bold", size: 12, family: "Raleway" },
        formatter: (value, context) => {
          return `${context.chart.data.labels[context.dataIndex]}: ${value}%`;
        },
        clip: false, // Ensures labels don't get cut off
        textAlign: "center",
      },
    },
  };

  const leaderLinePlugin = {
    id: "leaderLines",
    afterDraw(chart) {
      const ctx = chart.ctx;
      const dataset = chart.data.datasets[0];
      const meta = chart.getDatasetMeta(0);

      const chartCenter = {
        x: (chart.chartArea.left + chart.chartArea.right) / 2,
        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
      };

      const labelPositions = [];

      ctx.save();
      ctx.strokeStyle = "#888";
      ctx.lineWidth = 1;

      meta.data.forEach((arc, index) => {
        if (!arc || !arc.getCenterPoint) return;

        const tooltipPosition = arc.tooltipPosition();

        const angle = Math.atan2(
          tooltipPosition.y - chartCenter.y,
          tooltipPosition.x - chartCenter.x
        );

        const startOffset = 20;
        const baseDistance = 20;
        const distanceIncrement = 4;
        const distance = baseDistance + distanceIncrement * index;

        const xStart = tooltipPosition.x + Math.cos(angle) * startOffset;
        const yStart = tooltipPosition.y + Math.sin(angle) * startOffset;

        const xMid = tooltipPosition.x + Math.cos(angle) * distance;
        const yMid = tooltipPosition.y + Math.sin(angle) * distance;

        // Angle for label line + label rotation
        let xEnd, yEnd, labelAngle;
        const tilt = Math.PI / 6; // 30 degrees

        if (
          tooltipPosition.y < chartCenter.y &&
          tooltipPosition.x < chartCenter.x
        ) {
          labelAngle = angle - tilt;
        } else if (
          tooltipPosition.y < chartCenter.y &&
          tooltipPosition.x >= chartCenter.x
        ) {
          labelAngle = angle + tilt;
        } else if (tooltipPosition.x < chartCenter.x) {
          labelAngle = angle - Math.PI / 12;
        } else {
          labelAngle = angle + Math.PI / 12;
        }

        xEnd = xMid + Math.cos(labelAngle) * 30;
        yEnd = yMid + Math.sin(labelAngle) * 30;

        // Prevent overlapping labels
        while (labelPositions.includes(yEnd)) {
          yEnd += 14;
        }
        labelPositions.push(yEnd);

        // Draw Leader Line
        ctx.beginPath();
        ctx.moveTo(xStart, yStart);
        ctx.lineTo(xMid, yMid);
        ctx.lineTo(xEnd, yEnd);
        ctx.stroke();

        // Label Drawing — rotated
        const label = chart.data.labels[index];
        const words = label.split(" ");
        const maxWidth = 70;
        let line = "";
        let yOffset = 0;

        ctx.save(); // Save context before rotating
        ctx.translate(xEnd, yEnd);
        const flip = Math.abs(labelAngle) > Math.PI / 2;
        ctx.rotate(flip ? labelAngle + Math.PI : labelAngle);
        ctx.textAlign = flip ? "right" : "left";

        ctx.font = "12px Raleway";
        ctx.fillStyle = "#555";
        // ctx.textAlign = "left"; // Always draw to the right when rotated
        ctx.textBaseline = "middle";

        words.forEach((word) => {
          const testLine = line + word + " ";
          const testWidth = ctx.measureText(testLine).width;

          if (testWidth > maxWidth) {
            ctx.fillText(line, 0, yOffset);
            line = word + " ";
            yOffset += 14;
          } else {
            line = testLine;
          }
        });

        ctx.fillText(line, 0, yOffset);

        ctx.restore(); // Restore after rotating
      });

      ctx.restore();
    },
  };

  // Function to count mentions by month for both broadcast and print media
  const groupArticlesByMonthForLineChart = (
    onlineArticles,
    broadcastArticles,
    printArticles
  ) => {
    const currentYear = new Date().getFullYear();
    const onlineMonthCounts = new Array(12).fill(0); // Online articles
    const broadcastMonthCounts = new Array(12).fill(0); // Broadcast media
    const printMonthCounts = new Array(12).fill(0); // Print media

    // Count Online Articles (Competitor Articles for Debswana)
    onlineArticles.forEach((article) => {
      const articleDate = new Date(
        article.publication_date || article.mentionDT || article.publicationDate
      );
      if (articleDate.getFullYear() === currentYear) {
        onlineMonthCounts[articleDate.getMonth()] += 1;
      }
    });

    // Count Broadcast Articles (For non-Debswana)
    broadcastArticles.forEach((article) => {
      const articleDate = new Date(article.mentionDT);
      if (articleDate.getFullYear() === currentYear) {
        broadcastMonthCounts[articleDate.getMonth()] += 1;
      }
    });

    // Count Print Media Articles (For non-Debswana)
    printArticles.forEach((article) => {
      const articleDate = new Date(article.publicationDate);
      if (articleDate.getFullYear() === currentYear) {
        printMonthCounts[articleDate.getMonth()] += 1;
      }
    });

    return { onlineMonthCounts, broadcastMonthCounts, printMonthCounts };
  };

  // Get counts for broadcast and print media
  const { onlineMonthCounts, broadcastMonthCounts, printMonthCounts } =
    selectedOrg === debswanaId
      ? groupArticlesByMonthForLineChart(onlineArticles, [], []) // Only online articles for Debswana
      : groupArticlesByMonthForLineChart(
          onlineArticles,
          broadcastArticles,
          printMediaArticles
        ); // All media types for other orgs

  // **Updated Line Chart Data**
  const lineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Online Articles",
        data: onlineMonthCounts,
        borderColor: "rgb(190, 75, 192)", // Violet
        backgroundColor: "rgba(190, 75, 192, 0.2)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 7,
        fill: true,
      },
      ...(selectedOrg !== debswanaId
        ? [
            {
              label: "Broadcast Articles",
              data: broadcastMonthCounts,
              borderColor: "rgba(75, 192, 192, 1)", // Teal
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderWidth: 2,
              tension: 0.4,
              pointRadius: 3,
              pointHoverRadius: 7,
              fill: true,
            },
            {
              label: "Print Media Articles",
              data: printMonthCounts,
              borderColor: "rgba(255, 99, 132, 1)", // Red
              backgroundColor: "rgba(248, 124, 150, 0.41)",
              borderWidth: 2,
              tension: 0.4,
              pointRadius: 3,
              pointHoverRadius: 7,
              fill: true,
            },
          ]
        : []), // Exclude these datasets for Debswana
    ],
  };

  // **Line Chart Options**
  const lineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 200, // Adjusted for combined articles
        stepSize: 200,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        // align: "start", // Align legend items to the left (optional)
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // Forces circle style
          font: { family: "Raleway", size: 13, weight: "500" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
          boxWidth: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} Articles`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  // Handle delete article
  const handleDelete = async (id, type = "broadcastMedia") => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const response = await axios.delete(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/${type}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          if (type === "printMedia") {
            setPrintMediaArticles((prev) =>
              prev.filter((article) => article._id !== id)
            );
            setFilteredPrintMediaArticles((prev) =>
              prev.filter((article) => article._id !== id)
            );
          } else {
            setFilteredBroadcastArticles((prev) =>
              prev.filter((article) => article._id !== id)
            );
          }
          toast.success("Article deleted successfully");
        }
      } catch (error) {
        console.error("Error deleting article:", error);
        toast.error("Failed to delete article");
      }
    }
  };

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...broadcastArticles];

      // Filter by sentiment
      if (articleSentiment) {
        filtered = filtered.filter((article) => {
          if (articleSentiment === "positive") return article.sentiment > 0.05;
          if (articleSentiment === "neutral")
            return article.sentiment >= -0.05 && article.sentiment <= 0.05;
          if (articleSentiment === "negative") return article.sentiment < -0.05;
          if (articleSentiment === "mixed")
            return article.sentiment > -0.5 && article.sentiment < 0.5;
          return true;
        });
      }

      // Filter by visibility if needed (not implemented in the backend code example, but here's a placeholder)
      if (articleVisibility) {
        filtered = filtered.filter(
          (article) => article.visibility === articleVisibility
        );
      }

      // Filter by focus of coverage
      if (focusOfCoverage) {
        filtered = filtered.filter(
          (article) => article.coverageFocus === focusOfCoverage
        );
      }

      // Filter by station type
      if (stationType) {
        filtered = filtered.filter(
          (article) => article.stationType === stationType
        );
      }

      // Filter by keyword (case-insensitive match)
      if (selectedKeyword) {
        filtered = filtered.filter(
          (article) =>
            article.keyword.toLowerCase() === selectedKeyword.toLowerCase()
        );
      }

      // Sort by AVE
      if (aveSortOrder) {
        filtered.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
        console.log("Sorted by AVE:", aveSortOrder);
      }

      // Sort by reach
      if (reachSortOrder) {
        filtered.sort((a, b) =>
          reachSortOrder === "ascending" ? a.reach - b.reach : b.reach - a.reach
        );
      }

      // Filter by date range
      if (startDate && endDate) {
        filtered = filtered.filter((article) => {
          const articleDate = new Date(article.mentionDT);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      setBroadcastArticles(filtered);
    };

    applyFilters();
  }, [
    broadcastArticles,
    articleSentiment,
    keyword,
    articleVisibility,
    focusOfCoverage,
    aveSortOrder,
    reachSortOrder,
    stationType,
    startDate,
    endDate,
    selectedKeyword,
  ]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredBroadcastArticles.length));
  }, [filteredBroadcastArticles]);

  useEffect(() => {
    const applyPrintMediaFilters = () => {
      let filtered = [...printMediaArticles];

      // Filter by sentiment (same as for Broadcast Articles)
      if (articleSentiment) {
        filtered = filtered.filter((article) => {
          if (articleSentiment === "positive") return article.sentiment > 0.05;
          if (articleSentiment === "neutral")
            return article.sentiment >= -0.05 && article.sentiment <= 0.05;
          if (articleSentiment === "negative") return article.sentiment < -0.05;
          if (articleSentiment === "mixed")
            return article.sentiment > -0.5 && article.sentiment < 0.5;
          return true;
        });
      }

      // Filter by date range (if applicable)
      if (startDate && endDate) {
        filtered = filtered.filter((article) => {
          const articleDate = new Date(article.publicationDate);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      // Filter by selected company/keyword
      if (selectedKeyword) {
        filtered = filtered.filter(
          (article) =>
            article.keyword.toLowerCase() === selectedKeyword.toLowerCase()
        );
      }

      // Sort by AVE
      if (aveSortOrder) {
        filtered.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
      }

      setFilteredPrintMediaArticles(filtered);
    };

    applyPrintMediaFilters();
  }, [
    printMediaArticles,
    articleSentiment,
    selectedKeyword,
    aveSortOrder,
    startDate,
    endDate,
  ]);

  //for calendar/date filter
  useEffect(() => {
    if (startDate && endDate) {
      console.log("Start Date:", startDate);
      console.log("End Date:", endDate);

      const filtered = broadcastArticles.filter((article) => {
        if (!article.mentionDT) return false; // Skip articles without a date
        const articleDate = new Date(article.mentionDT);
        return (
          articleDate >=
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            ) &&
          articleDate <=
            new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate()
            )
        );
      });

      setFilteredBroadcastArticles(filtered);
    } else {
      setFilteredBroadcastArticles(broadcastArticles); // Reset to show all articles
    }
  }, [startDate, endDate, broadcastArticles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <div>{error}</div>;

  const handleMenuClick = (table, index) => {
    const menuId = `${table}-menu-${index}`;
    document.getElementById(menuId).style.display = "block";
  };
  const handleMenuClose = (table, index) => {
    const menuId = `${table}-menu-${index}`;
    document.getElementById(menuId).style.display = "none";
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    // Filter Broadcast Articles
    setFilteredBroadcastArticles(
      broadcastArticles.filter(
        (article) =>
          article.mention.toLowerCase().includes(lowerCaseQuery) ||
          article.station.toLowerCase().includes(lowerCaseQuery) ||
          (article.keyword &&
            article.keyword.toLowerCase().includes(lowerCaseQuery))
      )
    );

    // Filter Online Articles
    setFilteredOnlineArticles(
      onlineArticles.filter(
        (article) =>
          article.company.toLowerCase().includes(lowerCaseQuery) ||
          (article.snippet &&
            article.snippet.toLowerCase().includes(lowerCaseQuery)) ||
          (article.title &&
            article.title.toLowerCase().includes(lowerCaseQuery)) ||
          (article.source &&
            article.source.toLowerCase().includes(lowerCaseQuery))
      )
    );

    // Filter Print Media Articles
    setFilteredPrintMediaArticles(
      printMediaArticles.filter(
        (article) =>
          article.keyword.toLowerCase().includes(lowerCaseQuery) ||
          (article.publication &&
            article.publication.toLowerCase().includes(lowerCaseQuery)) ||
          (article.headline &&
            article.headline.toLowerCase().includes(lowerCaseQuery))
      )
    );
  };

  const showMoreBroadcastArticles = () => {
    setVisibleBroadcastArticles((prev) =>
      Math.min(prev + 20, filteredBroadcastArticles.length)
    );
  };

  const showLessBroadcastArticles = () => {
    setVisibleBroadcastArticles((prev) => Math.max(prev - 20, 20));
  };

  const showAllBroadcastArticles = () => {
    setVisibleBroadcastArticles(filteredBroadcastArticles.length);
  };

  const showMoreOnlineArticles = () => {
    setVisibleOnlineArticles((prev) =>
      Math.min(prev + 20, filteredOnlineArticles.length)
    );
  };

  const showLessOnlineArticles = () => {
    setVisibleOnlineArticles((prev) => Math.max(prev - 20, 20));
  };

  const showAllOnlineArticles = () => {
    setVisibleOnlineArticles(filteredOnlineArticles.length);
  };

  const showMorePrintMediaArticles = () => {
    setVisiblePrintMediaArticles((prev) =>
      Math.min(prev + 20, filteredPrintMediaArticles.length)
    );
  };

  const showLessPrintMediaArticles = () => {
    setVisiblePrintMediaArticles((prev) => Math.max(prev - 20, 20));
  };

  const showAllPrintMediaArticles = () => {
    setVisiblePrintMediaArticles(filteredPrintMediaArticles.length);
  };

  // Function to handle sorting for AVE and Reach
  const handleSort = (field) => {
    if (field === "ave") {
      setAveSortOrder((prevOrder) =>
        prevOrder === "ascending" ? "descending" : "ascending"
      );
    } else if (field === "reach") {
      setReachSortOrder((prevOrder) =>
        prevOrder === "ascending" ? "descending" : "ascending"
      );
    }
  };

  // Function to get sorting icon dynamically
  const getSortIcon = (sortOrder) => {
    return sortOrder === "ascending" ? (
      <FaSortAmountUp />
    ) : (
      <FaSortAmountDown />
    );
  };

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <div className="content">
        <h2>
          {organizationData.organization.organizationName}'s Industry
          Competitors
        </h2>
        <div className="holder">
          {/* Chart Section */}
          <div className="time-filter">
            <label>
              <input
                type="radio"
                value="monthly"
                checked={timeFilter === "monthly"}
                onChange={() => setTimeFilter("monthly")}
              />
              Monthly
            </label>
            <label>
              <input
                type="radio"
                value="overall"
                checked={timeFilter === "overall"}
                onChange={() => setTimeFilter("overall")}
              />
              Overall
            </label>
          </div>
          <div className="charts">
            <div className="piechart">
              <h3 style={{ marginBottom: "30px" }}>Competitors</h3>
              <Pie
                data={pieData}
                options={pieOptions}
                plugins={[leaderLinePlugin]}
              />
            </div>
            <div className="chart-container">
              <h3>Number of Articles over the Year</h3>
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>

          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="filters">
              {/* Date Range Filter */}
              <div
                className="date-container2"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date" style={{ fontWeight: "400" }}>
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the calendar toggle
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredBroadcastArticles(broadcastArticles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>
              <select
                value={selectedKeyword}
                onChange={(e) => setSelectedKeyword(e.target.value)}
              >
                <option value="">Filter by Company</option>
                {uniqueKeywords.map((keyword, index) => (
                  <option key={index} value={keyword}>
                    {keyword}
                  </option>
                ))}
              </select>
              <select
                value={articleSentiment}
                onChange={(e) => setArticleSentiment(e.target.value)}
              >
                <option value="">Article Sentiment ☺</option>
                <option value="positive">Positive</option>
                <option value="neutral">Neutral</option>
                <option value="negative">Negative</option>
                <option value="mixed">Mixed</option>
              </select>
              {/* <select
                value={articleVisibility}
                onChange={(e) => setArticleVisibility(e.target.value)}
              >
                <option value="">Article Visibility ↡</option>
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select> */}
              <select
                value={stationType}
                onChange={(e) => setStationType(e.target.value)}
              >
                <option value="">Station Type</option>
                <option value="TV">TV</option>
                <option value="Radio">Radio</option>
              </select>
              {/* <select
                value={aveSortOrder}
                onChange={(e) => setAveSortOrder(e.target.value)}
              >
                <option value="">AVE Sort Order</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select> */}
            </div>

            {/* Table Section for Online Media Articles */}
            <h3 style={{ fontSize: "16px", marginTop: "50px" }}>
              Online Media
            </h3>
            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th> Company</th>
                      <th>URL</th>
                      <th>Summary</th>
                      <th>Publisher</th>
                      <th>Country</th>
                      <th>Date Published</th>
                      <th>Sentiment</th>
                      <th
                        onClick={() => handleSort("ave")}
                        style={{ cursor: "pointer" }}
                      >
                        AVE {getSortIcon(aveSortOrder)}
                      </th>
                      <th>Reach</th>
                      <th>Coverage</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {onlineArticles.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No articles found for this organization.
                        </td>
                      </tr>
                    ) : (
                      [...onlineArticles] // Make a copy of the array to avoid mutating the original state
                        .sort((a, b) => {
                          // Default sorting by date (most recent first)
                          const dateComparison =
                            new Date(b.mentionDT) - new Date(a.mentionDT);
                          if (aveSortOrder) {
                            return aveSortOrder === "ascending"
                              ? a.ave - b.ave
                              : b.ave - a.ave;
                          }
                          return dateComparison; // Default to sorting by date if no AVE/Reach sorting is applied
                        })
                        // .slice(0, 80)
                        .slice(0, visibleOnlineArticles)
                        .map((article, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "8px",
                                }}
                              >
                                {article.company_logo_url && (
                                  <img
                                    className="logo-img"
                                    src={article.company_logo_url}
                                    alt={`${article.company} logo`}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "1px solid",
                                      padding: "1px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {article.company}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.title
                                    ? (() => {
                                        const cleanedText =
                                          article.title.replace(
                                            /^Summary:\s*/,
                                            ""
                                          ); // Remove "Summary:"
                                        const words = cleanedText.split(" "); // Split into words
                                        const shortenedText = words
                                          .slice(3, 8)
                                          .join(" "); // Remove first 2 words, keep next 5
                                        return (
                                          shortenedText ||
                                          "No content available"
                                        ); // Ensure fallback text
                                      })()
                                    : "No content available"}
                                  .. ⤴
                                </a>
                              </div>
                            </td>
                            {/* <td>{article.keyword}</td> */}

                            <td className="snippet">
                              "
                              {article.snippet
                                ? `${article.snippet
                                    .replace(/^Summary:\s*/, "")
                                    .split(" ")
                                    .slice(0, 15)
                                    .join(" ")}...`
                                : "No content available"}
                              "
                            </td>

                            <td>
                              <div className="table-source">
                                {article.source}
                              </div>
                            </td>
                            <td>{article.country}</td>
                            <td style={{ fontWeight: "500" }}>
                              {new Date(
                                article.publication_date
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              {/* Display sentiment icon with text on the right side */}
                              <div className="sentiment-container">
                                {article.sentiment > 0 ? (
                                  <div className="sentiment positive">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsUp className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Positive
                                    </span>
                                  </div>
                                ) : article.sentiment < 0 ? (
                                  <div className="sentiment negative">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsDown className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Negative
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sentiment neutral">
                                    <div className="sentiment-icon-container">
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Neutral
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {article.ave
                                ? `${article.ave.toFixed(2)} BWP`
                                : "-"}
                            </td>
                            <td>{article.reach}</td>
                            <td>{article.coverage_type}</td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() =>
                                    handleMenuClick("broadcast-media", index)
                                  }
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`broadcast-media-menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() =>
                                    handleMenuClose("broadcast-media", index)
                                  }
                                >
                                  <a href={article.url} download>
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                  >
                                    Send Article
                                  </a>
                                  {user.role === "super_admin" && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(article._id);
                                      }}
                                      className="delete-link"
                                    >
                                      Delete
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredOnlineArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing{" "}
                    {Math.min(
                      visibleOnlineArticles,
                      filteredOnlineArticles.length
                    )}{" "}
                    of {filteredOnlineArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visibleOnlineArticles < filteredOnlineArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreOnlineArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visibleOnlineArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessOnlineArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visibleOnlineArticles < filteredOnlineArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllOnlineArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Table Section for Broadcast Media Articles */}
            <h3 style={{ fontSize: "16px", marginTop: "50px" }}>
              Broadcast Media
            </h3>
            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th> Company</th>
                      <th>Summary</th>
                      <th>URL</th>
                      <th>Source</th>
                      <th>Station Type</th>
                      <th>Date Posted</th>
                      <th>Sentiment</th>
                      <th
                        onClick={() => handleSort("ave")}
                        style={{ cursor: "pointer" }}
                      >
                        AVE {getSortIcon(aveSortOrder)}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBroadcastArticles.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No articles found for this organization.
                        </td>
                      </tr>
                    ) : (
                      [...filteredBroadcastArticles] // Make a copy of the array to avoid mutating the original state
                        .sort((a, b) => {
                          // Default sorting by date (most recent first)
                          const dateComparison =
                            new Date(b.mentionDT) - new Date(a.mentionDT);
                          if (aveSortOrder) {
                            return aveSortOrder === "ascending"
                              ? a.ave - b.ave
                              : b.ave - a.ave;
                          }
                          return dateComparison; // Default to sorting by date if no AVE/Reach sorting is applied
                        })
                        // .slice(0, 80)
                        .slice(0, visibleBroadcastArticles)
                        .map((article, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "8px",
                                }}
                              >
                                {article.company_logo_url && (
                                  <img
                                    className="logo-img"
                                    src={article.company_logo_url}
                                    alt={`${article.keyword} logo`}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "1px solid",
                                      padding: "1px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {article.keyword}
                                </span>
                              </div>
                            </td>
                            {/* <td>{article.keyword}</td> */}

                            <td className="snippet">
                              "
                              {article.mention
                                ? `${article.mention
                                    .replace(/^Summary:\s*/, "")
                                    .split(" ")
                                    .slice(0, 15)
                                    .join(" ")}...`
                                : "No content available"}
                              "
                            </td>
                            <td>
                              <div>
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.mention
                                    ? (() => {
                                        const cleanedText =
                                          article.mention.replace(
                                            /^Summary:\s*/,
                                            ""
                                          ); // Remove "Summary:"
                                        const words = cleanedText.split(" "); // Split into words
                                        const shortenedText = words
                                          .slice(3, 8)
                                          .join(" "); // Remove first 2 words, keep next 5
                                        return (
                                          shortenedText ||
                                          "No content available"
                                        ); // Ensure fallback text
                                      })()
                                    : "No content available"}
                                  .. ⤴
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="table-source">
                                {article.station}
                              </div>
                            </td>
                            <td>
                              <div className="station-type">
                                {article.stationType}
                              </div>
                            </td>
                            <td style={{ fontWeight: "500" }}>
                              {new Date(article.mentionDT).toLocaleDateString()}
                            </td>
                            <td>
                              {/* Display sentiment icon with text on the right side */}
                              <div className="sentiment-container">
                                {article.sentiment > 0 ? (
                                  <div className="sentiment positive">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsUp className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Positive
                                    </span>
                                  </div>
                                ) : article.sentiment < 0 ? (
                                  <div className="sentiment negative">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsDown className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Negative
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sentiment neutral">
                                    <div className="sentiment-icon-container">
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Neutral
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {article.ave
                                ? `${article.ave.toFixed(2)} BWP`
                                : "-"}
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() =>
                                    handleMenuClick("broadcast-media", index)
                                  }
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`broadcast-media-menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() =>
                                    handleMenuClose("broadcast-media", index)
                                  }
                                >
                                  <a href={article.url} download>
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                  >
                                    Send Article
                                  </a>
                                  {user.role === "super_admin" && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(article._id);
                                      }}
                                      className="delete-link"
                                    >
                                      Delete
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredBroadcastArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing{" "}
                    {Math.min(
                      visibleBroadcastArticles,
                      filteredBroadcastArticles.length
                    )}{" "}
                    of {filteredBroadcastArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visibleBroadcastArticles <
                      filteredBroadcastArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreBroadcastArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visibleBroadcastArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessBroadcastArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visibleBroadcastArticles <
                      filteredBroadcastArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllBroadcastArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Table Section for Print Media Articles */}
            <h3 style={{ fontSize: "16px", marginTop: "50px" }}>Print Media</h3>
            <div className="filters">
              {/* Date Range Filter */}
              <div
                className="date-container2"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date" style={{ fontWeight: "400" }}>
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the calendar toggle
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredBroadcastArticles(broadcastArticles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>
              <select
                value={selectedKeyword}
                onChange={(e) => setSelectedKeyword(e.target.value)}
              >
                <option value="">Filter by Company</option>
                {uniqueKeywords.map((keyword, index) => (
                  <option key={index} value={keyword}>
                    {keyword}
                  </option>
                ))}
              </select>
              <select
                value={articleSentiment}
                onChange={(e) => setArticleSentiment(e.target.value)}
              >
                <option value="">Article Sentiment ☺</option>
                <option value="positive">Positive</option>
                <option value="neutral">Neutral</option>
                <option value="negative">Negative</option>
                <option value="mixed">Mixed</option>
              </select>
              {/* <select
                value={articleVisibility}
                onChange={(e) => setArticleVisibility(e.target.value)}
              >
                <option value="">Article Visibility ↡</option>
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select> */}
              <select
                value={stationType}
                onChange={(e) => setStationType(e.target.value)}
              >
                <option value="">Station Type</option>
                <option value="TV">TV</option>
                <option value="Radio">Radio</option>
              </select>
              <select
                value={aveSortOrder}
                onChange={(e) => setAveSortOrder(e.target.value)}
              >
                <option value="">AVE Sort Order</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
            </div>
            <div className="section">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <div className="table-container">
                  <table className="articles-table">
                    <thead>
                      <tr>
                        <th> Company</th>
                        <th>URL</th>
                        <th>Source</th>
                        <th>Date Posted</th>
                        <th>Sentiment</th>
                        <th>News Section</th>
                        <th
                          onClick={() => handleSort("ave")}
                          style={{ cursor: "pointer" }}
                        >
                          AVE {getSortIcon(aveSortOrder)}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPrintMediaArticles.length === 0 ? (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            No articles found for this organization.
                          </td>
                        </tr>
                      ) : (
                        [...filteredPrintMediaArticles]
                          .sort(
                            (a, b) =>
                              new Date(b.publicationDate) -
                              new Date(a.publicationDate)
                          )
                          .slice(0, visiblePrintMediaArticles)
                          // Limit to visible articles
                          .map((article, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  className="table-source"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    gap: "8px",
                                  }}
                                >
                                  {article.company_logo_url && (
                                    <img
                                      className="logo-img"
                                      src={article.company_logo_url}
                                      alt={`${article.keyword} logo`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid",
                                        padding: "1px",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    {article.keyword}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.headline} ⤴
                                </a>
                              </td>
                              <td className="table-source">
                                {article.publication}
                              </td>
                              <td>
                                {new Date(
                                  article.publicationDate
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                <div className="sentiment-container">
                                  {article.sentiment > 0 ? (
                                    <div className="sentiment positive">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsUp className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Positive
                                      </span>
                                    </div>
                                  ) : article.sentiment < 0 ? (
                                    <div className="sentiment negative">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsDown className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Negative
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="sentiment neutral">
                                      <div className="sentiment-icon-container">
                                        <MdOutlineSentimentNeutral className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Neutral
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div
                                  className="station-type"
                                  style={{ fontWeight: "500" }}
                                >
                                  {article.section}
                                </div>
                              </td>
                              <td>
                                {article.ave
                                  ? `${article.ave.toFixed(2)} BWP`
                                  : "-"}
                              </td>
                              <td>
                                <div className="icon-menu">
                                  <button
                                    className="icon-button"
                                    onClick={() =>
                                      handleMenuClick("print-media", index)
                                    }
                                  >
                                    <FaEllipsisV />
                                  </button>
                                  <div
                                    id={`print-media-menu-${index}`}
                                    className="menu-options"
                                    onMouseLeave={() =>
                                      handleMenuClose("print-media", index)
                                    }
                                  >
                                    <a href={article.url} download>
                                      Download
                                    </a>
                                    <a
                                      href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                    >
                                      Send Article
                                    </a>
                                    {user.role === "super_admin" && (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDelete(article._id);
                                        }}
                                        className="delete-link"
                                      >
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {/* Show More / Show Less Buttons for Print Media */}
              {filteredPrintMediaArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing{" "}
                    {Math.min(
                      visiblePrintMediaArticles,
                      filteredPrintMediaArticles.length
                    )}{" "}
                    of {filteredPrintMediaArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visiblePrintMediaArticles <
                      filteredPrintMediaArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMorePrintMediaArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visiblePrintMediaArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessPrintMediaArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visiblePrintMediaArticles <
                      filteredPrintMediaArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllPrintMediaArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Competitors;
