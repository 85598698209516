import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import Register from "./Views/register";
import Broadcast from './Views/Media/Broadcast';
import AddArticle from "./Components/AddArticle";
import Organization from "./Components/organization";
import OnlineMedia from './Views/Media/OnlineMedia';
import Print from './Views/Media/Print';
import Social from './Views/Media/Social';
import AdvancedSearch from './Views/AdvancedSearch';
import Alerts from './Views/Alerts';
import EditAlert from "./Components/EditAlert";
import Analytics from './Views/Analytics';
import Competitors from './Views/Competitors';
import ConsumerGroups from './Views/ConsumerGroups';
import Dashboard from './Views/Dashboard';
import Profile from './Views/Profile';
import Settings from './Views/Settings';
import Login from "./Views/login";
import { Toaster } from 'sonner';
import UserManagement from "./Components/UserManagement";
import OrganizationManagement from "./Components/OrganizationManagement";
import EditOrganization from "./Components/EditOrg";
import UserAvatar from "./Components/UserAvatar";
import ReportResults from "./Components/ReportResults";

function AppRoutes() {
    return (
        <Router>
            <Toaster richColors position="top-center" />
            <RouteWrapper />
        </Router>
    );
}

function RouteWrapper() {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthRoute = location.pathname === '/login' || location.pathname === '/register';

    useEffect(() => {
        const orgId = localStorage.getItem('organizationId');

        if (!isAuthRoute && !orgId) {
            // Redirect to login if no organizationId is found in localStorage
            navigate('/login');
        }
    }, [isAuthRoute, navigate]);

    return (
        <>
            {!isAuthRoute && <Sidebar />}
            <Routes>
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/dashboard/:orgId" element={<Dashboard />} />
                <Route path="/advancedsearch/:orgId" element={<AdvancedSearch />} />
                <Route path="/alerts/:orgId" element={<Alerts />} />
                <Route path="/alerts/edit/:alertId" element={<EditAlert />} />
                <Route path="/analytics/:orgId" element={<Analytics />} />
                <Route path="/report-results/:orgId" element={<ReportResults />} />
                <Route path="/competitors/:orgId" element={<Competitors />} />
                <Route path="/consumergroups/:orgId" element={<ConsumerGroups />} />
                <Route path="/profile/:orgId" element={<Profile />} />
                <Route path="/user-management/:orgId" element={<UserManagement />} />
                <Route path="/organization-management/:orgId" element={<OrganizationManagement />} />
                <Route path="/edit-organization/:orgId" element={<EditOrganization />} />
                <Route path="/settings/:orgId" element={<Settings />} />
                <Route path="/media/broadcast/:orgId" element={<Broadcast />} />
                <Route path="/media/online/:orgId" element={<OnlineMedia />} />
                <Route path="/media/print/:orgId" element={<Print />} />
                <Route path="/media/social/:orgId" element={<Social />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/settings/addarticle/:orgId" element={<AddArticle />} />
                <Route path="/settings/organization/:orgId" element={<Organization />} />
            </Routes>
        </>
    );
}

export default AppRoutes;
