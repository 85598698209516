import { useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';

function BannerUpload({ onBannerChange }) {
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      onBannerChange(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      onBannerChange(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={{
        border: '2px dashed #0077ff',
        padding: '30px',
        textAlign: 'center',
        borderRadius: '12px',
        backgroundColor: '#f1f8ff',
        cursor: 'pointer',
        position: 'relative',
        color: '#0056b3',
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#e8f3ff';
        e.currentTarget.style.borderColor = '#0056b3';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#f1f8ff';
        e.currentTarget.style.borderColor = '#0077ff';
      }}
    >
      {preview ? (
        <img
          src={preview}
          alt="Banner Preview"
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
      ) : (
        <>
          <MdOutlineCloudUpload size={60} style={{ color: '#0056b3', marginBottom: '10px' }} />
          <p style={{ fontSize: '16px', fontWeight: '500', margin: '0' }}>
            Drag & drop your banner here
          </p>
          <p style={{ fontSize: '14px', margin: '5px 0', color: '#666' }}>
            or click to upload
          </p>
        </>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileUpload"
      />
      <label
        htmlFor="fileUpload"
        style={{
          display: 'inline-block',
          marginTop: '15px',
          cursor: 'pointer',
          fontSize: '14px',
          color: '#0077ff',
          textDecoration: 'underline',
        }}
      >
        {preview ? 'Change Banner' : 'Upload Banner'}
      </label>
    </div>
  );
}

export default BannerUpload;
