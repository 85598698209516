/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import { FaEllipsisV, FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";
import { MdOutlineSentimentNeutral } from "react-icons/md";
import { Toaster, toast } from "sonner";
import "../../CSS/Dashboard.css";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import "../../App.css";

function Social() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  const { orgId } = useParams();
  const [facebookPosts, setFacebookPosts] = useState([]);
  const [post, setPost] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const popupRef = useRef(null);
  // Filter states
  const [articleSentiment, setArticleSentiment] = useState("");
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [reachSortOrder, setReachSortOrder] = useState("");
  const [visiblePosts, setVisiblePosts] = useState(20);

  // Close popup if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup
      }
    };

    if (popupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupVisible]);

  // Fetch organization data
  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        let response;
        if (user.role === "super_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
          );
        } else if (user.role === "org_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`
          );
        }
        setOrganizationData(response.data);
      } catch (error) {
        setError("Failed to load organization data.");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
    fetchOrgData();
  }, [user.role, selectedOrg, navigate, user.organizationId]);

  // Fetch Facebook posts
  useEffect(() => {
    const fetchFacebookPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/posts`
        );
        setFacebookPosts(response.data.posts);
        setFilteredPosts(response.data.posts); // Initial load of filtered posts
      } catch (error) {
        console.error("Error fetching Facebook posts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) {
      fetchFacebookPosts();
    }
  }, [selectedOrg]);

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...facebookPosts];

      // Filter by sentiment
      if (articleSentiment) {
        filtered = filtered.filter((post) => {
          if (articleSentiment === "positive") return post.sentiment > 0.05;
          if (articleSentiment === "neutral")
            return post.sentiment >= -0.05 && post.sentiment <= 0.05;
          if (articleSentiment === "negative") return post.sentiment < -0.05;
          if (articleSentiment === "mixed")
            return post.sentiment > -0.5 && post.sentiment < 0.5;
          return true;
        });
      }

      // Sort by AVE
      if (aveSortOrder) {
        filtered.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
      }

      // Sort by reach
      if (reachSortOrder) {
        filtered.sort((a, b) =>
          reachSortOrder === "ascending" ? a.reach - b.reach : b.reach - a.reach
        );
      }

      setFilteredPosts(filtered);
    };

    applyFilters();
  }, [facebookPosts, articleSentiment, aveSortOrder, reachSortOrder]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisiblePosts(Math.min(20, filteredPosts.length));
  }, [filteredPosts]);

  // Handle delete post
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this post?")) return;

    try {
      const response = await axios.delete(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/posts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setFacebookPosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== id)
        );
        setFilteredPosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== id)
        );
        toast.success("Post deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete post");
    }
  };

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  const handleMenuClick = (index) => {
    document.getElementById(`menu-${index}`).style.display = "block";
  };

  const handleMenuClose = (index) => {
    document.getElementById(`menu-${index}`).style.display = "none";
  };

  const generateLogoUrl = (platform) => {
    const socialMediaMapping = {
      Facebook: "facebook.com",
      Twitter: "twitter.com",
      Instagram: "instagram.com",
      LinkedIn: "linkedin.com",
      YouTube: "youtube.com",
      Other: "generic.com",
    };

    const domain = socialMediaMapping[platform] || socialMediaMapping["Other"];
    return `https://img.logo.dev/${domain}?token=pk_TR6h_8JIQjaZVVtiC3TeJA`;
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredPosts(
      facebookPosts.filter(
        (post) =>
          post.message?.toLowerCase().includes(lowerCaseQuery) ||
          post.group?.toLowerCase().includes(lowerCaseQuery) ||
          post.pageName?.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  const handleSubmitPost = async () => {
    const pageName = document.getElementById("pageName").value;
    const postId = document.getElementById("postId").value;
    const postContent = document.getElementById("postContent").value;
    const source = selectedPlatform; // Use the selected platform
    const postDate = document.querySelector("input[type='date']").value;
    const reach = parseInt(document.getElementById("reach").value, 10); // Get reach value

    if (
      !pageName ||
      !postId ||
      !postContent ||
      !postDate ||
      !source ||
      isNaN(reach)
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Include the group and reach only if Facebook is selected
    const formData = {
      pageName,
      postId,
      message: postContent,
      source,
      createdTime: postDate,
      reach, // Add reach to the payload
      group: source === "Facebook" ? selectedGroup : null, // Include group if Facebook is selected
    };

    try {
      const response = await axios.post(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/posts`,
        formData
      );

      if (response.status === 201) {
        toast.success("Post added successfully");
        setPopupVisible(false);
        setFacebookPosts((prevPosts) => [...prevPosts, response.data]);
      }
    } catch (error) {
      console.error("Error adding post:", error);
      toast.error("Failed to add post");
    }
  };

  // Increase visible articles
  const showMoreArticles = () => {
    setVisiblePosts((prev) => Math.min(prev + 20, filteredPosts.length)); // Ensure it doesn't exceed total articles
  };

  // Decrease visible articles
  const showLessArticles = () => {
    setVisiblePosts((prev) => Math.max(prev - 20, 20)); // Ensure it doesn't go below 20
  };

  // Show all articles
  const showAllArticles = () => {
    setVisiblePosts(filteredPosts.length); // Show all articles
  };

  return (
    <div>
      {popupVisible && (
        <div className="post-popup">
          <div className="post-popup-inner" ref={popupRef}>
            <button
              className="post-cancel-button"
              onClick={() => setPopupVisible(false)}
            >
              &times;
            </button>
            <h3 className="post-popup-header" style={{ marginBottom: "30px" }}>
              Add New Post
            </h3>
            <p
              className="post-popup-description"
              style={{ marginBottom: "30px" }}
            >
              Complete the form to manually add a post
            </p>

            {/* Platform Selection */}
            <div className="social-form-group">
              <select
                id="source"
                name="source"
                value={selectedPlatform}
                onChange={(e) => setSelectedPlatform(e.target.value)} // Update platform selection
              >
                <option value="">Select Platform</option>
                <option value="Facebook">Facebook</option>
                <option value="X">X (Twitter)</option>
                <option value="LinkedIn">LinkedIn</option>
              </select>
            </div>

            {/* Conditional Rendering for Facebook Group Selection */}
            {selectedPlatform === "Facebook" && (
              <div className="social-form-group">
                <select
                  id="facebookGroup"
                  name="facebookGroup"
                  value={selectedGroup}
                  onChange={(e) => setSelectedGroup(e.target.value)} // Update group selection
                >
                  <option value="">Select Group</option>
                  <option value="Name & Shame BW">Name & Shame BW</option>
                  <option value="Consumer Watchdog BW">
                    Consumer Watchdog BW
                  </option>
                </select>
              </div>
            )}

            {/* Other form fields */}
            <div className="form-group">
              <input
                type="text"
                id="postId"
                name="postId"
                placeholder="Enter URL eg., https://facebook.com/..."
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="pageName"
                name="pageName"
                placeholder="Enter Page Name"
              />
            </div>
            <div className="form-group">
              <textarea
                id="postContent"
                name="postContent"
                placeholder="Content..."
              ></textarea>
            </div>
            <div className="form-group">
              <input
                type="date"
                id="author"
                name="author"
                placeholder="Date posted"
              />
            </div>

            {/* Reach Input */}
            <div className="form-group">
              <input
                type="number"
                id="reach"
                name="reach"
                placeholder="Enter Reach (e.g., 1000)"
              />
            </div>

            <button
              onClick={() => handleSubmitPost()}
              className="submit-post-btn"
              style={{ marginTop: "30px" }}
            >
              Add Post
            </button>
          </div>
        </div>
      )}

      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <div className="content">
        <h2>{organizationData?.organization.organizationName} Social Media</h2>
        <div className="holder">
          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="filters">
              <select
                value={articleSentiment}
                onChange={(e) => setArticleSentiment(e.target.value)}
              >
                <option value="">Post Sentiment ☺</option>
                <option value="positive">Positive</option>
                <option value="neutral">Neutral</option>
                <option value="negative">Negative</option>
                <option value="mixed">Mixed</option>
              </select>
              <select
                value={aveSortOrder}
                onChange={(e) => setAveSortOrder(e.target.value)}
              >
                <option value="">AVE Sort Order</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
              <select
                value={reachSortOrder}
                onChange={(e) => setReachSortOrder(e.target.value)}
              >
                <option value="">Reach Sort Order</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
            </div>
            {/* Button visible only for super_admin */}
            {user.role === "super_admin" && (
              <button
                onClick={() => setPopupVisible(true)}
                className="add-post-btn"
              >
                Add New Post
              </button>
            )}

            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Consumer Group</th>
                      <th>Date Posted</th>
                      <th>Sentiment</th>
                      <th>AVE</th>
                      <th>Relevancy Score</th>
                      <th>Reach</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPosts.length === 0 ? (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No posts found yet for this organization.
                        </td>
                      </tr>
                    ) : (
                      [...filteredPosts] // Make a copy of the array to avoid mutating the original state
                        .sort(
                          (a, b) =>
                            new Date(b.createdTime) - new Date(a.createdTime)
                        ) // Sort by publication_date (latest first)
                        // .slice(0, 8)
                        .slice(0, visiblePosts)
                        .map((post, index) => (
                          <tr key={`fb-post-${index}`}>
                            <td style={{ fontWeight: "600" }}>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <img
                                  src={generateLogoUrl("Facebook")} // You can replace "Facebook" with logic to determine platform dynamically
                                  alt="Facebook logo"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    border: "0.5px solid",
                                    padding: "1px",
                                  }}
                                />
                                Facebook
                              </div>
                            </td>
                            <td>
                              <a
                                href={`https://facebook.com/${post.postId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {post.message && post.message.length > 30
                                  ? `${post.message.substring(0, 30)}...`
                                  : post.message || "N/A"}
                              </a>{" "}
                              ⤴
                            </td>
                            <td className="table-author">
                              {post.pageName || "-"}
                            </td>
                            <td>{post.group ? post.group : "-"}</td>
                            <td
                              style={{
                                // color: "#414141",
                                fontWeight: "500",
                              }}
                            >
                              {new Date(post.createdTime).toLocaleDateString()}
                            </td>
                            <td>
                              {/* Display sentiment icon with text on the right side */}
                              <div className="sentiment-container">
                                {post.sentiment > 0 ? (
                                  <div className="sentiment positive">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsUp className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Positive
                                    </span>
                                  </div>
                                ) : post.sentiment < 0 ? (
                                  <div className="sentiment negative">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsDown className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Negative
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sentiment neutral">
                                    <div className="sentiment-icon-container">
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Neutral
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              style={{
                                // color: "#414141",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {post.ave ? `${post.ave.toFixed(2)} BWP` : "N/A"}
                            </td>
                            <td
                              style={{
                                // color: "#414141",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {post.rank
                                ? `${parseFloat(post.rank).toFixed(1)}/5`
                                : "N/A"}
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {post.reach ? post.reach : "N/A"}
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() => handleMenuClick(index)}
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() => handleMenuClose(index)}
                                >
                                  <a
                                    href={`https://facebook.com/${post.postId}`}
                                    download
                                  >
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Post&body=https://facebook.com/${post.postId}`}
                                  >
                                    Send Post
                                  </a>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDelete(post._id);
                                    }}
                                    className="delete-link"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredPosts.length > 20 && (
                <div className="show-more-container">
                  <p
                    className="articles-table-count"
                    style={{ marginBottom: "10px" }}
                  >
                    Showing {Math.min(visiblePosts, filteredPosts.length)} of{" "}
                    {filteredPosts.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                    }}
                  >
                    {visiblePosts < filteredPosts.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visiblePosts > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visiblePosts < filteredPosts.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Social;
