import React from "react";
import ReactDOM from "react-dom";
import "../CSS/CountryModal.css";  // Import the CSS file for styling

const CountryModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="countryModal">
            <div className="modalContent">
                {children}
                <button onClick={onClose} className="closeButton">&times;</button>
            </div>
        </div>,
        document.body
    );
};

export default CountryModal;
