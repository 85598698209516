import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../Components/Header";
import { MdEdit, MdDelete } from "react-icons/md";
import axios from "axios";
import BannerUpload from "../Components/Banner";
import { ThemeContext } from "../Components/ThemeContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { createPopper } from "@popperjs/core";
import "../CSS/Alerts.css";
import "../App.css";

const Alerts = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const [organizationData, setOrganizationData] = useState(null);
  const selectedOrg = localStorage.getItem("selectedOrg");
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertName, setAlertName] = useState("");
  const [subject, setSubject] = useState("");
  const [startDate, setStartDate] = useState(null); // Changed to Date object
  const [schedule, setSchedule] = useState("");
  const [delivery, setDelivery] = useState("");
  const [externalUsers, setExternalUsers] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState(null);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setFilteredAlerts(alerts); // Initialize filteredAlerts with all alerts
  }, [alerts]);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        let response;
        if (user.role === "super_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
          );
        } else if (user.role === "org_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`
          );
        }

        setOrganizationData(response.data);
      } catch (error) {
        console.error("Error fetching organization data:", error);
        setError("Failed to load organization data.");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
    fetchOrgData();
  }, [user.role, selectedOrg, navigate, user.organizationId]);

  const fetchAlerts = async () => {
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/alerts/${selectedOrg}`
      );
      setAlerts(response.data);
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const alertData = {
      organizationId: selectedOrg,
      alertName,
      subject,
      startDate,
      schedule,
      delivery,
      externalUsers: externalUsers
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email),
      isActive: true, // Set alert as active by default
    };

    try {
      const response = await axios.post(
        "https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/create-alert",
        alertData
      );
      setAlerts([...alerts, response.data]);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating alert:", error);
    }
  };

  const handleEdit = (alertId) => {
    navigate(`/alerts/edit/${alertId}`);
  };

  const handleDelete = async (alertId) => {
    if (window.confirm("Are you sure you want to delete this alert?")) {
      try {
        await axios.delete(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/alerts/${alertId}`
        );
        setAlerts(alerts.filter((alert) => alert._id !== alertId)); // Remove from UI
      } catch (error) {
        console.error("Error deleting alert:", error);
      }
    }
  };

  //avatar random colours
  const getColor = (email) => {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }

    // Adjust brightness for dark mode (lighter in dark mode)
    const brightnessFactor = theme === "dark" ? 1.3 : 1.0; // Increase brightness in dark mode

    const adjustBrightness = (hex, factor) => {
      let decimal = parseInt(hex, 16);
      decimal = Math.min(255, Math.floor(decimal * factor)); // Ensure it doesn't exceed 255
      return decimal.toString(16).padStart(2, "0"); // Convert back to hex
    };

    const r = adjustBrightness(color.substring(1, 3), brightnessFactor);
    const g = adjustBrightness(color.substring(3, 5), brightnessFactor);
    const b = adjustBrightness(color.substring(5, 7), brightnessFactor);

    const adjustedColor = `#${r}${g}${b}`;

    const backgroundColor = `${adjustedColor}55`; // Slightly transparent
    const borderColor = `${adjustedColor}FF`; // Solid
    const textColor = `${adjustedColor}FF`; // Solid

    return { backgroundColor, borderColor, textColor };
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowerCaseQuery = query.toLowerCase();

    setFilteredAlerts(
      alerts.filter(
        (alert) =>
          alert.alertName.toLowerCase().includes(lowerCaseQuery) ||
          alert.subject.toLowerCase().includes(lowerCaseQuery) ||
          alert.externalUsers.some((email) =>
            email.toLowerCase().includes(lowerCaseQuery)
          )
      )
    );
  };

  return (
    <div>
      {isModalOpen && (
        <div
          className="alert-modal"
          onClick={(e) => {
            if (e.target.className === "alert-modal") {
              setIsModalOpen(false);
            }
          }}
        >
          <div className="alert-modal-content">
            <div className="modal-inner">
              <div className="modal-header">
                <h3 style={{ fontSize: "25px" }}>Set up Alert</h3>
                <span className="close" onClick={() => setIsModalOpen(false)}>
                  &times;
                </span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-section">
                  <h4>Basic Information</h4>
                  <div className="form-group">
                    <label className="alert-label">Alert Name</label>
                    <input
                      type="text"
                      value={alertName}
                      onChange={(e) => setAlertName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="alert-label">Subject</label>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-section">
                  <h4>Banner Upload</h4>
                  <BannerUpload onBannerChange={(file) => setBanner(file)} />
                </div>
                <div className="form-section">
                  <h4>Scheduling</h4>
                  <div className="form-group">
                    <label className="alert-label">Start Date</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="custom-date-picker"
                      calendarClassName="date-picker-dropdown"
                      popperPlacement="bottom-start" // Ensures dropdown appears below
                      dateFormat="yyyy/MM/dd"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="alert-label">Schedule</label>
                    <select
                      value={schedule}
                      onChange={(e) => {
                        setSchedule(e.target.value);
                        setDelivery("");
                      }}
                    >
                      <option value="">Select Schedule</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                  {schedule && (
                    <div className="form-group">
                      <label className="alert-label">Delivery</label>
                      {schedule === "daily" && (
                        <select
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                        >
                          {Array.from({ length: 24 }, (_, i) => (
                            <option
                              key={i}
                              value={`${i}:00`}
                            >{`${i}:00`}</option>
                          ))}
                        </select>
                      )}
                      {schedule === "weekly" && (
                        <select
                          value={delivery}
                          onChange={(e) => setDelivery(e.target.value)}
                        >
                          <option value="monday">Monday</option>
                          <option value="tuesday">Tuesday</option>
                          <option value="wednesday">Wednesday</option>
                          <option value="thursday">Thursday</option>
                          <option value="friday">Friday</option>
                          <option value="saturday">Saturday</option>
                          <option value="sunday">Sunday</option>
                        </select>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-section">
                  <h4>Recipients</h4>
                  <label className="alert-label">
                    Deliver To (separate emails with commas)
                  </label>
                  <textarea
                    value={externalUsers}
                    onChange={(e) => setExternalUsers(e.target.value)}
                    placeholder="Enter email addresses, separated by commas"
                  />
                </div>
                <button className="alert-submit" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
        />
      </div>
      <div className="content">
        <h2>{organizationData?.organization?.organizationName} Alerts</h2>
        <div className="holder">
          <div className="alerts">
            <div className="search-input-container">
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="create-alerts">
              <button
                className="alert-button"
                onClick={() => setIsModalOpen(true)}
              >
                Create Alert
              </button>
            </div>
          </div>
          <div className="alerts-table-container">
            <hr className="separator-line" />
            <table className="alerts-table">
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>People</th>
                  <th>Status</th>
                  <th>Schedule</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAlerts.map((alert, index) => (
                  <tr key={index}>
                    <td>{alert.alertName}</td>
                    <td>
                      <div className="user-avatars">
                        {alert.externalUsers.map((email, idx) => {
                          const { backgroundColor, borderColor, textColor } =
                            getColor(email);
                          return (
                            <div
                              key={idx}
                              className="avatar"
                              title={email}
                              style={{
                                backgroundColor,
                                border: `2px solid ${borderColor}`,
                                color: textColor,
                              }}
                            >
                              {email.substring(0, 2).toUpperCase()}
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <span
                        className={`status-indicator ${
                          alert.isActive ? "active" : "active"
                        }`}
                      >
                        {alert.isActive ? "Active" : "Active"}
                      </span>
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {alert.schedule || alert.delivery}
                    </td>
                    <td>
                      <div className="actions-container">
                        <div
                          className="action-icon"
                          onClick={() => handleEdit(alert._id)}
                        >
                          <MdEdit size={17} />
                        </div>
                        <div
                          className="action-icon"
                          onClick={() => handleDelete(alert._id)}
                        >
                          <MdDelete size={17} style={{ color: "red" }} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
