/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import "../CSS/EditAlerts.css";
import { getColorFromEmail } from './ColorUtil';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';

const EditAlert = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const { alertId } = useParams();
    const navigate = useNavigate();

    const [alertData, setAlertData] = useState({
        alertName: '',
        subject: '',
        newsletterHeader: '',
        newsletterFooter: '',
        displayImages: '',
        topic: '',
        mediaType: '',
        sentiment: '',
        startDate: '',
        schedule: '',
        delivery: '',
        excludeDays: [],
        externalUsers: []
    });

    const [ispopupOpen, setIspopupOpen] = useState(false); // popup visibility state
    const [popupInput, setpopupInput] = useState(''); // Input for popup
    const [popupType, setpopupType] = useState(''); // Type of popup ('user' or 'topic')

    // Fetch alert data
    useEffect(() => {
        const fetchAlertData = async () => {
            try {
                const response = await axios.get(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/alerts/details/${alertId}`);
                setAlertData(response.data);
            } catch (error) {
                console.error('Error fetching alert data:', error);
            }
        };

        fetchAlertData();
    }, [alertId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAlertData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/alerts/update/${alertId}`, alertData);
            console.log('Alert updated successfully:', response.data);
            navigate('/alerts/:orgId');
        } catch (error) {
            console.error('Error updating alert:', error);
        }
    };

    // Open the popup with a specified type ('user' or 'topic')
    const openpopup = (type) => {
        setpopupType(type);
        setpopupInput(''); // Reset input field
        setIspopupOpen(true); // Open popup
    };

    // Close the popup
    const closepopup = () => {
        setIspopupOpen(false);
    };

    // Save the input from the popup
    const savePopupInput = () => {
        if (popupType === 'user' && popupInput) {
            setAlertData(prevData => ({
                ...prevData,
                externalUsers: [...prevData.externalUsers, popupInput]
            }));
        } else if (popupType === 'topic' && popupInput) {
            setAlertData(prevData => ({
                ...prevData,
                topic: popupInput
            }));
        }
        closepopup();
    };

    const deleteExternalUser = index => {
        const filteredUsers = alertData.externalUsers.filter((_, i) => i !== index);
        setAlertData(prevData => ({
            ...prevData,
            externalUsers: filteredUsers
        }));
    };

    const deleteTopic = () => {
        setAlertData(prevData => ({
            ...prevData,
            topic: ''
        }));
    };


    return (
        <div>
            <div className="container">
                <Header userName={`${user.firstName} ${user.lastName}`} userRole={user.role} />
            </div>
            <div className="content">
                <h2>Edit Alert</h2>
                <form onSubmit={handleSubmit}>
                    <div className="alert-edits">
                        <div className="about-alert">
                            <h4>About Alert</h4>

                            <div>
                                <label>Alert Name</label>
                                <input type="text" name="alertName" value={alertData.alertName} onChange={handleChange} />
                            </div>
                            <div>
                                <label>Subject</label>
                                <input type="text" name="subject" value={alertData.subject} onChange={handleChange} />
                            </div>
                            <div>
                                <label>Newsletter Header</label>
                                <textarea name="newsletterHeader" value={alertData.newsletterHeader} onChange={handleChange}></textarea>
                            </div>
                            <div>
                                <label>Newsletter Footer</label>
                                <textarea name="newsletterFooter" value={alertData.newsletterFooter} onChange={handleChange}></textarea>
                            </div>
                        </div>
                        <div className="right-edits">
                            <div className="associated-users">
                                <div className="associated-users-header">
                                    <h4>Associated Users</h4>
                                    <div className="action-icon" onClick={() => openpopup('user')}>
                                        <MdAdd size={24} />
                                    </div>
                                </div>
                                <ul className="user-list">
                                    {alertData.externalUsers.length > 0 && alertData.externalUsers.map((email, index) => {
                                        const { bgColor, borderColor } = getColorFromEmail(email);
                                        return (
                                            <li key={index} className="user-item">
                                                <div className="avatar" style={{ backgroundColor: bgColor, borderColor, borderWidth: '1px', borderStyle: 'solid' }}>
                                                    {email.substring(0, 2).toUpperCase()}
                                                </div>
                                                <div className="user-info-alert">
                                                    <p className="user-name-alert">{email}</p>
                                                    <div className="action-icon delete-icon">
                                                        <MdDelete size={24} onClick={() => deleteExternalUser(index)} />

                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="associated-topics">
                                <div className="associated-users-header">
                                    <h4>Associated Topic</h4>
                                    <div className="action-icon" onClick={() => openpopup('topic')}>
                                        <MdAdd size={24} />
                                    </div>
                                </div>
                                <ul className="user-list">
                                    <li className="topic-item">
                                        <div className="user-info-alert">
                                            <p className="user-name-alert">{alertData.topic}</p>
                                            <div className="action-icon delete-icon">
                                                <MdDelete size={24} onClick={deleteTopic} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="alert-submission">Update Alert</button>
                </form>

                {/* Inline popup */}
                {ispopupOpen && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <div className="popup-header">
                                <h3>{popupType === 'user' ? 'Add User Email' : 'Add Topic'}</h3>
                            </div>
                            <input
                                className="popup-input"
                                type="text"
                                placeholder={popupType === 'user' ? 'Enter email' : 'Enter topic'}
                                value={popupInput}
                                onChange={(e) => setpopupInput(e.target.value)}
                            />
                            <div className="popup-actions">
                                <a className="save" onClick={savePopupInput}>Save</a>
                                <a className="cancel" onClick={closepopup}>Cancel</a>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default EditAlert;

