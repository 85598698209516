import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import axios from "axios";
import "../CSS/Sidebar.css";
import "../App.css"
import { FaBars, FaTimes, FaHome, FaUser, FaSearch, FaBell, FaNewspaper } from 'react-icons/fa';
import { CgOrganisation } from "react-icons/cg";
import { PiChatsCircleBold } from "react-icons/pi";
import { SiGoogleanalytics } from "react-icons/si";
import { GrChapterAdd } from "react-icons/gr";
import { MdTopic } from "react-icons/md";
import { RiTeamFill, RiSettings3Fill, RiSearchLine } from "react-icons/ri";
import { BsGlobe2 } from "react-icons/bs";
import { LuRadio } from "react-icons/lu";
import { MdExpandMore, MdOutlineLightMode, MdDarkMode } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { ThemeContext } from "./ThemeContext";

const Sidebar = () => {
    const location = useLocation();
    const logoLight = process.env.PUBLIC_URL + '/social.jpg';
    const logoDark = process.env.PUBLIC_URL + '/socialDark.png';
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const [mediaMenuOpen, setMediaMenuOpen] = useState(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [selectedOrgName, setSelectedOrgName] = useState(''); // State to store selected org name
    const { orgId } = useParams(); // Access the orgId from the URL
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Use an empty object if null
 // Retrieve user details from local storage
    const { theme, toggleTheme } = useContext(ThemeContext);

    const toggleSettingsMenu = () => {
        setSettingsMenuOpen(!settingsMenuOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleMediaMenu = () => {
        setMediaMenuOpen(!mediaMenuOpen);
    };


    // Logout function
    const handleLogout = () => {
        const savedTheme = localStorage.getItem("theme"); // Preserve theme
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("organizationId");
        localStorage.removeItem("selectedOrg");
        localStorage.setItem("theme", savedTheme); // Restore theme
        navigate('/login');
    };


    useEffect(() => {
        const selectedOrgId = localStorage.getItem('selectedOrg');
        // Fetch organization name based on selectedOrgId
        const fetchOrganizationName = async () => {
            if (selectedOrgId) {
                try {
                    const response = await axios.get(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrgId}`);
                    setSelectedOrgName(response.data.organizationName); // Assuming the organization name is in this format
                } catch (error) {
                    console.error('Error fetching organization name:', error);
                }
            }
        };

        fetchOrganizationName();
    }, []);

    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <div className="flex-column">
                    <div className="profile">
                        {/* Use dynamic logo based on theme */}
                        <Link to="/">
                            <img
                                src={theme === "light" ? logoLight : logoDark}
                                alt="logo"
                                className="img-fluid"
                            />
                        </Link>
                        <hr className="header-divider" />
                    </div>
                    <div className="menu-icon" onClick={toggleMenu}>
                        {menuOpen ? <FaTimes /> : <FaBars />}
                    </div>

                    <nav id="navbar" className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                        <ul>
                            <li>
                                <Link
                                    to={`/dashboard/${orgId}`}
                                    className={location.pathname.includes('/dashboard') ? 'active' : ''}
                                >
                                    <FaHome className="nav-icon" />
                                    <span>Dashboard {selectedOrgName && `(${selectedOrgName})`}</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/analytics/${orgId}`}
                                    className={location.pathname.includes('/analytics') ? 'active' : ''}
                                >
                                    <SiGoogleanalytics className="nav-icon" />
                                    <span>Analytics</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/competitors/${orgId}`}
                                    className={location.pathname.includes('/competitors') ? 'active' : ''}
                                >
                                    <RiTeamFill className="nav-icon" />
                                    <span>Competitors</span>
                                </Link>
                            </li>
                            <li>
                                <div className="menu-title" onClick={toggleMediaMenu}>
                                    <MdTopic className="nav-icon" />Media
                                    <MdExpandMore className="expand-icon" />
                                </div>
                                {mediaMenuOpen && (
                                    <ul className="submenu">
                                        <li>
                                            <Link
                                                to={`/media/broadcast/${orgId}`}
                                                className={location.pathname.includes('/media/broadcast') ? 'active' : ''}
                                            >
                                                <LuRadio className="nav-icon" />
                                                <span>Broadcast Media</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/media/online/${orgId}`}
                                                className={location.pathname.includes('/media/online') ? 'active' : ''}
                                            >
                                                <BsGlobe2 className="nav-icon" />
                                                <span>Online Media</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/media/print/${orgId}`}
                                                className={location.pathname.includes('/media/print') ? 'active' : ''}
                                            >
                                                <FaNewspaper className="nav-icon" />
                                                <span>Print Media</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/media/social/${orgId}`}
                                                className={location.pathname.includes('/media/social') ? 'active' : ''}
                                            >
                                                <PiChatsCircleBold className="nav-icon" />
                                                <span>Social Media</span>
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Link
                                    to={`/advancedsearch/${orgId}`}
                                    className={location.pathname.includes('/advancedsearch') ? 'active' : ''}
                                >
                                    <RiSearchLine className="nav-icon" />
                                    <span>Advanced Search</span>
                                </Link>
                            </li>
                            {user.role === 'super_admin' && (
                                <li>
                                    <Link
                                        to={`/alerts/${orgId}`}
                                        className={location.pathname.includes('/alerts') ? 'active' : ''}
                                    >
                                        <FaBell className="nav-icon" />
                                        <span>Alerts</span>
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link
                                    to={`/profile/${orgId}`}
                                    className={location.pathname.includes('/profile') ? 'active' : ''}
                                >
                                    <FaUser className="nav-icon" />
                                    <span>Profile</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="footer-nav">
                        <hr className="footer-divider" />
                        <ul>
                            {/* Theme Toggle */}
                            <li className="theme-toggle" onClick={toggleTheme}>
                                {theme === 'light' ? (
                                    <>
                                        <MdDarkMode className="nav-icon" />
                                        <span>Dark Mode</span>
                                    </>
                                ) : (
                                    <>
                                        <MdOutlineLightMode className="nav-icon" />
                                        <span>Light Mode</span>

                                    </>
                                )}
                            </li>
                            <li>
                                <div className="menu-title" onClick={toggleSettingsMenu}>
                                    <RiSettings3Fill className="nav-icon" />Settings
                                    <MdExpandMore className="expand-icon" />
                                </div>
                                {settingsMenuOpen && (
                                    <ul className="submenu">
                                        <li><Link to={`/settings/organization/${orgId}`}><CgOrganisation className="nav-icon" /><span>Organizations</span></Link></li>
                                        {/* {user.role === 'super_admin' && (
                                            <li><Link to={`/settings/addarticle/${orgId}`}><GrChapterAdd className="nav-icon" /><span>Add Article</span></Link></li>
                                        )} */}
                                    </ul>
                                )}
                            </li>
                            <li onClick={handleLogout} className="logout-link">
                                <AiOutlineLogout className="nav-icon" /><span>Logout</span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
