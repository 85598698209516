import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Components/Header";
import { Pie, Bar } from "react-chartjs-2";
import { IoInformationCircle } from "react-icons/io5";
import {
  FaChartBar,
  RegCalendarAlt,
  FaKey,
  FaRegListAlt,
  FaEllipsisV,
  FaRegCalendarAlt,
  FaRegFilePdf,
  FaRegFileExcel,
  FaRegFileImage,
} from "react-icons/fa";
import "../CSS/Dashboard.css";
import "../App.css";
import { Toaster, toast } from "sonner";
import { ThemeContext } from "../Components/ThemeContext";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { autoTable } from "jspdf-autotable";
import CustomReports from "../Components/CustomReports";

const Analytics = () => {
  const [organizationData, setOrganizationData] = useState(null);
  const [articles, setArticles] = useState([]);
  const [facebookPosts, setFacebookPosts] = useState([]);
  const [keywordDistribution, setKeywordDistribution] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [error, setError] = useState(null);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [articlesThisMonth, setArticlesThisMonth] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [totalTopics, setTotalTopics] = useState(0);
  const [monthlyArticles, setMonthlyArticles] = useState(0);
  const [infoTooltip, setInfoTooltip] = useState(null);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [contentType, setContentType] = useState("posts"); // Default to 'posts'
  const [granularity, setGranularity] = useState("month"); // Default to 'month'
  const [countOverTimeData, setCountOverTimeData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility
  const [isChartHovered, setIsChartHovered] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showCustomReportModal, setShowCustomReportModal] = useState(false);
  const dropdownRef = useRef(null);

  const Modal = ({ show, onClose, records, selectedDate, contentType }) => {
    if (!show) return null;

    return (
      <div className="popup" onClick={onClose}>
        <div className="popup-inner" onClick={(e) => e.stopPropagation()}>
          <button className="cancel-button" onClick={onClose}>
            &times;
          </button>
          <h3 className="popup-header">
            Records for {selectedDate} ({contentType})
          </h3>
          <p className="popup-description">
            Below is the detailed record list.
          </p>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Source</th>
                  <th>Published Date</th>
                  <th>Reach</th>
                  <th>AVE</th>
                </tr>
              </thead>
              <tbody>
                {records.length > 0 ? (
                  records.map((record, index) => (
                    <tr key={index}>
                      <td>{record.title || "N/A"}</td>
                      <td>{record.source || "N/A"}</td>
                      <td>
                        {new Date(record.publication_date).toLocaleDateString()}
                      </td>
                      <td>
                        {record.reach ? record.reach.toLocaleString() : "N/A"}
                      </td>
                      <td>
                        {record.ave ? record.ave.toLocaleString() : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No records found for this selection.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchCountOverTime = async () => {
      try {
        if (!selectedOrg) return;

        console.log(
          `Fetching data: organizationId=${selectedOrg}, contentType=${contentType}, granularity=${granularity}`
        );

        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/count-over-time?organizationId=${selectedOrg}&contentType=${contentType}&granularity=${granularity}`
        );

        console.log("DEBUG: Count Over Time Response:", response.data);
        setCountOverTimeData(response.data); // Save the response data
      } catch (error) {
        console.error("Error fetching count over time:", error);
        toast.error("Failed to load count data.");
      }
    };

    fetchCountOverTime();
  }, [selectedOrg, contentType, granularity]); // Re-fetch when contentType or granularity changes

  const countOverTimeChartData = {
    labels: countOverTimeData.map((item) => {
      if (!item.date) return "Unknown Date"; // Handle missing dates

      if (granularity === "week") {
        return `${item.date.split("-W")[0]}-W${item.date.split("-W")[1]}`;
      } else if (granularity === "day") {
        return new Date(item.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      } else if (granularity === "month") {
        return item.date.split("-")[0] + "-" + item.date.split("-")[1];
      } else {
        return item.date.split("-")[0]; // For Year
      }
    }),
    datasets: [
      {
        label: `${contentType} Count`,
        data: countOverTimeData.map((item) => item.count || 0),
        backgroundColor: "rgb(66, 162, 241)", // Blue
        // borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        borderRadius: 5,
        yAxisID: "y1",
      },
      {
        label: "Total AVE",
        data: countOverTimeData.map((item) => item.totalAVE || 0),
        backgroundColor: "rgb(17, 221, 81)", // Green
        // borderColor: "rgba(144, 238, 144, 1)",
        borderWidth: 1,
        borderRadius: 5,
        yAxisID: "y2",
      },
      {
        label: "Total Reach",
        data: countOverTimeData.map((item) => item.totalReach || 0),
        backgroundColor: "rgb(147, 79, 255)", // Light Blue
        // borderColor: "rgba(0, 191, 255, 1)",
        borderWidth: 1,
        borderRadius: 5,
        yAxisID: "y2",
      },
    ],
  };

  const [detailedRecords, setDetailedRecords] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChartClick = async (event, elements) => {
    if (!elements.length) return; // If no bar was clicked, exit

    const clickedIndex = elements[0].index;
    const selectedDate = countOverTimeData[clickedIndex].date;
    const selectedContentType = contentType;

    console.log(`Clicked on: ${selectedDate} - ${selectedContentType}`);

    setSelectedDate(selectedDate);

    // Fetch detailed records
    await fetchDetailedRecords(selectedDate, selectedContentType);

    // Open modal after fetching data
    setIsModalOpen(true);
  };

  const fetchDetailedRecords = async (date, contentType) => {
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/detailed-records?organizationId=${selectedOrg}&contentType=${contentType}&date=${date}&granularity=${granularity}`
      );

      setDetailedRecords(response.data); // Store the records
      console.log("Fetched Detailed Records:", response.data);
    } catch (error) {
      console.error("Error fetching detailed records:", error);
      toast.error("Failed to load records.");
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleDownload = (format) => {
    if (!countOverTimeData.length) {
      toast.error("No data available to download.");
      return;
    }

    // Get organization name for file naming
    const orgName =
      organizationData?.organization?.organizationName || "Organization";

    if (format === "png") {
      const chartCanvas = document.getElementById("chartCanvas"); // Get Chart.js canvas
      if (!chartCanvas) {
        toast.error("Chart not found!");
        return;
      }

      const chartImage = chartCanvas.toDataURL("image/png"); // Convert chart to image
      const link = document.createElement("a");
      link.href = chartImage;
      link.download = `${orgName}_filtered_chart_${contentType}_${granularity}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const fileName = `filtered_data_${orgName}_${contentType}_${granularity}`;

    // Extract the filtered data (same logic for all formats)
    const tableData = countOverTimeData.map((item) => [
      item.date, // Year, Month, Week, or Day based on granularity
      item.count || 0, // Total count of filtered content
      item.totalAVE ? item.totalAVE.toLocaleString() : "0.00", // Sum of AVE
      item.totalReach ? item.totalReach.toLocaleString() : "0", // Sum of Reach
    ]);

    const headers = [
      [
        `CreatedTime: ${granularity.toUpperCase()}`,
        "Count",
        "Sum of AVE",
        "Sum of Reach",
      ],
    ];

    // ✅ CSV Download
    if (format === "csv") {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += headers[0].join(",") + "\n"; // Add headers
      csvContent += tableData.map((row) => row.join(",")).join("\n"); // Add rows

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // ✅ XLSX Download
    else if (format === "xlsx") {
      const ws = XLSX.utils.aoa_to_sheet([...headers, ...tableData]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Filtered Data");

      XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    // ✅ PDF Download
    if (format === "pdf") {
      const doc = new jsPDF();

      // Ensure autoTable is available
      autoTable(doc, {
        head: headers,
        body: tableData,
        startY: 25,
      });

      doc.text(
        `${orgName} - Filtered Data (${contentType.toUpperCase()} - ${granularity.toUpperCase()})`,
        14,
        15
      );

      doc.save(`${fileName}.pdf`);
    }
  };

  // sipho pasted her functions above here

  // Generate colors for chart sections
  const getGradient = (ctx, index) => {
    const gradient = ctx.createRadialGradient(100, 100, 50, 100, 100, 200);
    const hue = (index * 47) % 360;

    gradient.addColorStop(0, `hsl(${hue}, 90%, 40%)`); // Darker center
    gradient.addColorStop(1, `hsl(${hue}, 90%, 75%)`); // Lighter edge

    return gradient;
  };

  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 2,
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgUrl = `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`;

        // Fetching organization, articles, and posts in parallel
        const [orgResponse, articlesResponse, postsResponse] =
          await Promise.all([
            axios.get(orgUrl),
            axios.get(`${orgUrl}/articles`),
            axios.get(`${orgUrl}/posts`),
          ]);

        // Set organization data
        setOrganizationData(orgResponse.data);

        // Extract totalKeywords from organization
        const totalKeywordsCount =
          orgResponse.data.organization.keywords?.length || 0;
        setTotalKeywords(totalKeywordsCount); // Store total keywords count

        // Handle articles
        const articlesData = articlesResponse.data.articles || [];
        setArticles(articlesData);
        setDisplayedArticles(articlesData.slice(0, 8)); // Display the first 8 articles
        setTotalArticles(articlesData.length);

        // Calculate articles for the current month
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const monthlyArticlesCount = articlesData.filter((article) => {
          const articleDate = new Date(article.publication_date);
          return (
            articleDate.getMonth() === currentMonth &&
            articleDate.getFullYear() === currentYear
          );
        }).length;

        setArticlesThisMonth(monthlyArticlesCount);

        // Handle posts
        setFacebookPosts(postsResponse.data.posts || []);
        setTotalPosts(postsResponse.data.posts.length || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
        toast.error("Failed to load data");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) fetchData();
  }, [selectedOrg, navigate]); // Only run when `selectedOrg` changes

  // Process keyword distribution
  useEffect(() => {
    if (articles.length > 0) {
      const keywordMap = {};

      articles.forEach((article) => {
        article.matched_keywords.forEach((keyword) => {
          if (!keywordMap[keyword]) {
            keywordMap[keyword] = { count: 0, sources: new Set() };
          }
          keywordMap[keyword].count += 1;
          keywordMap[keyword].sources.add(article.source);
        });
      });

      // Convert sources Set to an array
      Object.keys(keywordMap).forEach((key) => {
        keywordMap[key].sources = Array.from(keywordMap[key].sources);
      });

      setKeywordDistribution(keywordMap);
    }
  }, [articles]);

  // Prepare pie chart data
  useEffect(() => {
    if (Object.keys(keywordDistribution).length > 0) {
      const labels = Object.keys(keywordDistribution);
      const data = labels.map((key) => keywordDistribution[key].count);

      setPieData({
        labels,
        datasets: [
          {
            data,
            backgroundColor: (context) => {
              const chart = context.chart;
              const ctx = chart.ctx;
              return labels.map((_, index) => getGradient(ctx, index));
            },
            borderColor: "transparent",
            borderWidth: 2,
            borderRadius: 5,
            spacing: 5, // Creates spacing between slices
          },
        ],
      });
    }
  }, [keywordDistribution]);

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true, // Use custom point style
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
          pointStyle: "roundedRect",
          boxWidth: 10,
          boxHeight: 10,
          padding: 15,
          left: 15,
          font: {
            size: 14, // Adjust font size if needed
            family: "Raleway",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const keyword = tooltipItem.label;
            const count = keywordDistribution[keyword]?.count;
            const sources = keywordDistribution[keyword]?.sources.join(", ");
            return `${keyword}: ${count} (Sources: ${sources})`;
          },
        },
      },
      datalabels: {
        color: theme === "light" ? "#ffffffd2" : "#ffffffd2",
        font: { weight: "bold", size: 12, family: "Raleway" },
        formatter: (value) => `${value}`,
        anchor: "end",
        align: "start",
        offset: 10,
      },
    },
    cutout: "50%", // Keeps the donut shape
  };

  // BAR CHART
  const gradientBlues = [
    "rgba(173, 216, 230, 0.8)",
    "rgba(100, 149, 237, 0.8)",
    "rgba(70, 130, 180, 0.8)",
    "rgba(0, 105, 148, 0.8)",
    "rgba(0, 75, 115, 0.8)",
  ];
  const borderColors = [
    "rgb(173, 216, 230)",
    "rgb(100, 149, 237)",
    "rgb(70, 130, 180)",
    "rgb(0, 105, 148)",
    "rgb(0, 75, 115)",
  ];

  // Generate dataset for the current year
  const currentYear = new Date().getFullYear();

  const articlesByMonthThisYear = new Array(12).fill(0); // Initialize array for 12 months
  articles.forEach((article) => {
    const articleDate = new Date(article.publication_date);
    if (articleDate.getFullYear() === currentYear) {
      const month = articleDate.getMonth(); // 0-indexed
      articlesByMonthThisYear[month] += 1; // Increment count for the corresponding month
    }
  });

  // Group articles by year and month
  const articlesByYear = articles.reduce((acc, article) => {
    const date = new Date(article.publication_date);
    const year = date.getFullYear();
    const month = date.getMonth();

    if (!acc[year]) {
      acc[year] = new Array(12).fill(0); // Initialize array with 12 months
    }

    acc[year][month] += 1; // Increment count for the month
    return acc;
  }, {});

  // Generate datasets for each year, cycling through the gradient colors
  const barDatasets = Object.entries(articlesByYear).map(
    ([year, monthlyData], index) => ({
      label: `Articles in ${year}`,
      data: monthlyData,
      backgroundColor: gradientBlues[index % gradientBlues.length],
      borderColor: borderColors[index % borderColors.length],
      // borderWidth: 2,
      borderRadius: 5,
    })
  );

  // Bar chart data with multiple datasets for each year
  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: barDatasets,
  };

  const barOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
      y: {
        beginAtZero: true,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          font: { family: "Raleway", size: 12 },
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
          usePointStyle: true, // Use custom point style
          pointStyle: "roundedRect",
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.raw} Articles in ${
              tooltipItem.dataset.label.split(" ")[2]
            }`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  // Define fixed month labels (January to December)
  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Prepare Monthly Summary with all months filled
  const filledMonthlySummary = Array.from({ length: 12 }, (_, index) => {
    const monthData = monthlySummary.find((item) => item.month === index + 1);
    return {
      month: index + 1,
      totalPosts: monthData?.totalPosts || 0,
      totalArticles: monthData?.totalArticles || 0,
      totalReach: monthData?.totalReach || 0,
      totalAVE: monthData?.totalAVE || 0,
    };
  });

  // Fetch Monthly Summary
  useEffect(() => {
    const fetchMonthlySummary = async () => {
      try {
        if (!selectedOrg) throw new Error("No organization selected.");
        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/monthly-summary`
        );
        setMonthlySummary(response.data);
      } catch (error) {
        console.error("Error fetching monthly summary:", error.message);
        setError("Failed to load monthly summary.");
        toast.error("Failed to fetch monthly summary.");
      } finally {
        setLoading(false);
      }
    };

    fetchMonthlySummary();
  }, [selectedOrg]);

  // Filter Monthly Summary into Articles and Posts
  const filledMonthlySummaryArticles = filledMonthlySummary.map((item) => ({
    month: item.month,
    totalVolume: item.totalArticles,
    totalReach: item.totalReach,
    totalAVE: item.totalAVE,
  }));

  const filledMonthlySummaryPosts = filledMonthlySummary.map((item) => ({
    month: item.month,
    totalVolume: item.totalPosts,
    totalReach: item.totalReach,
    totalAVE: item.totalAVE,
  }));

  // Chart Data for Articles
  const articlesChartData = {
    labels: monthLabels,
    datasets: [
      {
        type: "bar",
        label: "Total Articles",
        data: filledMonthlySummaryArticles.map((item) => item.totalVolume),
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Teal
        borderColor: "rgba(75, 192, 192, 1)",
        borderRadius: 2,
        borderWidth: 2,
        yAxisID: "y1",
      },
      {
        type: "line",
        label: "Total Reach",
        data: filledMonthlySummaryArticles.map((item) => item.totalReach),
        borderColor: "rgba(30, 144, 255, 1)", // Dodger Blue
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        yAxisID: "y2",
      },
      {
        type: "line",
        label: "Total AVE",
        data: filledMonthlySummaryArticles.map((item) => item.totalAVE),
        borderColor: "rgba(0, 102, 204, 1)", // Deep Blue
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        yAxisID: "y2",
      },
    ],
  };

  // Chart Data for Social Posts
  const postsChartData = {
    labels: monthLabels,
    datasets: [
      {
        type: "bar",
        label: "Total Posts",
        data: filledMonthlySummaryPosts.map((item) => item.totalVolume),
        backgroundColor: "rgba(100, 193, 255, 0.95)", // Sky Blue
        borderColor: "rgba(54, 162, 235, 1)",
        borderRadius: 2,
        borderWidth: 2,
        yAxisID: "y1",
      },
      {
        type: "line",
        label: "Total Reach",
        data: filledMonthlySummaryPosts.map((item) => item.totalReach),
        borderColor: "rgb(3, 92, 182)", // Dodger Blue
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        yAxisID: "y2",
      },
      {
        type: "line",
        label: "Total AVE",
        data: filledMonthlySummaryPosts.map((item) => item.totalAVE),
        borderColor: "rgba(0, 102, 204, 1)", // Deep Blue
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        yAxisID: "y2",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
          usePointStyle: true, // Use custom point style
          pointStyle: "roundedRect",
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          font: {
            size: 12, // Adjust font size if needed
            family: "Raleway",
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw.toLocaleString();
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
      },
      datalabels: { display: false },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
      y1: {
        beginAtZero: true,
        title: { display: true, text: "Volume" },
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
      y2: {
        beginAtZero: true,
        position: "right",
        labels: {
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
          usePointStyle: true, // Use custom point style
          pointStyle: "roundedRect",
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          font: {
            size: 12, // Adjust font size if needed
            family: "Raleway",
          },
        },
        title: { display: true, text: "Reach / AVE" },
        grid: { drawOnChartArea: false },
        ticks: {
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
    },
    animation: { duration: 10 },
  };

  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
        />
        {/* Render CustomReports and pass modal state as props */}
        <CustomReports
          organizationName={
            organizationData?.organization?.organizationName ||
            "Unknown Organization"
          }
          showModal={showCustomReportModal} // Pass modal visibility state
          setShowModal={setShowCustomReportModal} // Pass function to close modal
        />
      </div>
      <div className="content">
        <h2>{organizationData?.organization?.organizationName} Analytics</h2>
        <div className="holder">
          {/* Card Section */}
          <div className="grid">
            <div className="card">
              <FaChartBar className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() =>
                  setInfoTooltip("totalArticles")
                } /* Show tooltip */
                onMouseLeave={() => setInfoTooltip(null)} /* Hide tooltip */
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalArticles" && (
                  <div className="tooltips">
                    Total number of online articles found over time.
                  </div>
                )}
              </div>
              <h4>Total Online Articles Overtime</h4>
              <p>{totalArticles}</p>
            </div>

            <div className="card">
              <FaRegCalendarAlt className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("monthlyArticles")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "monthlyArticles" && (
                  <div className="tooltips">
                    Number of online articles found this month.
                  </div>
                )}
              </div>
              <h4>Total Online Articles this Month</h4>
              <p>{monthlyArticles}</p>
            </div>

            <div className="card">
              <FaKey className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("totalKeywords")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalKeywords" && (
                  <div className="tooltips">
                    Total number of keyphrases matched across articles.
                  </div>
                )}
              </div>
              <h4>Total Keyphrases</h4>
              <p>{totalKeywords}</p>
            </div>

            <div className="card">
              <FaRegListAlt className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("totalTopics")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalTopics" && (
                  <div className="tooltips">
                    The number of different media types where this organization
                    was mentioned.
                  </div>
                )}
              </div>
              <h4>Total Media Types</h4>
              <p>{totalTopics}</p>
            </div>
          </div>
          <br />

          {/* Charts */}
          <div className="analysis-charts">
            <h3 style={{ fontSize: "25px" }}>
              Overall Keyword Trends Across Online Sources
            </h3>
            <div className="piechart2">
              <Pie data={pieData} options={pieOptions} />
            </div>

            <h3 style={{ fontSize: "25px" }}>Articles Over The Years</h3>
            <div className="chart-container2">
              <div className="analytics-bg">
                <Bar data={barData} options={barOptions} />
              </div>
            </div>

            <div className="headerz-container">
              <h3 style={{ fontSize: "25px" }}>
                Content Volume per Reach & AVE
              </h3>
              <div
                className="tooltip-container2"
                onMouseEnter={() => setInfoTooltip("totalTopics")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalTopics" && (
                  <div className="tooltip-box">
                    This chart tracks media content volume (posts, articles,
                    broadcasts, or print media) over time and its correlation
                    with:
                    <p>Total Reach:</p> Estimated audience size.{" "}
                    <p>Total AVE:</p>
                    The financial value of media coverage. <p>Content Count:</p>
                    Total media items published.
                  </div>
                )}
              </div>
            </div>

            <div
              className="chart-container2"
              style={{ position: "relative" }}
              onMouseEnter={() => setIsChartHovered(true)}
              onMouseLeave={() => setIsChartHovered(false)}
            >
              <br />

              <div className="analytics-select">
                <label htmlFor="contentType">Media:</label>
                <select
                  id="contentType"
                  value={contentType}
                  onChange={(e) => setContentType(e.target.value)}
                >
                  <option value="posts">Social Media Posts</option>
                  <option value="articles">Online Articles</option>
                  <option value="broadcast">Broadcast Media</option>
                  <option value="printmedia">Print Media</option>
                </select>
                {/* </div> */}

                {/* <div className="analytics-select"> */}
                <label htmlFor="granularity">Duration:</label>
                <select
                  id="granularity"
                  value={granularity}
                  onChange={(e) => setGranularity(e.target.value)}
                >
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>

              <hr />

              <div className="buttons-container">
                <button
                  className="add-report-btn"
                  onClick={() => setShowCustomReportModal(true)}
                >
                  Create Report
                </button>

                {/* {( dropdownVisible) && ( */}
                <button className="analytics-ellipsis" onClick={toggleDropdown}>
                  <FaEllipsisV />
                </button>
                {/* )} */}
              </div>

              <div className="analytics-bg">
                <Bar
                  id="chartCanvas"
                  data={countOverTimeChartData}
                  options={{
                    ...chartOptions,
                    onClick: handleChartClick,
                  }}
                />

                {/* Dropdown for downloads */}
                {dropdownVisible && (
                  <div
                    ref={dropdownRef}
                    className={`download-dropdown ${
                      dropdownVisible ? "active" : ""
                    }`}
                  >
                    <h4
                      style={{
                        marginBottom: "8px",
                        marginLeft: "5px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Download As:
                    </h4>
                    <div className="format-options">
                      <button
                        className="format-btn"
                        onClick={() => handleDownload("csv")}
                      >
                        <FaRegFileExcel className="icon excel-icon" />
                        <span> CSV</span>
                      </button>

                      <button
                        className="format-btn"
                        onClick={() => handleDownload("xlsx")}
                      >
                        <FaRegFileExcel className="icon excel-icon" />
                        <span> XLSX</span>
                      </button>

                      <button
                        className="format-btn"
                        onClick={() => handleDownload("pdf")}
                      >
                        <FaRegFilePdf className="icon pdf-icon" />
                        <span> PDF</span>
                      </button>

                      <button
                        className="format-btn"
                        onClick={() => handleDownload("png")}
                      >
                        <FaRegFileImage className="icon png-icon" />
                        <span> PNG</span>
                      </button>
                    </div>
                  </div>
                )}

                <Modal
                  show={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  records={detailedRecords}
                  selectedDate={selectedDate}
                  contentType={contentType}
                />
              </div>
            </div>
          </div>

          {/* <div className="charts">
            <div className="chart-container">
              <h3>Online: Volume vs Reach vs AVE</h3>
              <Bar data={articlesChartData} options={chartOptions} />
            </div>
          </div>

          <div className="charts">
            <div className="chart-container">
              <h3>Social: Volume vs Reach vs AVE</h3>
              <Bar data={postsChartData} options={chartOptions} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
