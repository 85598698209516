/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Components/Header";
import "chart.js/auto";
import "../CSS/Dashboard.css";
import "../CSS/online.css";
import "../App.css";
import { LuCalendarDays } from "react-icons/lu";
import { Toaster, toast } from "sonner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AdvancedSearch() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  console.log("Loaded selectedOrg from localStorage:", selectedOrg);
  const { orgId } = useParams();
  const [articles, setArticles] = useState([]);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  // const [pieData, setPieData] = useState({});
  const [articleSentiment, setArticleSentiment] = useState("");
  const [articleVisibility, setArticleVisibility] = useState("");
  const [focusOfCoverage, setFocusOfCoverage] = useState("");
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [reachSortOrder, setReachSortOrder] = useState("");
  const [coverageType, setCoverageType] = useState("");
  const [monthlyArticles, setMonthlyArticles] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [editingSource, setEditingSource] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState(20);
  const popupRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    const fetchOrgData = async () => {
      try {
        const endpoint =
          user.role === "super_admin"
            ? `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
            : `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`;

        const response = await axios.get(endpoint);
        if (isMounted) setOrganizationData(response.data);
      } catch (error) {
        if (error.response && [401, 403].includes(error.response.status)) {
          navigate("/login");
        } else {
          setError("Failed to load organization data.");
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (selectedOrg || user.organizationId) {
      fetchOrgData();
    }

    return () => {
      isMounted = false;
    }; // cleanup function to set isMounted false
  }, [user.role, selectedOrg, user.organizationId, navigate]);

  // Close popup if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup
      }
    };

    if (popupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupVisible]);

  // Fetch articles for the organization and set displayedArticles
  const fetchArticles = async () => {
    setLoading(true); // Start loading spinner
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`
      );
      const articlesData = response.data.articles || [];

      // Sort articles by publication date (latest first)
      const sortedArticles = articlesData.sort(
        (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
      );

      setArticles(sortedArticles); // Store all articles
      setDisplayedArticles(sortedArticles.slice(0, 8)); // Display the first 8 articles
      setTotalArticles(sortedArticles.length);

      // Calculate articles for the current month
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const monthlyCount = sortedArticles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return (
          articleDate.getMonth() === currentMonth &&
          articleDate.getFullYear() === currentYear
        );
      }).length;

      setMonthlyArticles(monthlyCount); // Update state for articles in the current month
    } catch (error) {
      console.error("Error fetching articles:", error);
      toast.error("Failed to load articles");
      setError("Failed to load articles");
    } finally {
      setLoading(false); // Stop loading spinner after fetch
    }
  };

  // Use fetchArticles inside useEffect
  useEffect(() => {
    if (selectedOrg) fetchArticles();
  }, []); // Re-run when selectedOrg changes

  // Reset visibleArticles whenever filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  //for calendar/date filter
  useEffect(() => {
    if (startDate && endDate) {
      const filtered = articles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles); // Reset to show all articles
    }
  }, [startDate, endDate, articles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...articles];

      // Filter by sentiment
      if (articleSentiment) {
        filtered = filtered.filter((article) => {
          if (articleSentiment === "positive") return article.sentiment > 0.05;
          if (articleSentiment === "neutral")
            return article.sentiment >= -0.05 && article.sentiment <= 0.05;
          if (articleSentiment === "negative") return article.sentiment < -0.05;
          if (articleSentiment === "mixed")
            return article.sentiment > -0.5 && article.sentiment < 0.5;
          return true;
        });
      }

      // Filter by visibility if needed (not implemented in the backend code example, but here's a placeholder)
      if (articleVisibility) {
        filtered = filtered.filter(
          (article) => article.visibility === articleVisibility
        );
      }

      // Filter by focus of coverage
      if (focusOfCoverage) {
        filtered = filtered.filter(
          (article) => article.coverageFocus === focusOfCoverage
        );
      }

      // Filter by coverage type
      if (coverageType) {
        filtered = filtered.filter(
          (article) => article.coverageType === coverageType
        );
      }

      // Sort by AVE
      if (aveSortOrder) {
        filtered.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
      }

      // Sort by reach
      if (reachSortOrder) {
        filtered.sort((a, b) =>
          reachSortOrder === "ascending" ? a.reach - b.reach : b.reach - a.reach
        );
      }

      //filter by date
      if (startDate && endDate) {
        filtered = filtered.filter((article) => {
          const articleDate = new Date(article.publication_date);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      setFilteredArticles(filtered);
    };

    applyFilters();
  }, [
    articles,
    articleSentiment,
    articleVisibility,
    focusOfCoverage,
    aveSortOrder,
    reachSortOrder,
    coverageType,
    startDate,
    endDate,
  ]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <div>Error: {error}</div>;
  // if (!organizationData) return <div>No organization data available</div>;

  const handleMenuClick = (index) => {
    document.getElementById(`menu-${index}`).style.display = "block";
  };

  const handleMenuClose = (index) => {
    document.getElementById(`menu-${index}`).style.display = "none";
  };

  // edit source name on articles table
  const handleSourceEdit = (articleId, currentSource) => {
    setEditingSource({ articleId, value: currentSource });
  };

  const handleSourceChange = (e) => {
    setEditingSource((prev) => ({ ...prev, value: e.target.value }));
  };

  const confirmSourceUpdate = async (articleId, newSource) => {
    if (
      window.confirm(
        `Are you sure you want to change the source to "${newSource}"?`
      )
    ) {
      try {
        const response = await axios.put(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}`,
          { newSource },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Update the articles in local state
          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in articles
                : article
            )
          );

          // Update the displayedArticles state
          setDisplayedArticles((prevDisplayedArticles) =>
            prevDisplayedArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in displayedArticles
                : article
            )
          );

          toast.success("Source updated successfully");
          setEditingSource(null); // Close the input box
        }
      } catch (error) {
        console.error("Error updating source:", error);
        toast.error("Failed to update source");
      }
    }
  };

  const handleKeyPress = (e, articleId, newSource) => {
    if (e.key === "Enter") {
      confirmSourceUpdate(articleId, newSource);
    }
  };

  const handleBlur = () => {
    setEditingSource(null);
  };

  // Map numerical sentiment score from MongoDB to label
  const mapSentimentToLabel = (score) => {
    if (score === 0) return "neutral"; // Neutral takes precedence for exact 0
    if (score > -0.5 && score < 0.5) return "mixed"; // Mixed is checked before positive
    if (score > 0.05) return "positive";
    if (score < -0.05) return "negative";
    return "neutral"; // Default fallback
  };

  // Map sentiment label to numerical score for MongoDB
  const mapLabelToSentiment = (label) => {
    if (label === "positive") return 1; // High positive sentiment
    if (label === "negative") return -1; // High negative sentiment
    if (label === "mixed") return 0.25; // Assign a representative score for mixed sentiment
    return 0; // Neutral sentiment
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredArticles(
      articles.filter(
        (article) =>
          article.title.toLowerCase().includes(lowerCaseQuery) ||
          article.source.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <div className="content">
        <h2>Advanced Search</h2>
        <div className="holder">
          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="filters">
              {/* Date Range Filter */}
              <div
                className="date-container2"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date" style={{ fontWeight: "400" }}>
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredArticles(articles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>

              {/* Other Filters */}
              <select
                value={articleSentiment}
                onChange={(e) => setArticleSentiment(e.target.value)}
              >
                <option value="">Article Sentiment ↡</option>
                <option value="positive">Positive</option>
                <option value="neutral">Neutral</option>
                <option value="negative">Negative</option>
                <option value="mixed">Mixed</option>
              </select>
              {/* <select
                value={articleVisibility}
                onChange={(e) => setArticleVisibility(e.target.value)}
              >
                <option value="">Article Visibility ↡</option>
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select> */}
              {/* <select
                value={focusOfCoverage}
                onChange={(e) => setFocusOfCoverage(e.target.value)}
              >
                <option value="">Select Coverage ↡</option>
                <option value="dedicated">Dedicated</option>
                <option value="mentioned">Mentioned</option>
                <option value="shared">Shared</option>
              </select> */}
              <select
                value={aveSortOrder}
                onChange={(e) => setAveSortOrder(e.target.value)}
              >
                <option value="">AVE Sort Order ↡</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
              <select
                value={reachSortOrder}
                onChange={(e) => setReachSortOrder(e.target.value)}
              >
                <option value="">Reach Sort Order ↡</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
              {/* <select
                value={coverageType}
                onChange={(e) => setCoverageType(e.target.value)}
              >
                <option value="">Coverage Type ↡</option>
                <option value="notset">Not Set</option>
                <option value="earned">Earned</option>
                <option value="owned">Owned</option>
                <option value="incidental">Incidental</option>
                <option value="advocated">Advocated</option>
                <option value="paid">Paid</option>
              </select> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedSearch;
