import React, { useState, useEffect } from "react";
import { FaCamera } from "react-icons/fa";
import Modal from "./Modal";
import Header from "../Components/Header";
import EditOrganization from "./EditOrg";
import { CircleLoader } from "react-spinners";
import { toast, ToastContainer } from 'react-toastify';
import "../App.css";
import '../CSS/organization.css';

const Organization = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [formData, setFormData] = useState({
        organizationName: '',
        logo: null,
        logoPreview: null,
        industry: '',
        address: '',
        ContactEmail: '',
        phoneNumber: '',
        website: '',
        facebookUrl: '',
        linkedinUrl: '',
        xHandle: '',
        monitoringType: [],
        email: '',
        keywords: '',  // New field for keywords (comma-separated)
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);  // Loading state for the loader

    useEffect(() => {
        // Fetch existing organization data if user is org_admin
        if (user.role === 'org_admin') {
            const fetchOrgData = async () => {
                try {
                    const response = await fetch(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`);
                    const data = await response.json();
                    setFormData(data);
                } catch (error) {
                    console.error('Error fetching organization data:', error);
                }
            };
            fetchOrgData();
        }
    }, [user.organizationId, user.role]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle keywords separately to limit them to 5
        if (name === "keywords") {
            const keywordArray = value.split(',').map(keyword => keyword.trim());
            if (keywordArray.length <= 5) {
                setFormData({ ...formData, keywords: value });
            } else {
                alert("You can only enter up to 5 keywords");
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                logo: file,
                logoPreview: URL.createObjectURL(file)
            });
            // toast.info("Logo uploaded successfully!"); // Notify user that logo upload is done
        }
    };


    const triggerFileInput = () => {
        document.getElementById('logoInput').click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Prepare data for submission
        const keywordsArray = formData.keywords.split(',').map(keyword => keyword.trim());
        const organizationData = new FormData();
        for (let key in formData) {
            if (key === 'keywords') {
                organizationData.append(key, JSON.stringify(keywordsArray));
            } else {
                organizationData.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations', {
                method: 'POST',
                body: organizationData,
            });
            const textResponse = await response.text();
            const contentType = response.headers.get('Content-Type');
            let data;
            if (contentType && contentType.includes('application/json')) {
                data = JSON.parse(textResponse);
            } else {
                data = { error: textResponse };
            }

            if (response.ok) {
                setModalMessage('Organization created successfully!');
                setModalShow(true);
            } else {
                setModalMessage('There was an error creating the organization.');
                setModalShow(true);
            }
        } catch (error) {
            setModalMessage('There was an error creating the organization!');
            setModalShow(true);
        } finally {
            setLoading(false);
        }
    };


    const userName = `${user.firstName} ${user.lastName}`;
    const userRole = user.role;

    return (
        <div>
            <div className="container">
                <Header userName={userName} userRole={userRole} />{" "}
            </div>
            <div className="content">
                {userRole === 'super_admin' ? (
                    <div className="organization-container">
                        <h2>Create an Organization</h2>
                        <form onSubmit={handleSubmit} className="organization-form">
                            <div className="form-row" style={{ marginTop: "20px" }}>
                                <label>
                                    Organization Name
                                    <input type="text" name="organizationName" value={formData.organizationName} onChange={handleChange} />
                                </label>
                                <div className="logo-upload-container">
                                    <input
                                        type="file"
                                        id="logoInput"
                                        name="logo"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="custom-upload-button" onClick={triggerFileInput}>
                                        {formData.logoPreview ? (
                                            <img src={formData.logoPreview} alt="Logo Preview" className="logo-preview" />
                                        ) : (
                                            <FaCamera className="camera-icon" />
                                        )}
                                    </div>
                                    <p>Add Logo</p>
                                </div>
                            </div>
                            <div className="form-row">
                                <label>
                                    Industry
                                    <input type="text" name="industry" value={formData.industry} onChange={handleChange} placeholder="E.g., Mining, Technology" />
                                </label>
                                <label>
                                    Address
                                    <input type="text" name="address" value={formData.address} onChange={handleChange} />
                                </label>
                            </div>
                            <div className="form-row">
                                <label>
                                    Contact Email
                                    <input type="email" name="ContactEmail" value={formData.ContactEmail} onChange={handleChange} required />
                                </label>
                                <label>
                                    Contact Phone Number
                                    <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                                </label>
                            </div>
                            <div className="form-row">
                                <label>
                                    Website URL
                                    <input type="url" name="website" value={formData.website} onChange={handleChange} />
                                </label>
                                <label>
                                    Facebook Page URL
                                    <input type="url" name="facebookUrl" value={formData.facebookUrl} onChange={handleChange} />
                                </label>
                            </div>
                            <div className="form-row">
                                <label>
                                    LinkedIn Company Page URL
                                    <input type="url" name="linkedinUrl" value={formData.linkedinUrl} onChange={handleChange} />
                                </label>
                                <label>
                                    X Handle
                                    <input type="text" name="xHandle" value={formData.xHandle} onChange={handleChange} placeholder="e.g., exampleorg" />
                                </label>
                            </div>
                            <div className="form-row">
                                <label>
                                    Keywords (up to 5, separated by commas)
                                    <input
                                        type="text"
                                        name="keywords"
                                        value={formData.keywords}
                                        onChange={handleChange}
                                    />
                                </label>
                                <label>
                                    Username (Email)
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                </label>
                            </div>
                            <div>
                                <label className="monitoring-type">
                                    Monitoring Type
                                    <select name="monitoringType" value={formData.monitoringType} onChange={handleChange}>
                                        <option value="">Select Monitoring Type</option>
                                        <option value="Online Media">Online Media</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Broadcast Media">Broadcast Media</option>
                                        <option value="Print Media">Print Media</option>
                                    </select>
                                </label>
                            </div>

                            <button type="submit" className="createorg" disabled={loading}>Create Organization</button>
                        </form>
                    </div>
                ) : (
                    <EditOrganization formData={formData} setFormData={setFormData} />
                )}
                <Modal show={modalShow} onClose={() => setModalShow(false)}>
                    <p>{modalMessage}</p>
                </Modal>

                {/* Loading spinner overlay */}
                {loading && (
                    <div className="loading-overlay">
                        <CircleLoader color="#0056b3" size={100} />
                        <p>Loading...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Organization;
