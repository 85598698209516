import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the X icon from react-icons
import '../App.css'; // Ensure your styles are correctly imported

const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
        <div className="modal" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    <FaTimes />
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
