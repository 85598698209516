import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import "../CSS/organization.css"; // Import any necessary CSS

const EditOrganization = ({ formData, setFormData }) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Initial setup or data fetch if needed
        setIsLoading(false);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            logo: file,
            logoPreview: URL.createObjectURL(file)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const organizationData = new FormData();
        for (let key in formData) {
            organizationData.append(key, formData[key]);
        }

        try {
            const response = await fetch(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${formData.organizationId}`, {
                method: 'PUT',
                body: organizationData,
            });

            const textResponse = await response.text();
            const contentType = response.headers.get('Content-Type');
            let data;
            if (contentType && contentType.includes('application/json')) {
                data = JSON.parse(textResponse);
            } else {
                data = { error: textResponse };
            }

            if (response.ok) {
                setModalMessage('Organization updated successfully!');
                setModalShow(true);
            } else {
                setModalMessage('There was an error updating the organization.');
                setModalShow(true);
            }
        } catch (error) {
            setModalMessage('There was an error updating the organization!');
            setModalShow(true);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="edit-organization-container">
            <h2>Edit Organization</h2>
            <form onSubmit={handleSubmit} className="organization-form">
                <div className="form-row">
                    <label>
                        Organization Name
                        <input type="text" name="organizationName" value={formData.organizationName} onChange={handleChange} />
                    </label>
                    <div className="logo-upload-container">
                        <input
                            type="file"
                            id="logoInput"
                            name="logo"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleFileChange}
                            style={{ display: 'none' }} // Hide the default file input
                        />
                        <div className="custom-upload-button" onClick={() => document.getElementById('logoInput').click()}>
                            {formData.logoPreview ? (
                                <img src={formData.logoPreview} alt="Logo Preview" className="logo-preview" />
                            ) : (
                                <span>Add Logo</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <label>
                        Industry
                        <input type="text" name="industry" value={formData.industry} onChange={handleChange} />
                    </label>
                    <label>
                        Address
                        <input type="text" name="address" value={formData.address} onChange={handleChange} />
                    </label>
                </div>
                <div className="form-row">
                    <label>
                        Contact Email
                        <input type="email" name="ContactEmail" value={formData.ContactEmail} onChange={handleChange} required />
                    </label>
                    <label>
                        Contact Phone Number
                        <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                    </label>
                </div>

                <button type="submit" className="update-org">Update Organization</button>
            </form>
            <Modal show={modalShow} onClose={() => setModalShow(false)}>
                <p>{modalMessage}</p>
            </Modal>
        </div>
    );
};

export default EditOrganization;
