import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../CSS/CustomReports.css";
import { jsPDF } from "jspdf";
import pptxgen from "pptxgenjs";
import html2canvas from "html2canvas";

const CustomReports = ({ organizationName, alias, showModal, setShowModal }) => {
    const logoUrl = process.env.PUBLIC_URL + '/social.jpg';
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        contentTypes: ["posts"],
        granularity: "month",
    });
    const [data, setData] = useState([]);
    const [sentimentData, setSentimentData] = useState([]);
    const [sourceDistributionData, setSourceDistributionData] = useState([]);
    const [topSourcesData, setTopSourcesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [organizationId, setOrganizationId] = useState(null);
    // const [showModal, setShowModal] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [categorizedData, setCategorizedData] = useState([]);
    const [wordCloudData, setWordCloudData] = useState([]); // 🔹 New state for word cloud data
    const navigate = useNavigate();
    const [organizationAlias, setOrganizationAlias] = useState("");


    // Extracting data for charts
    const volumeData = topSourcesData.map((source) => ({
        name: source.source || "Unknown",
        volume: source.count
    }));

    const tonalityData = topSourcesData.map((source) => ({
        name: source.source || "Unknown",
        positive: source.positive,
        neutral: source.neutral,
        negative: source.negative
    }));

    // Define Line Colors for Different Media Types
    const LINE_COLORS = ["#1C2833", "#5D6D7E", "#8A9E83", "#27AE60", "#F4D03F", "#E74C3C", "#2E86C1", "#AF7AC5"];

    // Transform source distribution data for line chart
    const lineChartData = sourceDistributionData.reduce((acc, source) => {
        sourceDistributionData.forEach((entry) => {
            const existing = acc.find((item) => item.date === entry.date);
            if (existing) {
                existing[entry.mediaType] = entry.mentions;
            } else {
                acc.push({
                    date: entry.date,
                    [entry.mediaType]: entry.mentions
                });
            }
        });
        return acc;
    }, []);

    useEffect(() => {
        const selectedOrg = localStorage.getItem("selectedOrg");

        if (selectedOrg && selectedOrg !== organizationId) { // Prevent unnecessary re-fetch
            setOrganizationId(selectedOrg);

            // Fetch organization details (including alias)
            axios.get(`https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`)
                .then((response) => {
                    const orgData = response.data;
                    setOrganizationAlias(orgData.alias || orgData.organizationName); // Fallback to organizationName
                })
                .catch((error) => {
                    console.error("Failed to fetch organization details:", error);
                });
        } else {
            console.error("No organization ID found in localStorage");
        }
    }, [organizationId]); // Runs only if `organizationId` changes


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setFormData((prev) => ({
            ...prev,
            startDate: start,
            endDate: end,
        }));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData((prev) => ({
                ...prev,
                contentTypes: checked
                    ? [...prev.contentTypes, value]
                    : prev.contentTypes.filter((item) => item !== value),
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const COLORS = ["#8A9E83", "#5D6D7E", "#2E4053", "#1C2833"]; // Adjust colors to match

    const totalContentCount = data.reduce((sum, item) => sum + item.count, 0);

    const chartData = data.map((item, index) => ({
        name: item.contentType.charAt(0).toUpperCase() + item.contentType.slice(1),
        value: Math.round((item.count / totalContentCount) * 100),
        count: item.count,
    }));


    const fetchData = async (e) => {
        e.preventDefault();

        if (!organizationId) {
            setError("Invalid organization ID");
            return;
        }

        if (!formData.startDate || !formData.endDate) {
            setError("Please select a date range");
            return;
        }

        setLoading(true);
        setError(null);

        const selectedContentTypes = formData.contentTypes.length > 0 ? formData.contentTypes.join(",") : "";

        try {
            const [
                countResponse, sentimentResponse, sourceResponse,
                topSourcesResponse, categorizedResponse, wordCloudResponse
            ] = await Promise.all([
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/content-volume", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        granularity: formData.granularity,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                    },
                }),
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/sentiment-over-time", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        granularity: formData.granularity,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                    },
                }),
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/source-distribution", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        granularity: formData.granularity,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                    },
                }),
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/top-sources", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        granularity: formData.granularity,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                        limit: 10,
                    },
                }),
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/categorized-content-details", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                    },
                }),
                axios.get("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/reports/word-cloud", {
                    params: {
                        organizationId,
                        contentType: selectedContentTypes,
                        startDate: formData.startDate.toISOString().split("T")[0],
                        endDate: formData.endDate.toISOString().split("T")[0],
                    },
                }),
            ]);

            // Navigate to report results page with fetched data
            navigate(`/report-results/${organizationId}`, {
                state: {
                    data: countResponse.data,
                    sentimentData: sentimentResponse.data,
                    sourceDistributionData: sourceResponse.data,
                    topSourcesData: topSourcesResponse.data,
                    categorizedData: categorizedResponse.data.categorizedData,
                    wordCloudData: wordCloudResponse.data.keywords,
                    formData,
                    organizationName,
                    organizationAlias,
                },
            });

        } catch (error) {
            console.error("Failed to fetch data:", error);
            setError("Failed to fetch data");
        }

        setLoading(false);
    };


    const reputationPillarsChartData = categorizedData
        .filter((pillar) => pillar.count > 0)  // Only keep pillars with content
        .map((pillar) => ({
            name: pillar.pillar,
            positive: pillar.positive || 0,
            neutral: pillar.neutral || 0,
            negative: pillar.negative || 0,
            mixed: pillar.mixed || 0,
        }));

    const reputationRadarData = reputationPillarsChartData.map((pillar) => ({
        name: pillar.pillar,
        score: pillar.positive - pillar.negative, // Net Sentiment Score
        volume: pillar.count
    }));


    // Function to generate filename dynamically
    const generateFilename = (extension) => {
        const orgName = organizationName.replace(/\s+/g, "_"); // Replace spaces with underscores
        const startDate = formData.startDate?.toISOString().split("T")[0];
        const endDate = formData.endDate?.toISOString().split("T")[0];
        return `SL_${orgName}MediaReport_${startDate}-${endDate}.${extension}`;
    };
    const exportToPDF = () => {
        document.body.classList.add("export-mode", "pdf");

        setTimeout(() => {
            const doc = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });
            const reportSections = document.querySelectorAll(".report-section");

            let yOffset = 10;

            const captureSection = (section, callback) => {
                html2canvas(section, { scale: 2 }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    const imgWidth = 190;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    if (yOffset + imgHeight > 280) {
                        doc.addPage();
                        yOffset = 10;
                    }

                    doc.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
                    yOffset += imgHeight + 10;
                    callback();
                });
            };

            const processSections = (index) => {
                if (index < reportSections.length) {
                    captureSection(reportSections[index], () => processSections(index + 1));
                } else {
                    doc.save(generateFilename("pdf"));
                    document.body.classList.remove("export-mode", "pdf"); // Remove export mode after saving
                }
            };

            processSections(0);
        }, 500);
    };


    const exportToPPT = () => {
        document.body.classList.add("export-mode", "ppt");

        setTimeout(() => {
            let ppt = new pptxgen();
            const reportSections = document.querySelectorAll(".report-section");

            const captureSection = (section, callback) => {
                html2canvas(section, { scale: 2 }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    let slide = ppt.addSlide();
                    slide.addImage({ data: imgData, x: 0, y: 0, w: 10, h: 5.625 });
                    callback();
                });
            };

            const processSections = (index) => {
                if (index < reportSections.length) {
                    captureSection(reportSections[index], () => processSections(index + 1));
                } else {
                    ppt.writeFile(generateFilename("pptx"));
                    document.body.classList.remove("export-mode", "ppt"); // Remove export mode after saving
                }
            };

            processSections(0);
        }, 500);
    };

    return (
        <div className="reports-container">
            {/* <button className="fab-button" onClick={() => setShowModal(false)}>+</button> */}

            {/* Modal for Report Creation */}
            {showModal && (
                <div className="modal-overlay" onClick={(e) => {
                    if (e.target.classList.contains("modal-overlay")) {
                        setShowModal(false);
                    }
                }}>
                    <div className="reports-modal-content">
                        <button
                            className="report-close-button"
                            onClick={() => setShowModal(false)}
                        >
                            &times;
                        </button>
                        <h2 className="reports-modal-title">Create Report</h2>
                        <form onSubmit={fetchData}>
                            {/* Date Range Picker */}
                            <div className="reports-date-range">
                                <label className="reports-label">Add Date Range:</label>
                                <DatePicker
                                    selected={formData.startDate}
                                    onChange={handleDateChange}
                                    startDate={formData.startDate}
                                    endDate={formData.endDate}
                                    selectsRange
                                    className="reports-date-picker"
                                    dateFormat="yyyy/MM/dd"
                                    required
                                />
                            </div>

                            {/* Content Types */}
                            <div className="reports-content-types">
                                <label className="reports-label">Choose Media Type:</label>
                                <div className="reports-checkbox-group">
                                    {["posts", "articles", "broadcast", "printmedia"].map((item) => (
                                        <div key={item} className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                name="contentTypes"
                                                value={item}
                                                checked={formData.contentTypes.includes(item)}
                                                onChange={handleChange}
                                                className="reports-checkbox"
                                                id={`checkbox-${item}`}
                                            />
                                            <label htmlFor={`checkbox-${item}`} className="checkbox-label">
                                                {item.charAt(0).toUpperCase() + item.slice(1)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Granularity Selection */}
                            <div className="reports-granularity">
                                <label className="reports-label">Report Type (Duration):</label>
                                <select
                                    name="granularity"
                                    value={formData.granularity}
                                    onChange={handleChange}
                                    className="reports-select"
                                >
                                    <option value="year">Annual</option>
                                    <option value="month">Monthly</option>
                                    <option value="week">Weekly</option>
                                    <option value="day">Daily</option>
                                </select>
                            </div>

                            {/* Action Buttons */}
                            <div className="reports-actions">
                                <button type="submit" className="reports-submit-btn">Fetch Data</button>
                                {/* <button type="button" className="reports-cancel-btn" onClick={() => setShowModal(false)}>Cancel</button> */}
                            </div>
                        </form>
                    </div>

                </div>
            )}
        </div>
    );
};

export default CustomReports;
