import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from 'axios';
import "../CSS/Settings.css";
import { MdEdit, MdDelete } from 'react-icons/md'; // Import icons from react-icons

const OrganizationManagement = () => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOrganizations();
    }, []);

    const fetchOrganizations = async () => {
        try {
            const response = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations');
            setOrganizations(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch organizations');
            console.error(error);
            setLoading(false);
        }
    };

    return (
        <div>
            <h4 style={{ paddingLeft: "12px", paddingTop: "20px" }}>Organization Management</h4>
            <div className="table-container">
                {loading ? (
                    // <div className="spinner-container">
                    <div className="spinner"></div>
                    // {/* </div> */}
                ) : error ? (
                    <div className="error-message">Error: {error}</div>
                ) : organizations.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Website</th>
                                <th>Industry</th>
                                <th>Address</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map((org) => (
                                <tr key={org._id}>
                                    <td>{org.organizationName}</td>
                                    <td>{org.email}</td>
                                    <td>{org.website}</td>
                                    <td>{org.industry}</td>
                                    <td>{org.address}</td>
                                    <td>
                                        <span className={`status-dot ${getStatusClass(org.status)}`}></span>
                                        {org.status || 'N/A'}
                                    </td>
                                    <td className="actions-container">
                                        <div className="action-icon edit-icon">
                                            <Link to={`/edit-organization/edit/${org._id}`}>
                                                <MdEdit size={16} color="gray" />
                                            </Link>
                                        </div>
                                        <div className="action-icon delete-icon">
                                            <MdDelete size={16} color="gray" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No organizations found.</p>
                )}
            </div>
        </div>
    );
};

function getStatusClass(status) {
    if (!status) return ''; // Handle undefined or null status
    switch (status.toLowerCase()) {
        case 'active': return 'active';
        case 'inactive': return 'inactive';
        default: return '';
    }
}

export default OrganizationManagement;
