/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../../Components/Header";
import "chart.js/auto";
import { Line, Pie, Doughnut } from "react-chartjs-2";
import {
  FaEllipsisV,
  FaSortAmountUp,
  FaSortAmountDown,
  FaAngleDown,
} from "react-icons/fa";
import "../../CSS/Dashboard.css";
import "../../App.css";
import { LuThumbsDown, LuThumbsUp, LuCalendarDays } from "react-icons/lu";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { MdOutlineSentimentNeutral } from "react-icons/md";
import { Toaster, toast } from "sonner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "../../Components/ThemeContext";
import CountryModal from "../../Components/CountryModal";

function Broadcast() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  console.log("Loaded selectedOrg from localStorage:", selectedOrg);
  const { orgId } = useParams();
  const [articles, setArticles] = useState([]);
  const [sourceDistribution, setSourceDistribution] = useState({});
  const [pieData, setPieData] = useState({});
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [stationType, setStationType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState(20);
  const { theme } = useContext(ThemeContext);
  const [chartArticles, setChartArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [dateSortOrder, setDateSortOrder] = useState("descending");
  const [stationFilterVisible, setStationFilterVisible] = useState(false);
  const [stationTypes, setStationTypes] = useState([]);
  const [sentimentTypes, setSentimentTypes] = useState([]);
  const [sentimentFilter, setSentimentFilter] = useState("");
  const [sentimentFilterVisible, setSentimentFilterVisible] = useState(false);
  const [timeRange, setTimeRange] = useState("month");
  const [editingCountry, setEditingCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [countries, setCountries] = useState([]);

  //geolocation useeffect
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort(); // Sort alphabetically
        setCountries([
          "Botswana",
          ...countryNames.filter((c) => c !== "Botswana"),
        ]); // Keep Botswana at top
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        let response;
        console.log(
          "Fetching data for role:",
          user.role,
          "with selectedOrg:",
          selectedOrg
        );

        if (user.role === "super_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
          );
        } else if (user.role === "org_admin") {
          response = await axios.get(
            `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`
          );
        }

        setOrganizationData(response.data);
      } catch (error) {
        setError("Failed to load organization data.");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
    fetchOrgData();
  }, [user.role, selectedOrg, navigate, user.organizationId]);

  // Fetch articles for the selected organization
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/broadcastMedia`
        );

        const newArticles = response.data;
        setArticles(newArticles); // Update articles state

        // Extract unique station types BEFORE setting state
        const uniqueStations = [
          ...new Set(
            newArticles.map((article) => article.stationType).filter(Boolean)
          ),
        ];
        setStationTypes(uniqueStations);

        // Extract unique sentiment values
        const uniqueSentiments = [
          ...new Set(
            newArticles
              .map((article) => article.sentiment)
              .filter((value) => value !== null && value !== undefined)
          ),
        ];
        setSentimentTypes(uniqueSentiments);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setLoading(false);
      }
    };

    if (selectedOrg) fetchArticles();
  }, [selectedOrg]);

  // Update source distribution for articles only
  useEffect(() => {
    const distribution = {};
    articles.forEach((article) => {
      distribution[article.station] = (distribution[article.station] || 0) + 1;
    });
    setSourceDistribution(distribution);
  }, [articles]);

  const predefinedColors = [
    "#1E90FF", // Dodger Blue
    "#ff895e", // Orange Red
    "#93ff93", // Lime Green
    "#ffea76", // Gold
    "#c07cff", // Blue Violet
    "#db0e74", // Hot Pink
    "#7afff8", // Light Sea Green
    "#ff5b7c", // Crimson
    "#0163dc", // Teal
    "#ffa83d", // Dark Orange
    "#678dff", // Steel Blue
    "#811a1a", // Brown
    "#1437a1", // Cadet Blue
    "#e8ec16", // Medium Violet Red
    "#b6feb6", // Forest Green
  ];

  const getGradient = (ctx, index) => {
    if (!ctx) return predefinedColors[index % predefinedColors.length]; // Fallback to solid color if no ctx

    const baseColor = predefinedColors[index % predefinedColors.length];
    let gradient = ctx.createLinearGradient(0, 0, 200, 200);

    gradient.addColorStop(0, baseColor);
    gradient.addColorStop(1, adjustColorBrightness(baseColor, -20)); // Darker for contrast

    return gradient;
  };

  // Function to slightly darken a color for gradient effect
  const adjustColorBrightness = (hex, amount) => {
    let usePound = false;
    if (hex[0] === "#") {
      hex = hex.slice(1);
      usePound = true;
    }

    let num = parseInt(hex, 16);
    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00ff) + amount;
    let b = (num & 0x0000ff) + amount;

    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    return (
      (usePound ? "#" : "") +
      ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")
    );
  };

  const gradientPlugin = {
    id: "gradientPlugin",
    beforeDraw: (chart) => {
      const { ctx, data } = chart;
      if (!ctx || !data.datasets) return;

      data.datasets.forEach((dataset, datasetIndex) => {
        dataset.backgroundColor = dataset.data.map((_, index) => {
          return getGradient(
            ctx,
            predefinedColors[(index + datasetIndex) % predefinedColors.length]
          );
        });
      });

      chart.update();
    },
  };

  useEffect(() => {
    const currentMonth = new Date().toLocaleString("default", {
      month: "short",
    });
    const currentYear = new Date().getFullYear();
    const monthlyDistribution = groupArticlesByMonth(articles);

    let distribution = {};

    if (timeRange === "month") {
      distribution = monthlyDistribution[currentMonth] || {};
    } else if (timeRange === "year") {
      Object.values(monthlyDistribution).forEach((monthData) => {
        Object.keys(monthData).forEach((source) => {
          distribution[source] =
            (distribution[source] || 0) + monthData[source];
        });
      });
    }

    const labels = Object.keys(distribution);
    const values = Object.values(distribution);

    // Sort stations by mentions (high to low)
    let sortedData = labels
      .map((label, index) => ({ label, value: values[index] }))
      .sort((a, b) => b.value - a.value);

    const maxPerRing = 8;
    let datasets = [];
    let usedStations = new Set();
    let ringIndex = 0;

    while (sortedData.length > 0) {
      const ringData = sortedData.splice(0, maxPerRing); // Take only 8 stations per ring

      // Ensure that each station only appears once
      const filteredRingData = ringData.filter(
        (item) => !usedStations.has(item.label)
      );

      // Add these stations to the used set to prevent duplication
      filteredRingData.forEach((item) => usedStations.add(item.label));

      let baseColors = filteredRingData.map(
        (_, index) => predefinedColors[index % predefinedColors.length]
      );

      datasets.push({
        label: `Ring ${datasets.length + 1}`,
        data: filteredRingData.map((d) => d.value),
        backgroundColor: baseColors,
        hoverBackgroundColor: baseColors,
        borderColor: "transparent",
        borderWidth: 2,
        cutout: `${35 - ringIndex * 15}%`, // Adjust cutout for each ring
      });

      ringIndex++;
    }

    setPieData({
      labels: [...usedStations], // Ensure only unique stations are shown
      datasets,
    });
  }, [articles, timeRange]);

  const nestedDonutOptions = {
    responsive: true,
    hoverOffset: 8,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 25,
          usePointStyle: true,
          font: { family: "Raleway", size: 13 },
          color: theme === "light" ? "#7a7a7a" : "#ffffffd2",
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.label}: ${tooltipItem.raw} mentions`,
        },
      },
      gradientPlugin,
    },
    elements: {
      arc: {
        hoverBackgroundColor: (context) => {
          return context.dataset.backgroundColor[context.dataIndex];
        },
        hoverBorderColor: "rgba(0,0,0,0.1)",
      },
    },
  };

  // Function to group articles by month and count the sources
  const groupArticlesByMonth = (articles) => {
    const monthlyDistribution = articles.reduce((acc, article) => {
      const month = new Date(article.mentionDT).toLocaleString("default", {
        month: "short",
      });
      if (!acc[month]) {
        acc[month] = {};
      }
      const source = article.station;
      acc[month][source] = (acc[month][source] || 0) + 1;
      return acc;
    }, {});

    return monthlyDistribution;
  };

  // Ensure articles are processed correctly for the line chart
  const groupArticlesByMonthForLineChart = (articles) => {
    const currentYear = new Date().getFullYear();
    const monthCounts = new Array(12).fill(0);

    articles.forEach((article) => {
      if (article.mentionDT) {
        const articleDate = new Date(article.mentionDT);
        if (articleDate.getFullYear() === currentYear) {
          monthCounts[articleDate.getMonth()] += 1;
        }
      }
    });

    return monthCounts;
  };

  const articleCountsByMonth = groupArticlesByMonthForLineChart(articles);

  const lineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Broadcast Articles",
        data: articleCountsByMonth,
        borderColor: "rgb(78, 100, 224)", // Green
        backgroundColor: "rgba(78, 100, 224, 0.2)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 7,
        fill: true,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 500, // Stretch y-axis
        stepSize: 200,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // Forces circle style
          font: { family: "Raleway", size: 13 },
          color: theme === "light" ? "#7a7a7a" : "#fff",
          paddingBottom: 25,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} Articles`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  // Handle delete article
  const handleDelete = async (id) => {
    if (
      !window.confirm("Are you sure you want to delete this broadcast media?")
    )
      return;

    try {
      const response = await axios.delete(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/broadcastMedia/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setArticles((prevArticles) =>
          prevArticles.filter((article) => article._id !== id)
        );
        setFilteredArticles((prevArticles) =>
          prevArticles.filter((article) => article._id !== id)
        );
        toast.success("Article deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting article:", error);
      toast.error(error.response?.data?.error || "Failed to delete article");
    }
  };

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filteredForTable = [...articles]; // Keep all articles for table
      let filteredForCharts = [...articles]; // Filter only for the charts

      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      // Filter articles for charts (only this month's articles)
      filteredForCharts = filteredForCharts.filter((article) => {
        const articleDate = new Date(article.mentionDT);
        return (
          articleDate.getMonth() === currentMonth &&
          articleDate.getFullYear() === currentYear
        );
      });

      // Apply Sentiment Filter (fix falsy 0 issue)
      if (
        sentimentFilter !== "" &&
        sentimentFilter !== null &&
        sentimentFilter !== undefined
      ) {
        filteredForTable = filteredForTable.filter(
          (article) => article.sentiment === sentimentFilter
        );
      }

      if (stationType) {
        filteredForTable = filteredForTable.filter(
          (article) => article.stationType === stationType
        );
      }

      if (aveSortOrder) {
        filteredForTable.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
      }

      if (startDate && endDate) {
        filteredForCharts = filteredForCharts.filter((article) => {
          const articleDate = new Date(article.mentionDT);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      setFilteredArticles(filteredForTable); // Set articles for the table
      setChartArticles(filteredForCharts); // Set articles for the charts
    };

    applyFilters();
  }, [
    articles,
    stationType,
    aveSortOrder,
    startDate,
    endDate,
    sentimentFilter,
  ]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  //for calendar/date filter
  useEffect(() => {
    if (startDate && endDate) {
      console.log("Start Date:", startDate);
      console.log("End Date:", endDate);

      const filtered = articles.filter((article) => {
        if (!article.mentionDT) return false; // Skip articles without a date
        const articleDate = new Date(article.mentionDT);
        return (
          articleDate >=
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            ) &&
          articleDate <=
            new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate()
            )
        );
      });

      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles); // Reset to show all articles
    }
  }, [startDate, endDate, articles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  // Increase visible articles
  const showMoreArticles = () => {
    setVisibleArticles((prev) => Math.min(prev + 20, filteredArticles.length)); // Ensure it doesn't exceed total articles
  };

  // Decrease visible articles
  const showLessArticles = () => {
    setVisibleArticles((prev) => Math.max(prev - 20, 20)); // Ensure it doesn't go below 20
  };

  // Show all articles
  const showAllArticles = () => {
    setVisibleArticles(filteredArticles.length); // Show all articles
  };

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <div>{error}</div>;

  const handleMenuClick = (index) => {
    document.getElementById(`menu-${index}`).style.display = "block";
  };

  const handleMenuClose = (index) => {
    document.getElementById(`menu-${index}`).style.display = "none";
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredArticles(
      articles.filter(
        (article) =>
          article.mention.toLowerCase().includes(lowerCaseQuery) ||
          article.station.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  // Function to get sorting icon dynamically
  const getSortIcon = (sortOrder) => {
    return sortOrder === "ascending" ? (
      <FaSortAmountDown />
    ) : sortOrder === "descending" ? (
      <FaSortAmountUp />
    ) : (
      <FaSortAmountUp style={{ opacity: 0.5 }} />
    ); // Default: Faded icon
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === "date") {
      setDateSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setAveSortOrder("");
    } else if (field === "ave") {
      setAveSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setDateSortOrder("");
    }
  };

  const sortedArticles = [...filteredArticles].sort((a, b) => {
    if (dateSortOrder) {
      return dateSortOrder === "ascending"
        ? new Date(a.mentionDT) - new Date(b.mentionDT)
        : new Date(b.mentionDT) - new Date(a.mentionDT);
    }
    if (aveSortOrder) {
      return aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave;
    }
    return 0; // Keep default order if no sort is selected
  });

  const confirmCountryUpdate = async (broadcastId) => {
    if (!selectedCountry || !editingCountry) return;

    try {
      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/broadcasts/${broadcastId}/country`,
        { country: selectedCountry },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedStation = response.data.name;

        // Update all broadcasts with the same station name
        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article.station === updatedStation
              ? { ...article, country: selectedCountry }
              : article
          )
        );

        toast.success("Country updated successfully!");
        setIsCountryModalOpen(false);
      } else {
        throw new Error("Failed to update country in MongoDB");
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("Failed to update country.");
    } finally {
      setEditingCountry(null);
    }
  };

  const handleCountryEdit = (broadcastId, currentCountry) => {
    setEditingCountry(broadcastId);
    setSelectedCountry(currentCountry || "Botswana");
    setCurrentArticleId(broadcastId);
    setIsCountryModalOpen(true);
  };

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <CountryModal
        isOpen={isCountryModalOpen}
        onClose={() => setIsCountryModalOpen(false)}
      >
        <h2 style={{ fontFamily: "Raleway" }}>Edit Country</h2>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <button
          onClick={() => confirmCountryUpdate(currentArticleId)}
          className="modalSave"
        >
          Save Changes
        </button>
      </CountryModal>
      <div className="content">
        <h2>
          {organizationData.organization.organizationName}'s Broadcast Media
        </h2>
        <div className="holder">
          {/* Chart Section */}
          <div className="charts">
            <div className="piechart">
              <h3>
                {organizationData.organization.organizationName}'s Mentions (
                {timeRange === "month" ? "This Month" : "This Year"})
              </h3>
              {/* Time Range Selector */}
              <div className="chart-controls">
                <label>Select Time Range:</label>
                <select
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                >
                  <option value="month">This Month</option>
                  <option value="year">This Year</option>
                </select>
              </div>

              {/* Render Nested Donut Chart */}
              <Doughnut data={pieData} options={nestedDonutOptions} />
            </div>

            <div className="chart-container">
              <h3>Number of Articles over the Year</h3>
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>

          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="table-controls">
              {/* Date Range Filter */}
              <div
                className="date-container"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date">
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the calendar toggle
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredArticles(articles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>
            </div>
            <br />

            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th> Source</th>
                      <th>URL</th>
                      <th>Summary</th>
                      <th
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() =>
                          setStationFilterVisible(!stationFilterVisible)
                        }
                      >
                        Media
                        <FaAngleDown
                          style={{
                            marginLeft: "5px",
                            opacity: stationType ? 1 : 0.5,
                          }}
                        />
                        {stationFilterVisible && (
                          <div className="section-filter-dropdown">
                            <ul>
                              <li
                                onClick={() => {
                                  setStationType("");
                                  setStationFilterVisible(false);
                                }}
                                style={{ color: "gray" }}
                              >
                                All Media
                              </li>
                              {stationTypes.map((sec, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setStationType(sec);
                                    setStationFilterVisible(false);
                                  }}
                                >
                                  {sec}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </th>
                      <th>Country</th>
                      <th
                        onClick={() => handleSort("date")}
                        style={{ cursor: "pointer" }}
                      >
                        Date Aired {getSortIcon(dateSortOrder)}
                      </th>
                      <th
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() =>
                          setSentimentFilterVisible(!sentimentFilterVisible)
                        }
                      >
                        Sentiment
                        <FaAngleDown
                          style={{
                            marginLeft: "5px",
                            opacity: sentimentFilter ? 1 : 0.5,
                          }}
                        />
                        {sentimentFilterVisible && (
                          <div className="section-filter-dropdown">
                            <ul>
                              <li
                                onClick={() => {
                                  setSentimentFilter(""); // Reset filter
                                  setSentimentFilterVisible(false);
                                }}
                                style={{ color: "gray" }}
                              >
                                All Sentiments
                              </li>
                              {sentimentTypes.map((sentiment, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setSentimentFilter(sentiment); // Use the actual sentiment value
                                    setSentimentFilterVisible(false);
                                  }}
                                >
                                  {sentiment === 1
                                    ? "Positive"
                                    : sentiment === 0
                                    ? "Neutral"
                                    : sentiment === -1
                                    ? "Negative"
                                    : sentiment === 0.25
                                    ? "Mixed"
                                    : "Unknown"}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </th>
                      <th
                        onClick={() => handleSort("ave")}
                        style={{ cursor: "pointer" }}
                      >
                        AVE {getSortIcon(aveSortOrder)}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArticles.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No broadcast articles found yet for this organization.
                        </td>
                      </tr>
                    ) : (
                      sortedArticles
                        // .slice(0, 80)
                        .slice(0, visibleArticles)
                        .map((article, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "8px",
                                }}
                              >
                                {article.logo_url && (
                                  <img
                                    className="logo-img"
                                    src={article.logo_url}
                                    alt={`${article.station} logo`}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "1px solid",
                                      padding: "1px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {article.station}
                                </span>
                              </div>
                            </td>
                            <td style={{ width: "80px" }}>
                              <div>
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.mention
                                    ? (() => {
                                        const cleanedText =
                                          article.mention.replace(
                                            /^Summary:\s*/,
                                            ""
                                          ); // Remove "Summary:"
                                        const words = cleanedText.split(" "); // Split into words
                                        const shortenedText = words
                                          .slice(3, 8)
                                          .join(" "); // Remove first 2 words, keep next 5
                                        return (
                                          shortenedText ||
                                          "No content available"
                                        ); // Ensure fallback text
                                      })()
                                    : "No content available"}
                                  .. ⤴{/* ↗ */}
                                </a>
                              </div>
                            </td>
                            <td style={{ width: "120px" }} className="snippet">
                              "
                              {article.mention
                                ? `${article.mention
                                    .replace(/^Summary:\s*/, "")
                                    .split(" ")
                                    .slice(0, 15)
                                    .join(" ")}...`
                                : "No content available"}
                              "
                            </td>
                            <td>
                              <div className="station-type">
                                {article.stationType}
                              </div>
                            </td>
                            <td
                              className="source_country"
                              onClick={() => {
                                if (user.role === "super_admin") {
                                  handleCountryEdit(
                                    article._id,
                                    article.country
                                  );
                                }
                              }}
                              title={
                                user.role === "super_admin"
                                  ? "Click to change country"
                                  : ""
                              }
                              style={{
                                cursor:
                                  user.role === "super_admin"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              {article.country || "Unknown"}
                            </td>

                            <td style={{ fontWeight: "500" }}>
                              {new Date(article.mentionDT).toLocaleDateString()}
                            </td>
                            <td>
                              {/* Display sentiment icon with text on the right side */}
                              <div className="sentiment-container">
                                {article.sentiment > 0 ? (
                                  <div className="sentiment positive">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsUp className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Positive
                                    </span>
                                  </div>
                                ) : article.sentiment < 0 ? (
                                  <div className="sentiment negative">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsDown className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Negative
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sentiment neutral">
                                    <div className="sentiment-icon-container">
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Neutral
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {article.ave
                                ? `${article.ave.toFixed(2)} BWP`
                                : "-"}
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() => handleMenuClick(index)}
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() => handleMenuClose(index)}
                                >
                                  <a href={article.url} download>
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                  >
                                    Send Article
                                  </a>
                                  {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent the default anchor behavior
                                      handleDelete(article._id); // Trigger the delete function
                                    }}
                                    className="delete-link"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing {Math.min(visibleArticles, filteredArticles.length)}{" "}
                    of {filteredArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visibleArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Broadcast;
