/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../../Components/Header";
import "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";
import {
  FaSortAmountUp,
  FaSortAmountDown,
  FaEllipsisV,
  FaFilter,
} from "react-icons/fa";
import { IoInformationCircle } from "react-icons/io5";
import "../../CSS/Dashboard.css";
import "../../CSS/online.css";
import "../../App.css";
import { LuThumbsDown, LuThumbsUp, LuCalendarDays } from "react-icons/lu";
import { ImConfused } from "react-icons/im";
import { MdOutlineSentimentNeutral } from "react-icons/md";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Toaster, toast } from "sonner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { ThemeContext } from "../../Components/ThemeContext";
import CountryModal from "../../Components/CountryModal"; // Correct import path
import "../../CSS/CountryModal.css";

function Online() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  console.log("Loaded selectedOrg from localStorage:", selectedOrg);
  const { orgId } = useParams();
  const [articles, setArticles] = useState([]);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  // const [pieData, setPieData] = useState({});
  const [articleSentiment, setArticleSentiment] = useState("");
  const [articleVisibility, setArticleVisibility] = useState("");
  const [focusOfCoverage, setFocusOfCoverage] = useState("");
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [rankSortOrder, setRankSortOrder] = useState("");
  const [reachSortOrder, setReachSortOrder] = useState("");
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [coverageType, setCoverageType] = useState("");
  const [monthlyArticles, setMonthlyArticles] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [editingSource, setEditingSource] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [editingSentiment, setEditingSentiment] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState(20);
  const popupRef = useRef(null);
  const [infoTooltip, setInfoTooltip] = useState(null);
  const [keywordDistribution, setKeywordDistribution] = useState({});
  const tooltipRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle submission
  const [newArticle, setNewArticle] = useState({
    source: "",
    title: "",
    snippet: "",
    publication_date: "",
    sentiment: "neutral",
    ave: 0,
    rank: 0,
    reach: 0,
  });
  const [sentimentFilter, setSentimentFilter] = useState("");
  const [sentimentFilterVisible, setSentimentFilterVisible] = useState(false);
  const [sentimentTypes, setSentimentTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [editingCountry, setEditingCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    const fetchOrgData = async () => {
      try {
        const endpoint =
          user.role === "super_admin"
            ? `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
            : `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`;

        const response = await axios.get(endpoint);
        if (isMounted) setOrganizationData(response.data);
      } catch (error) {
        if (error.response && [401, 403].includes(error.response.status)) {
          navigate("/login");
        } else {
          setError("Failed to load organization data.");
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (selectedOrg || user.organizationId) {
      fetchOrgData();
    }

    return () => {
      isMounted = false;
    }; // cleanup function to set isMounted false
  }, [user.role, selectedOrg, user.organizationId, navigate]);

  // Close popup if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup
      }
    };

    if (popupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupVisible]);

  const toggleTooltip = (label) => {
    setInfoTooltip((prev) => (prev === label ? null : label)); // Toggle tooltip
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setInfoTooltip(null); // Close the tooltip
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  //geolocation useeffect
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort(); // Sort alphabetically
        setCountries([
          "Botswana",
          ...countryNames.filter((c) => c !== "Botswana"),
        ]); // Keep Botswana at top
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Fetch articles for the organization and set displayedArticles
  const fetchArticles = async () => {
    setLoading(true); // Start loading spinner
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`
      );
      const articlesData = response.data.articles || [];

      // Sort articles by publication date (latest first)
      const sortedArticles = articlesData.sort(
        (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
      );

      setArticles(sortedArticles); // Store all articles
      setDisplayedArticles(sortedArticles.slice(0, 8)); // Display the first 8 articles
      setTotalArticles(sortedArticles.length);

      // 🎯 Extract unique sentiment values from the actual article data
      const uniqueSentiments = [
        ...new Set(
          articlesData
            .map((article) => article.sentiment)
            .filter((value) => value !== null && value !== undefined)
        ),
      ];
      setSentimentTypes(uniqueSentiments.sort((a, b) => b - a)); // Sort sentiments from Positive → Negative

      // Calculate articles for the current month
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const monthlyCount = sortedArticles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return (
          articleDate.getMonth() === currentMonth &&
          articleDate.getFullYear() === currentYear
        );
      }).length;

      setMonthlyArticles(monthlyCount); // Update state for articles in the current month
    } catch (error) {
      console.error("Error fetching articles:", error);
      toast.error("Failed to load articles");
      setError("Failed to load articles");
    } finally {
      setLoading(false); // Stop loading spinner after fetch
    }
  };

  // Use fetchArticles inside useEffect
  useEffect(() => {
    if (selectedOrg) fetchArticles();
  }, []); // Re-run when selectedOrg changes

  // Reset visibleArticles whenever filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  // Generate colors for chart sections
  const getGradient = (ctx, index) => {
    const gradient = ctx.createRadialGradient(100, 100, 50, 100, 100, 200);
    const hue = (index * 47) % 360;

    gradient.addColorStop(0, `hsl(${hue}, 90%, 40%)`); // Darker center
    gradient.addColorStop(1, `hsl(${hue}, 90%, 75%)`); // Lighter edge

    return gradient;
  };

  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 2,
      },
    ],
  });

  // Process keyword distribution
  useEffect(() => {
    if (articles.length > 0) {
      // Determine the selected month and year from the filter
      const selectedMonth = startDate
        ? new Date(startDate).getMonth()
        : new Date().getMonth();
      const selectedYear = startDate
        ? new Date(startDate).getFullYear()
        : new Date().getFullYear();

      const keywordMap = {};

      articles.forEach((article) => {
        const articleDate = new Date(article.publication_date);
        if (
          articleDate.getMonth() === selectedMonth &&
          articleDate.getFullYear() === selectedYear
        ) {
          article.matched_keywords.forEach((keyword) => {
            if (!keywordMap[keyword]) {
              keywordMap[keyword] = { count: 0, sources: new Set() };
            }
            keywordMap[keyword].count += 1;
            keywordMap[keyword].sources.add(article.source);
          });
        }
      });

      // Convert sources Set to an array
      Object.keys(keywordMap).forEach((key) => {
        keywordMap[key].sources = Array.from(keywordMap[key].sources);
      });

      setKeywordDistribution(keywordMap);
    }
  }, [articles, startDate]); // Update when articles or selected date changes

  // Pie chart data preparation
  useEffect(() => {
    if (Object.keys(keywordDistribution).length > 0) {
      const labels = Object.keys(keywordDistribution);
      const data = labels.map((key) => keywordDistribution[key].count);

      setPieData({
        labels,
        datasets: [
          {
            data,
            backgroundColor: (context) => {
              const chart = context.chart;
              const ctx = chart.ctx;
              return labels.map((_, index) => getGradient(ctx, index));
            },
            borderColor: "transparent",
            borderWidth: 2,
            borderRadius: 5,
            spacing: 5, // Creates spacing between slices
          },
        ],
      });
    } else {
      // Default placeholder when no data is available
      setPieData({
        labels: ["No Data Available For This Month"],
        datasets: [
          {
            data: [1], // Dummy data to prevent chart errors
            backgroundColor: ["#d3d3d3"], // Neutral gray
            borderColor: "transparent",
            borderWidth: 2,
          },
        ],
      });
    }
  }, [keywordDistribution]);

  // Pie chart options
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: theme === "light" ? "#7a7a7a" : "#fff",
          font: {
            family: "Raleway",
            size: 13,
          },
          padding: 15,
        },
      },
      tooltip: {
        enabled: pieData.labels[0] !== "No Data Available For This Month", // Disable tooltip if no data
        callbacks: {
          label: (tooltipItem) => {
            if (pieData.labels[0] === "No Data Available For This Month")
              return null; // Prevent tooltip display
            const keyword = tooltipItem.label;
            const count = keywordDistribution[keyword]?.count;
            const sources = keywordDistribution[keyword]?.sources.join(", ");
            return `${keyword}: ${count} (Sources: ${sources})`;
          },
        },
      },
      datalabels: {
        display: pieData.labels[0] !== "No Data Available For This Month", // Hide labels for placeholder
        color: theme === "light" ? "#fff" : "#fff",
        font: { weight: "bold", size: 12, family: "Raleway" },
        formatter: (value) => `${value}`,
        anchor: "end",
        align: "start",
        offset: 10,
      },
    },
    cutout: "50%", // Donut shape
  };

  // Ensure articles are processed correctly for the line chart
  const groupArticlesByMonthForLineChart = (articles) => {
    const currentYear = new Date().getFullYear();
    const monthCounts = new Array(12).fill(0);

    articles.forEach((article) => {
      if (article.publication_date) {
        const articleDate = new Date(article.publication_date);
        if (articleDate.getFullYear() === currentYear) {
          monthCounts[articleDate.getMonth()] += 1;
        }
      }
    });

    return monthCounts;
  };

  const articleCountsByMonth = groupArticlesByMonthForLineChart(articles);

  const lineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Online Articles",
        data: articleCountsByMonth,
        borderColor: theme === "light" ? "#1061e2" : "#56a8ff",
        backgroundColor:
          theme === "light"
            ? "rgba(75, 128, 192, 0.2)"
            : "rgba(75, 128, 192, 0.734)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 7,
        fill: true,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 500, // Stretch y-axis
        stepSize: 200,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // Forces circle style
          font: { family: "Raleway", size: 13 },
          color: theme === "light" ? "#7a7a7a" : "#fff",
          paddingBottom: 25,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} Articles`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  //for calendar/date filter
  useEffect(() => {
    if (startDate && endDate) {
      const filtered = articles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles); // Reset to show all articles
    }
  }, [startDate, endDate, articles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  // Handle delete article
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const response = await axios.delete(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${localStorage.getItem(
            "selectedOrg"
          )}/articles/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Update articles state
          setArticles((prevArticles) =>
            prevArticles.filter((article) => article._id !== id)
          );

          // Update displayedArticles state to reflect changes in the UI instantly
          setDisplayedArticles((prevDisplayedArticles) =>
            prevDisplayedArticles.filter((article) => article._id !== id)
          );

          toast.success("Article deleted successfully");
        }
      } catch (error) {
        console.error("Error deleting article:", error);
        toast.error("Failed to delete article");
      }
    }
  };

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...articles];

      // Apply Sentiment Filter
      if (sentimentFilter) {
        filtered = filtered.filter((article) => {
          if (sentimentFilter === "positive") return article.sentiment === 1;
          if (sentimentFilter === "neutral") return article.sentiment === 0;
          if (sentimentFilter === "negative") return article.sentiment === -1;
          if (sentimentFilter === "mixed") return article.sentiment === 0.25;
          return true;
        });
      }

      if (focusOfCoverage) {
        filtered = filtered.filter(
          (article) => article.coverageFocus === focusOfCoverage
        );
      }

      if (coverageType) {
        filtered = filtered.filter(
          (article) => article.coverageType === coverageType
        );
      }

      // Sort by AVE
      if (aveSortOrder) {
        filtered.sort((a, b) =>
          aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave
        );
        console.log("Sorted by AVE:", aveSortOrder); // Debugging sort
      }

      // Sort by reach
      if (reachSortOrder) {
        filtered.sort((a, b) =>
          reachSortOrder === "ascending" ? a.reach - b.reach : b.reach - a.reach
        );
        console.log("Sorted by Reach:", reachSortOrder); // Debugging sort
      }

      // Date filter
      if (startDate && endDate) {
        filtered = filtered.filter((article) => {
          const articleDate = new Date(article.publication_date);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      setFilteredArticles(filtered);
    };

    applyFilters();
  }, [
    articles,
    articleSentiment,
    articleVisibility,
    focusOfCoverage,
    coverageType,
    aveSortOrder,
    reachSortOrder,
    startDate,
    endDate,
    sentimentFilter,
  ]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <div>Error: {error}</div>;
  // if (!organizationData) return <div>No organization data available</div>;

  const handleMenuClick = (index) => {
    document.getElementById(`menu-${index}`).style.display = "block";
  };

  const handleMenuClose = (index) => {
    document.getElementById(`menu-${index}`).style.display = "none";
  };

  // edit source name on articles table
  const handleSourceEdit = (articleId, currentSource) => {
    setEditingSource({ articleId, value: currentSource });
  };

  const handleSourceChange = (e) => {
    setEditingSource((prev) => ({ ...prev, value: e.target.value }));
  };

  const confirmSourceUpdate = async (articleId, newSource) => {
    if (
      window.confirm(
        `Are you sure you want to change the source to "${newSource}"?`
      )
    ) {
      try {
        const response = await axios.put(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}`,
          { newSource },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Update the articles in local state
          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in articles
                : article
            )
          );

          // Update the displayedArticles state
          setDisplayedArticles((prevDisplayedArticles) =>
            prevDisplayedArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in displayedArticles
                : article
            )
          );

          toast.success("Source updated successfully");
          setEditingSource(null); // Close the input box
        }
      } catch (error) {
        console.error("Error updating source:", error);
        toast.error("Failed to update source");
      }
    }
  };

  const handleKeyPress = (e, articleId, newSource) => {
    if (e.key === "Enter") {
      confirmSourceUpdate(articleId, newSource);
    }
  };

  const handleBlur = () => {
    setEditingSource(null);
  };

  // Map numerical sentiment score from MongoDB to label
  const mapSentimentToLabel = (score) => {
    if (score >= 0.75) return "positive"; // Allow for slight variations
    if (score <= -0.5) return "negative";
    if (score > 0 && score < 0.5) return "mixed"; // Catch small positive values
    if (score === 0) return "neutral";

    console.warn(`Unknown sentiment score received: ${score}`);
    return "neutral"; // Fallback to neutral
  };

  // Map sentiment label to numerical score for MongoDB
  const mapLabelToSentiment = (label) => {
    const lowerLabel = label.toLowerCase(); // Normalize for consistency

    if (lowerLabel === "positive") return 1;
    if (lowerLabel === "negative") return -1;
    if (lowerLabel === "mixed") return 0.25;
    if (lowerLabel === "neutral") return 0;

    console.warn(`Unknown sentiment label received: ${label}`);
    return 0; // Fallback to neutral
  };

  // Handle change in sentiment popup
  const handleSentimentChange = (e) => {
    const newSentiment = e.target.value;
    console.log("User selected sentiment:", newSentiment);

    setEditingSentiment((prev) => ({
      ...prev,
      value: newSentiment, // Keep it as a string
    }));
  };

  const confirmSentimentUpdate = async (articleId, label) => {
    try {
      console.log(
        `Attempting to update article ${articleId} with sentiment:`,
        label
      );

      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}/sentiment`,
        { sentiment: label },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Updated article from backend:", response.data);

        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article._id === articleId
              ? { ...article, sentiment: response.data.sentiment } // Use the actual updated sentiment
              : article
          )
        );

        toast.success("Sentiment updated successfully");
      } else {
        throw new Error("Failed to update sentiment");
      }
    } catch (error) {
      console.error("Error updating sentiment:", error);
      toast.error("Failed to update sentiment");
    } finally {
      setEditingSentiment(null);
    }
  };

  // Exit editing mode without saving changes
  const handleSentimentCancel = () => {
    setEditingSentiment(null);
  };

  // Trigger editing mode
  const handleSentimentEdit = (articleId, currentLabel) => {
    setEditingSentiment({ articleId, value: currentLabel });
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredArticles(
      articles.filter(
        (article) =>
          article.title.toLowerCase().includes(lowerCaseQuery) ||
          article.source.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setNewArticle((prev) => ({
      ...prev,
      [name]:
        name === "sentiment"
          ? value // Store as a string for proper selection in <select>
          : type === "number"
          ? value
            ? Number(value)
            : ""
          : value,
    }));
  };

  const handleAddArticle = async () => {
    const {
      source,
      title,
      snippet,
      publication_date,
      url,
      sentiment,
      reach,
      cpm,
    } = newArticle;

    // Validate required fields before proceeding
    if (!source) {
      toast.error("Source name is required.");
      return;
    }
    if (!title) {
      toast.error("Article title is required.");
      return;
    }
    if (!url) {
      toast.error("Article URL is required.");
      return;
    }
    if (!publication_date) {
      toast.error("Publication date is required.");
      return;
    }
    if (!reach || isNaN(Number(reach)) || Number(reach) <= 0) {
      toast.error("Reach must be a valid positive number.");
      return;
    }
    if (!cpm || isNaN(Number(cpm)) || Number(cpm) <= 0) {
      toast.error("CPM must be a valid positive number.");
      return;
    }

    try {
      setIsSubmitting(true); // Disable button

      // Format publication_date to YYYY-MM-DD
      const formattedDate = format(new Date(publication_date), "yyyy-MM-dd");

      // Ensure sentiment is correctly mapped
      const mappedSentiment = mapLabelToSentiment(sentiment);
      console.log("Mapped Sentiment Score:", mappedSentiment); // Debug log

      // Prepare the payload for the backend
      const payload = {
        source,
        title,
        snippet: snippet || title,
        publication_date: formattedDate,
        url,
        sentiment: mappedSentiment, // Send the mapped sentiment
        reach: Number(reach),
        cpm: Number(cpm),
      };

      console.log("Final payload being sent:", payload); // Debug log

      // Make POST request
      const response = await axios.post(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 201) {
        setArticles((prev) => [response.data.article, ...prev]); // Add new article to state
        toast.success("Article added successfully.");
        setPopupVisible(false); // Close the popup
        setNewArticle({}); // Reset the form
      } else {
        toast.error("Failed to add article.");
      }
    } catch (error) {
      console.error("Error adding article:", error);

      // Extract error message properly
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "An unexpected error occurred.";

      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false); // Re-enable button after API call
    }
  };

  // Increase visible articles
  const showMoreArticles = () => {
    setVisibleArticles((prev) => Math.min(prev + 20, filteredArticles.length)); // Ensure it doesn't exceed total articles
  };

  // Decrease visible articles
  const showLessArticles = () => {
    setVisibleArticles((prev) => Math.max(prev - 20, 20)); // Ensure it doesn't go below 20
  };

  // Show all articles
  const showAllArticles = () => {
    setVisibleArticles(filteredArticles.length); // Show all articles
  };

  // Function to get sorting icon dynamically
  const getSortIcon = (sortOrder) => {
    return sortOrder === "ascending" ? (
      <FaSortAmountDown />
    ) : sortOrder === "descending" ? (
      <FaSortAmountUp />
    ) : (
      <FaSortAmountUp style={{ opacity: 0.5 }} />
    ); // Default: Faded icon
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === "date") {
      setDateSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setAveSortOrder("");
      setRankSortOrder("");
      setReachSortOrder(""); // Reset other sorts
    } else if (field === "ave") {
      setAveSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setDateSortOrder("");
      setRankSortOrder("");
      setReachSortOrder(""); // Reset other sorts
    } else if (field === "rank") {
      setRankSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setDateSortOrder("");
      setAveSortOrder("");
      setReachSortOrder(""); // Reset other sorts
    } else if (field === "reach") {
      setReachSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setDateSortOrder("");
      setAveSortOrder("");
      setRankSortOrder(""); // Reset other sorts
    }
  };

  const sortedArticles = [...filteredArticles].sort((a, b) => {
    if (dateSortOrder) {
      return dateSortOrder === "ascending"
        ? new Date(a.publication_date) - new Date(b.publication_date)
        : new Date(b.publication_date) - new Date(a.publication_date);
    }
    if (aveSortOrder) {
      return aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave; // Highest first
    }
    if (rankSortOrder) {
      return rankSortOrder === "ascending" ? a.rank - b.rank : b.rank - a.rank; // Highest first
    }
    if (reachSortOrder) {
      return reachSortOrder === "ascending"
        ? a.reach - b.reach
        : b.reach - a.reach; // Highest first
    }
    return filteredArticles; // Default order when no sorting is applied
  });

  const confirmCountryUpdate = async (articleId) => {
    if (!selectedCountry || !editingCountry) return;

    try {
      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}/country`,
        { country: selectedCountry },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedSource = response.data.name;

        // Update all online articles with the same source name
        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article.source === updatedSource
              ? { ...article, country: selectedCountry }
              : article
          )
        );

        toast.success("Country updated successfully!");
        setIsCountryModalOpen(false); // Close modal after updating
      } else {
        throw new Error("Failed to update country in MongoDB");
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("Failed to update country.");
    } finally {
      setEditingCountry(null);
    }
  };

  const handleCountryEdit = (articleId, currentCountry) => {
    setEditingCountry(articleId);
    setSelectedCountry(currentCountry || "Botswana");
    setCurrentArticleId(articleId);
    setIsCountryModalOpen(true);
  };

  return (
    <div>
      <CountryModal
        isOpen={isCountryModalOpen}
        onClose={() => setIsCountryModalOpen(false)}
      >
        <h2 style={{ fontFamily: "Raleway" }}>Edit Country</h2>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <button
          onClick={() => confirmCountryUpdate(currentArticleId)}
          className="modalSave"
        >
          Save Changes
        </button>
      </CountryModal>

      {popupVisible && (
        <div className="article-popup">
          <div className="article-popup-inner" ref={popupRef}>
            <button
              className="article-cancel-button"
              onClick={() => setPopupVisible(false)}
            >
              &times;
            </button>
            <h3
              className="article-popup-header"
              style={{ marginBottom: "30px" }}
            >
              Add New Article
            </h3>
            <p
              className="article-popup-description"
              style={{ color: "GrayText", marginBottom: "30px" }}
            >
              Fill in the details to add a new article.
            </p>
            <div className="form-group">
              <label htmlFor="source">
                Source Name{" "}
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("source")}
                  // onMouseLeave={() => toggleTooltip(null)}
                />
              </label>
              {infoTooltip === "source" && (
                <div className="tooltip" ref={tooltipRef}>
                  The name of the publication or media source, e.g., "Sunday
                  Standard."
                </div>
              )}
              <input
                id="source"
                type="text"
                name="source"
                // placeholder="Source Name"
                value={newArticle.source}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="title">Article Title</label>
              <input
                id="title"
                type="text"
                name="title"
                // placeholder="Article Title"
                value={newArticle.title}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="title">Article URL</label>
              <input
                id="URL"
                type="text"
                name="url"
                value={newArticle.url}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="snippet">
                Article Snippet
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("snippet")}
                  // onMouseLeave={() => toggleTooltip(null)}
                />
              </label>
              {infoTooltip === "snippet" && (
                <div className="tooltip" ref={tooltipRef}>
                  A brief summary of the article (15 words max).
                </div>
              )}
              <textarea
                id="snippet"
                name="snippet"
                value={newArticle.snippet}
                onChange={(e) => {
                  const words = e.target.value.split(/\s+/); // Splits the input into words
                  if (words.length <= 15) {
                    // Allow only if words are 15 or less
                    handleInputChange(e);
                  } else {
                    // Otherwise, cap it to 15 words and prevent additional input
                    setNewArticle((prev) => ({
                      ...prev,
                      snippet: words.slice(0, 15).join(" "), // Keeps only the first 15 words
                    }));
                  }
                }}
              />
            </div>

            <div className="form-group">
              <label htmlFor="publication_date">
                Publication Date{" "}
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("publication_date")}
                  // onMouseLeave={() => toggleTooltip(null)}
                />
              </label>
              {infoTooltip === "publication_date" && (
                <div className="tooltip" ref={tooltipRef}>
                  Select the date when the article was published.
                </div>
              )}
              <input
                id="publication_date"
                type="date"
                name="publication_date"
                value={newArticle.publication_date}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="sentiment">
                Article Sentiment{" "}
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("sentiment")}
                  // onMouseLeave={() => toggleTooltip(null)}
                />
              </label>
              {infoTooltip === "sentiment" && (
                <div className="tooltip" ref={tooltipRef}>
                  Select the sentiment that best describes the article:
                  Positive, Neutral, Negative, or Mixed.
                </div>
              )}
              <select
                id="sentiment"
                name="sentiment"
                value={newArticle.sentiment}
                onChange={handleInputChange}
              >
                <option value="positive">Positive</option>
                <option value="neutral">Neutral</option>
                <option value="negative">Negative</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="cpm">
                CPM{" "}
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("cpm")}
                  // onMouseLeave={() => toggleTooltip(null)}
                />
              </label>
              {infoTooltip === "cpm" && (
                <div className="tooltip" ref={tooltipRef}>
                  CPM stands for "Cost per Mille" in advertising. It refers to
                  the cost of 1,000 impressions or views.
                </div>
              )}
              <input
                id="cpm"
                type="number"
                name="cpm"
                value={newArticle.cpm}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="reach">
                Article Reach{" "}
                <IoInformationCircle
                  className="info-icon"
                  onClick={() => toggleTooltip("reach")}
                  // onMouseLeave={() => toggleTooltip(null)
                />
              </label>
              {infoTooltip === "reach" && (
                <div className="tooltip" ref={tooltipRef}>
                  Number of unique views received by the article or the number
                  of people who have seen it.
                </div>
              )}
              <input
                id="reach"
                type="number"
                name="reach"
                value={newArticle.reach}
                onChange={handleInputChange}
              />
            </div>

            <button
              onClick={handleAddArticle}
              className="submit-post-btn"
              style={{ marginTop: "30px" }}
            >
              Add Article
            </button>
          </div>
        </div>
      )}

      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <div className="content">
        <h2>{organizationData.organization.organizationName}'s Online Media</h2>
        <div className="holder">
          {/* Chart Section */}
          <div className="charts">
            <div className="piechart">
              <h3>This Month's Top Keyword Trends</h3>
              <Pie data={pieData} options={pieOptions} />
            </div>
            <div className="chart-container">
              <h3>Number of Articles over the Year</h3>
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>

          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="table-controls">
              {/* Date Range Filter */}
              <div
                className="date-container"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date">
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredArticles(articles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>

              {/* Button visible only for super_admin */}
              {user.role === "super_admin" && (
                <div>
                  <button
                    onClick={() => setPopupVisible(true)}
                    className="add-article-btn"
                  >
                    Add New Article
                  </button>
                </div>
              )}
            </div>

            <br />

            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Title</th>
                      <th>Summary</th>
                      <th
                        onClick={() => handleSort("date")}
                        style={{ cursor: "pointer" }}
                      >
                        Date Posted {getSortIcon(dateSortOrder)}
                      </th>
                      <th>Country</th>
                      <th
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() =>
                          setSentimentFilterVisible(!sentimentFilterVisible)
                        }
                      >
                        Sentiment
                        <FaFilter
                          style={{
                            marginLeft: "5px",
                            opacity: sentimentFilter ? 1 : 0.5,
                          }}
                        />
                        {sentimentFilterVisible && (
                          <div className="section-filter-dropdown">
                            <ul>
                              <li
                                onClick={() => {
                                  setSentimentFilter(""); // Reset filter
                                  setSentimentFilterVisible(false);
                                }}
                              >
                                All Sentiments
                              </li>
                              <li
                                onClick={() => {
                                  setSentimentFilter("positive");
                                  setSentimentFilterVisible(false);
                                }}
                              >
                                Positive
                              </li>
                              <li
                                onClick={() => {
                                  setSentimentFilter("neutral");
                                  setSentimentFilterVisible(false);
                                }}
                              >
                                Neutral
                              </li>
                              <li
                                onClick={() => {
                                  setSentimentFilter("negative");
                                  setSentimentFilterVisible(false);
                                }}
                              >
                                Negative
                              </li>
                            </ul>
                          </div>
                        )}
                      </th>

                      <th
                        onClick={() => handleSort("ave")}
                        style={{ cursor: "pointer" }}
                      >
                        AVE {getSortIcon(aveSortOrder)}
                      </th>
                      <th>Coverage Type</th>
                      <th
                        onClick={() => handleSort("rank")}
                        style={{ cursor: "pointer" }}
                      >
                        Rank {getSortIcon(rankSortOrder)}
                      </th>
                      <th
                        onClick={() => handleSort("reach")}
                        style={{ cursor: "pointer" }}
                      >
                        Reach {getSortIcon(reachSortOrder)}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedArticles.length === 0 ? (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          No articles found for this organization.
                        </td>
                      </tr>
                    ) : (
                      sortedArticles
                        .slice(0, visibleArticles)
                        .map((article, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "8px",
                                }}
                              >
                                {article.logo_url && (
                                  <img
                                    src={article.logo_url}
                                    alt={`${article.source} logo`}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "0.5px solid",
                                      padding: "1px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    cursor:
                                      user.role === "super_admin"
                                        ? "pointer"
                                        : "default",
                                  }}
                                  title={
                                    user.role === "super_admin"
                                      ? "Click to edit source"
                                      : ""
                                  }
                                  onClick={() =>
                                    user.role === "super_admin" &&
                                    handleSourceEdit(
                                      article._id,
                                      article.source
                                    )
                                  }
                                >
                                  {editingSource &&
                                  editingSource.articleId === article._id ? (
                                    <input
                                      type="text"
                                      className="sourceInput"
                                      value={editingSource.value}
                                      onChange={handleSourceChange}
                                      onKeyDown={(e) =>
                                        handleKeyPress(
                                          e,
                                          article._id,
                                          editingSource.value
                                        )
                                      }
                                      onBlur={handleBlur}
                                      autoFocus
                                    />
                                  ) : (
                                    article.source
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <a
                                href={article.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {article.title} ⤴
                              </a>
                            </td>
                            <td className="snippet">
                              {" "}
                              "{article.snippet || article.title}"
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {new Date(
                                article.publication_date
                              ).toLocaleDateString()}
                            </td>
                            <td
                              className="source_country"
                              onClick={() => {
                                if (user.role === "super_admin") {
                                  handleCountryEdit(
                                    article._id,
                                    article.country
                                  );
                                }
                              }}
                              title={
                                user.role === "super_admin"
                                  ? "Click to change country"
                                  : ""
                              }
                              style={{
                                cursor:
                                  user.role === "super_admin"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              {article.country || "Unknown"}
                            </td>

                            <td>
                              {editingSentiment &&
                              editingSentiment.articleId === article._id ? (
                                user.role === "super_admin" ? (
                                  <div className="sentiment-popup">
                                    <h4>Modify Sentiment</h4>
                                    <form>
                                      {/* Radio buttons for sentiment selection */}
                                      <label>
                                        <input
                                          type="radio"
                                          value="positive"
                                          checked={
                                            editingSentiment.value ===
                                            "positive"
                                          }
                                          onChange={handleSentimentChange}
                                        />
                                        Positive
                                      </label>
                                      <label>
                                        <input
                                          type="radio"
                                          value="neutral"
                                          checked={
                                            editingSentiment.value === "neutral"
                                          }
                                          onChange={handleSentimentChange}
                                        />
                                        Neutral
                                      </label>
                                      <label>
                                        <input
                                          type="radio"
                                          value="negative"
                                          checked={
                                            editingSentiment.value ===
                                            "negative"
                                          }
                                          onChange={handleSentimentChange}
                                        />
                                        Negative
                                      </label>
                                      <label>
                                        <input
                                          type="radio"
                                          value="mixed"
                                          checked={
                                            editingSentiment.value === "mixed"
                                          }
                                          onChange={handleSentimentChange}
                                        />
                                        Mixed
                                      </label>
                                      {/* Action buttons */}
                                      <div className="popup-actions">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            confirmSentimentUpdate(
                                              article._id,
                                              editingSentiment.value
                                            )
                                          }
                                        >
                                          Confirm
                                        </button>
                                        <button
                                          type="button"
                                          onClick={handleSentimentCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <span className="sentiment-text">
                                    {mapSentimentToLabel(article.sentiment)}{" "}
                                    {/* Just display the sentiment */}
                                  </span>
                                )
                              ) : (
                                <div
                                  className={`sentiment-container sentiment ${mapSentimentToLabel(
                                    article.sentiment
                                  )}`}
                                  title={
                                    user.role === "super_admin"
                                      ? "Click to change sentiment"
                                      : ""
                                  }
                                  onClick={() =>
                                    user.role === "super_admin" &&
                                    handleSentimentEdit(
                                      article._id,
                                      mapSentimentToLabel(article.sentiment)
                                    )
                                  }
                                  style={{
                                    cursor:
                                      user.role === "super_admin"
                                        ? "pointer"
                                        : "default",
                                  }}
                                >
                                  <div className="sentiment-icon-container">
                                    {mapSentimentToLabel(article.sentiment) ===
                                    "positive" ? (
                                      <LuThumbsUp className="sentiment-icon" />
                                    ) : mapSentimentToLabel(
                                        article.sentiment
                                      ) === "negative" ? (
                                      <LuThumbsDown className="sentiment-icon" />
                                    ) : mapSentimentToLabel(
                                        article.sentiment
                                      ) === "mixed" ? (
                                      <ImConfused className="sentiment-icon" />
                                    ) : (
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    )}
                                  </div>
                                  <span className="sentiment-text">
                                    {mapSentimentToLabel(article.sentiment)}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td style={{ fontWeight: "500" }}>
                              {article.ave
                                ? article.ave.toFixed(2) + " BWP"
                                : "-"}
                            </td>
                            <td className="coverage-text">
                              {article.coverage_type}
                            </td>
                            <td
                              style={{
                                // color: "#414141",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {article.rank}/5
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {article.reach}
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() => handleMenuClick(index)}
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() => handleMenuClose(index)}
                                >
                                  <a href={article.url} download>
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                  >
                                    Send Article
                                  </a>
                                  {user.role === "super_admin" && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(article._id);
                                      }}
                                      className="delete-link"
                                    >
                                      Delete
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing {Math.min(visibleArticles, filteredArticles.length)}{" "}
                    of {filteredArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visibleArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Online;
