import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../Components/Modal';
import '../App.css';
import '../CSS/login.css';
import { CircleLoader } from 'react-spinners';

function Register() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  });
  const [passwordError, setPasswordError] = useState('');
  const [passwordsMatchError, setPasswordsMatchError] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOrgSelect, setShowOrgSelect] = useState(false);  // For super_admin org selection
  const [organizations, setOrganizations] = useState([]);      // List of organizations for selection
  const [selectedOrg, setSelectedOrg] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    if (id === 'password') validatePassword(value);
    if (id === 'confirmPassword') validatePasswordsMatch(formData.password, value);
  };

  const validatePassword = (password) => {
    setPasswordError(password.length < 8 ? 'Password must be at least 8 characters long.' : '');
  };

  const validatePasswordsMatch = (password, confirmPassword) => {
    setPasswordsMatchError(confirmPassword && password !== confirmPassword ? 'Passwords do not match.' : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordError || passwordsMatchError) {
      setModalContent('Please correct the errors before submitting.');
      setShowModal(true);
      return;
    }

    setLoading(true);

    try {
      // Register the user
      await axios.post('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/register', formData);

      // Automatically log the user in after registration
      const loginResponse = await axios.post('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/login', {
        email: formData.email,
        password: formData.password
      });
      
      const { token, user } = loginResponse.data;
      
      // Store token and user info in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      if (user.role === 'org_admin') {
        // Redirect org_admins directly to their organization dashboard
        navigate(`/dashboard/${user.organizationId}`);
      } else if (user.role === 'super_admin') {
        // Fetch organizations and show selection modal for super_admins
        const orgResponse = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOrganizations(orgResponse.data);
        setShowOrgSelect(true);
      }
    } catch (error) {
      setModalContent(error.response?.data || 'An error occurred. Please try again.');
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOrgChange = (e) => {
    setSelectedOrg(e.target.value);
  };

  const handleContinue = async () => {
    if (!selectedOrg) return;

    setLoading(true); // Show the loading spinner before redirecting
    localStorage.setItem('selectedOrg', selectedOrg);  // Store selected organization ID

    // Simulate loading for smoother UX
    await new Promise(resolve => setTimeout(resolve, 1000));

    setLoading(false);
    setShowOrgSelect(false);
    navigate(`/dashboard/${selectedOrg}`);  // Redirect to selected org's dashboard
  };

  const handleRequestNewOtp = async () => {
    try {
      const response = await axios.post('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/request-new-otp', { email: formData.email });
      setModalContent(response.data);
      setShowModal(true);
    } catch (error) {
      setModalContent(error.response?.data || 'Error requesting new OTP. Please try again.');
      setShowModal(true);
    }
  };

  const closePopup = () => setShowModal(false);

  return (
    <div className="background-container">
      <div className="left-content">
        <h1>Join Us Today!</h1>
        <p>Create your account to get started.</p>
      </div>
      <div className="right-content">
        <div className="glass-card">
          <form onSubmit={handleSubmit}>
            <img src={`${process.env.PUBLIC_URL}/app/social.jpg`} className="logo" alt="social-light-logo" />
            <div className="login-form">
              <label htmlFor="firstName">First Name</label>
              <input type="text" id="firstName" value={formData.firstName} onChange={handleChange} required />
            </div>
            <div className="login-form">
              <label htmlFor="lastName">Last Name</label>
              <input type="text" id="lastName" value={formData.lastName} onChange={handleChange} required />
            </div>
            <div className="login-form">
              <label htmlFor="email">Username (Email)</label>
              <input type="email" id="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="login-form">
              <label htmlFor="otp">One-Time Password (OTP)</label>
              <input type="text" id="otp" value={formData.otp} onChange={handleChange} required />
            </div>
            <div className="login-form">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" value={formData.password} onChange={handleChange} required />
              {passwordError && <p style={{ color: 'red', fontSize: '12px' }}>{passwordError}</p>}
            </div>
            <div className="login-form">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input type="password" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
              {passwordsMatchError && <p style={{ color: 'red', fontSize: '12px' }}>{passwordsMatchError}</p>}
            </div>
            <button type="submit" disabled={passwordError || passwordsMatchError || loading}>
              Register
            </button>
            <div className="separator">
              <span>OR</span>
            </div>
            <div className="sign-in-link">
              <p>Already have an account? <Link to="/login">Sign In</Link></p>
            </div>
          </form>
        </div>
      </div>

      <Modal show={showModal} onClose={closePopup}>
        <p>{modalContent}</p>
        {modalContent.toLowerCase().includes('expired') && (
          <button onClick={handleRequestNewOtp}>Request New OTP</button>
        )}
      </Modal>

      {/* Organization selection modal for super_admins */}
      <Modal show={showOrgSelect} onClose={() => setShowOrgSelect(false)}>
        <h2>Select Your Organization</h2>
        <select onChange={handleOrgChange} value={selectedOrg} required>
          <option value="">Select an organization</option>
          {organizations.map(org => (
            <option key={org._id} value={org._id}>{org.organizationName}</option>
          ))}
        </select>
        <button onClick={handleContinue}>Continue</button>
      </Modal>
      
      {loading && (
        <div className="loading-overlay">
          <CircleLoader color="#0056b3" size={100} />
          <p>Getting ready...</p>
        </div>
      )}
    </div>
  );
}

export default Register;
