/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import "../CSS/Settings.css";
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import Header from '../Components/Header';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [email, setEmail] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        fetchUsers();
        fetchOrganizations();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/users');
            setUsers(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch users');
            console.error(error);
            setLoading(false);
        }
    };

    const fetchOrganizations = async () => {
        try {
            const response = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations');
            setOrganizations(response.data);
        } catch (error) {
            console.error('Error loading organizations:', error);
        }
    };

    const handleSave = async () => {
        if (!email || !userRole) {
            alert("Please fill all required fields.");
            return;
        }
        try {
            const payload = {
                email,
                role: userRole,
                organizationId: userRole === 'org_admin' ? selectedOrg : undefined
            };
            const response = await axios.post('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/users/create', payload);

            if (response.status === 201) {
                alert('User created successfully. A welcome email has been sent.');
            }
            setShowPopup(false);
            fetchUsers(); // Refresh the list of users
        } catch (error) {
            console.error('Failed to create user:', error);
            alert('Failed to create user');
        }
    };

    return (
        <div>
            {showPopup && (
                <div className="mgmt-popup" onClick={() => setShowPopup(false)}>
                    <div className="mgmt-popup-inner" onClick={(e) => e.stopPropagation()}>
                        <h3>Add New User</h3>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                        <select value={userRole} onChange={(e) => setUserRole(e.target.value)}>
                            <option value="">Select Role</option>
                            <option value="super_admin">Super Admin</option>
                            <option value="org_admin">Org Admin</option>
                        </select>
                        {userRole === 'org_admin' && (
                            <div>
                                <label>Organization:</label>
                                <select value={selectedOrg} onChange={(e) => setSelectedOrg(e.target.value)}>
                                    {organizations.map(org => (
                                        <option key={org._id} value={org._id}>{org.organizationName}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <button className="save-user" onClick={handleSave}>Save User</button>
                        <span className="mgmt-close" onClick={() => setShowPopup(false)}>
                            &times;
                        </span>
                    </div>
                </div>
            )}

            <div className="user-management-header">
                <h4 style={{ paddingLeft: "12px" }}></h4>
                <button className="add-users-button" onClick={() => setShowPopup(true)}>Create User</button>
            </div>

            <div className="table-container">
                {loading ? (
                    // <div className="spinner-container">
                    <div className="spinner"></div>
                    // </div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Organization</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? (
                                users.map((user) => (
                                    <tr key={user._id}>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>{user.organizationName || 'ALL'}</td>
                                        <td>
                                            <div className="action-icon edit-icon">
                                                <Link to={`/user/edit/${user._id}`}>
                                                    <MdEdit className="editing-icon" size={16} />
                                                </Link>
                                            </div>
                                            <div className="action-icon delete-icon">
                                                <MdDelete size={16} color="red" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No users found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default UserManagement;
