/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../../Components/Header";
import "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";
import {
  FaEllipsisV,
  FaSortAmountUp,
  FaSortAmountDown,
  FaFilter,
} from "react-icons/fa";
import "../../CSS/Dashboard.css";
import "../../CSS/online.css";
import "../../App.css";
import { LuCalendarDays } from "react-icons/lu";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Toaster, toast } from "sonner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "../../Components/ThemeContext";
import CountryModal from "../../Components/CountryModal";

function Print() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  console.log("Loaded selectedOrg from localStorage:", selectedOrg);
  const { orgId } = useParams();
  const [articles, setArticles] = useState([]);
  const [sourceDistribution, setSourceDistribution] = useState({});
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [pieData, setPieData] = useState({});
  const [articleSentiment, setArticleSentiment] = useState("");
  const [aveSortOrder, setAveSortOrder] = useState("");
  const [rankSortOrder, setRankSortOrder] = useState("");
  const [reachSortOrder, setReachSortOrder] = useState("");
  const [dateSortOrder, setDateSortOrder] = useState("descending");
  const [coverageType, setCoverageType] = useState("");
  const [section, setSection] = useState("");
  const [editingSource, setEditingSource] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [editingSentiment, setEditingSentiment] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState(20);
  const { theme } = useContext(ThemeContext);
  const [sections, setSections] = useState([]); // Stores unique sections
  const [sectionFilterVisible, setSectionFilterVisible] = useState(false);
  const [editingCountry, setEditingCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [countries, setCountries] = useState([]);

  //geolocation useeffect
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort(); // Sort alphabetically
        setCountries([
          "Botswana",
          ...countryNames.filter((c) => c !== "Botswana"),
        ]); // Keep Botswana at top
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    const fetchOrgData = async () => {
      try {
        const endpoint =
          user.role === "super_admin"
            ? `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`
            : `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${user.organizationId}`;

        const response = await axios.get(endpoint);
        if (isMounted) setOrganizationData(response.data);
      } catch (error) {
        if (error.response && [401, 403].includes(error.response.status)) {
          navigate("/login");
        } else {
          setError("Failed to load organization data.");
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (selectedOrg || user.organizationId) {
      fetchOrgData();
    }

    return () => {
      isMounted = false;
    }; // cleanup function to set isMounted false
  }, [user.role, selectedOrg, user.organizationId, navigate]);

  // Fetch articles for the selected organization
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/printMedia`
        );
        const articles = response.data;
        setArticles(articles);

        // Extract unique sections
        const uniqueSections = [
          ...new Set(
            articles.map((article) => article.section).filter(Boolean)
          ),
        ];
        setSections(uniqueSections);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setLoading(false);
      }
    };

    if (selectedOrg) fetchArticles();
  }, [selectedOrg]);

  // Update source distribution for articles only
  useEffect(() => {
    const distribution = {};
    articles.forEach((article) => {
      distribution[article.publication] =
        (distribution[article.publication] || 0) + 1;
    });
    setSourceDistribution(distribution);
  }, [articles]);

  // Helper function to generate bold, distinct colors and lighter fill colors
  // Helper function to generate smooth radial gradients for pie chart sections
  const getGradient = (ctx, index) => {
    const gradient = ctx.createRadialGradient(100, 100, 50, 100, 100, 200);
    const hue = (index * 47) % 360;

    gradient.addColorStop(0, `hsl(${hue}, 90%, 40%)`); // Darker center
    gradient.addColorStop(1, `hsl(${hue}, 90%, 75%)`); // Lighter edge

    return gradient;
  };

  // Function to group articles by month and count the sources
  const groupArticlesByMonth = (articles) => {
    return articles.reduce((acc, article) => {
      if (!article.publicationDate) return acc;

      const articleDate = new Date(article.publicationDate);
      const articleMonth = articleDate.toLocaleString("default", {
        month: "short",
      });
      const articleYear = articleDate.getFullYear();

      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString("default", {
        month: "short",
      });
      const currentYear = currentDate.getFullYear();

      // Only include articles from the current month and year
      if (articleMonth === currentMonth && articleYear === currentYear) {
        const source = article.publication;
        acc[source] = (acc[source] || 0) + 1;
      }

      return acc;
    }, {});
  };

  // Pie chart data for media source distribution (filtered to current month)
  useEffect(() => {
    const distribution = groupArticlesByMonth(articles);

    setPieData({
      labels: Object.keys(distribution),
      datasets: [
        {
          data: Object.values(distribution),
          backgroundColor: (context) => {
            const { chart } = context;
            const { ctx } = chart;
            return Object.keys(distribution).map((_, index) =>
              getGradient(ctx, index)
            );
          },
          borderColor: "transparent",
          borderWidth: 2,
          borderRadius: 3,
          spacing: 6,
          offset: 2,
        },
      ],
    });
  }, [articles]);

  // Pie chart options
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          font: { family: "Raleway", size: 13, weight: "500" },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.label}: ${tooltipItem.raw} mentions`,
        },
      },
      datalabels: {
        color: theme === "light" ? "#fff" : "#fff",
        font: { weight: "medium", size: 12, family: "Raleway" },
        formatter: (value) => `${value}`,
        anchor: "end",
        align: "start",
        offset: 10,
      },
    },
    cutout: "50%", // Donut shape
  };

  // Ensure articles are processed correctly for the line chart
  const groupArticlesByMonthForLineChart = (articles) => {
    const currentYear = new Date().getFullYear();
    const monthCounts = new Array(12).fill(0);

    articles.forEach((article) => {
      if (article.publicationDate) {
        const articleDate = new Date(article.publicationDate);
        if (articleDate.getFullYear() === currentYear) {
          monthCounts[articleDate.getMonth()] += 1;
        }
      }
    });

    return monthCounts;
  };

  const articleCountsByMonth = groupArticlesByMonthForLineChart(articles);

  const lineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Print Media Articles",
        data: articleCountsByMonth,
        borderColor: "rgb(192, 85, 75)", // Green
        backgroundColor: "rgba(192, 85, 75, 0.2)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 7,
        fill: true,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 500, // Stretch y-axis
        stepSize: 100,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // Forces circle style
          font: { family: "Raleway", size: 13 },
          color: theme === "light" ? "#7a7a7a" : "#fff",
          paddingBottom: 25,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} Articles`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  //for calendar/date filter
  useEffect(() => {
    if (startDate && endDate) {
      const filtered = articles.filter((article) => {
        const articleDate = new Date(article.publicationDate);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles); // Reset to show all articles
    }
  }, [startDate, endDate, articles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  // Handle delete article
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this article?"))
      return;

    try {
      const response = await axios.delete(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/printMedia/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setArticles((prevArticles) =>
          prevArticles.filter((article) => article._id !== id)
        );
        setFilteredArticles((prevArticles) =>
          prevArticles.filter((article) => article._id !== id)
        );
        toast.success("Article deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting article:", error);
      toast.error("Failed to delete article");
    }
  };

  // Filter logic
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...articles];

      // Filter by sentiment
      if (articleSentiment) {
        filtered = filtered.filter((article) => {
          if (articleSentiment === "positive") return article.sentiment > 0.05;
          if (articleSentiment === "neutral")
            return article.sentiment >= -0.05 && article.sentiment <= 0.05;
          if (articleSentiment === "negative") return article.sentiment < -0.05;
          if (articleSentiment === "mixed")
            return article.sentiment > -0.5 && article.sentiment < 0.5;
          return true;
        });
      }

      // Sort by news section
      if (section) {
        filtered = filtered.filter((article) => article.section === section);
      }

      // Filter by date range
      if (startDate && endDate) {
        filtered = filtered.filter((article) => {
          const articleDate = new Date(article.publicationDate);
          return articleDate >= startDate && articleDate <= endDate;
        });
      }

      setFilteredArticles(filtered);
    };

    applyFilters();
  }, [articles, articleSentiment, section, aveSortOrder, startDate, endDate]);

  // Dynamically update visibleArticles when filteredArticles changes
  useEffect(() => {
    setVisibleArticles(Math.min(20, filteredArticles.length));
  }, [filteredArticles]);

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  const handleMenuClick = (index) => {
    document.getElementById(`menu-${index}`).style.display = "block";
  };

  const handleMenuClose = (index) => {
    document.getElementById(`menu-${index}`).style.display = "none";
  };

  // edit source name on articles table
  const handleSourceEdit = (articleId, currentSource) => {
    setEditingSource({ articleId, value: currentSource });
  };

  const handleSourceChange = (e) => {
    setEditingSource((prev) => ({ ...prev, value: e.target.value }));
  };

  const confirmSourceUpdate = async (articleId, newSource) => {
    if (
      window.confirm(
        `Are you sure you want to change the source to "${newSource}"?`
      )
    ) {
      try {
        const response = await axios.put(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}`,
          { newSource },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Update the articles in local state
          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in articles
                : article
            )
          );

          // Update the displayedArticles state
          setDisplayedArticles((prevDisplayedArticles) =>
            prevDisplayedArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in displayedArticles
                : article
            )
          );

          toast.success("Source updated successfully");
          setEditingSource(null); // Close the input box
        }
      } catch (error) {
        console.error("Error updating source:", error);
        toast.error("Failed to update source");
      }
    }
  };

  const handleKeyPress = (e, articleId, newSource) => {
    if (e.key === "Enter") {
      confirmSourceUpdate(articleId, newSource);
    }
  };

  const handleBlur = () => {
    setEditingSource(null);
  };

  // Map numerical sentiment score from MongoDB to label
  const mapSentimentToLabel = (score) => {
    if (score > 0) return "positive";
    if (score < 0) return "negative";
    return "neutral";
  };

  // Map sentiment label to numerical score for MongoDB
  const mapLabelToSentiment = (label) => {
    if (label === "positive") return 1;
    if (label === "negative") return -1;
    return 0;
  };

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredArticles(
      articles.filter(
        (article) =>
          article.publication.toLowerCase().includes(lowerCaseQuery) ||
          article.headline.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  // Increase visible articles
  const showMoreArticles = () => {
    setVisibleArticles((prev) => Math.min(prev + 20, filteredArticles.length)); // Ensure it doesn't exceed total articles
  };

  // Decrease visible articles
  const showLessArticles = () => {
    setVisibleArticles((prev) => Math.max(prev - 20, 20)); // Ensure it doesn't go below 20
  };

  // Show all articles
  const showAllArticles = () => {
    setVisibleArticles(filteredArticles.length); // Show all articles
  };

  // Function to get sorting icon dynamically
  const getSortIcon = (sortOrder) => {
    return sortOrder === "ascending" ? (
      <FaSortAmountDown />
    ) : sortOrder === "descending" ? (
      <FaSortAmountUp />
    ) : (
      <FaSortAmountUp style={{ opacity: 0.5 }} />
    ); // Default: Faded icon
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === "date") {
      setDateSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setAveSortOrder("");
    } else if (field === "ave") {
      setAveSortOrder((prevOrder) =>
        prevOrder === "ascending"
          ? "descending"
          : prevOrder === "descending"
          ? "ascending"
          : "descending"
      );
      setDateSortOrder("");
    }
  };

  const sortedArticles = [...filteredArticles].sort((a, b) => {
    if (dateSortOrder) {
      return dateSortOrder === "ascending"
        ? new Date(a.publicationDate) - new Date(b.publicationDate)
        : new Date(b.publicationDate) - new Date(a.publicationDate);
    }
    if (aveSortOrder) {
      return aveSortOrder === "ascending" ? a.ave - b.ave : b.ave - a.ave;
    }
    return 0; // Return 0 to keep original order if no sort applied
  });

  const confirmCountryUpdate = async (printId) => {
    if (!selectedCountry || !editingCountry) return;

    try {
      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/printmedias/${printId}/country`,
        { country: selectedCountry },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedPublication = response.data.name;

        // Update all print articles with the same publication name
        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article.publication === updatedPublication
              ? { ...article, country: selectedCountry }
              : article
          )
        );

        toast.success("Country updated successfully!");
        setIsCountryModalOpen(false);
      } else {
        throw new Error("Failed to update country in MongoDB");
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("Failed to update country.");
    } finally {
      setEditingCountry(null);
    }
  };

  const handleCountryEdit = (printId, currentCountry) => {
    setEditingCountry(printId);
    setSelectedCountry(currentCountry || "Botswana");
    setCurrentArticleId(printId);
    setIsCountryModalOpen(true);
  };

  return (
    <div>
      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <CountryModal
        isOpen={isCountryModalOpen}
        onClose={() => setIsCountryModalOpen(false)}
      >
        <h2 style={{ fontFamily: "Raleway" }}>Edit Country</h2>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <button
          onClick={() => confirmCountryUpdate(currentArticleId)}
          className="modalSave"
        >
          Save Changes
        </button>
      </CountryModal>
      <div className="content">
        <h2>{organizationData.organization.organizationName}'s Print Media</h2>
        <div className="holder">
          {/* Chart Section */}
          <div className="charts">
            <div className="piechart">
              <h3>This Month's Media Source Distribution</h3>
              <Pie data={pieData} options={pieOptions} />
            </div>
            <div className="chart-container">
              <h3>Number of Articles over the Year</h3>
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>

          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Quick Filters
            </h3>
            <div className="table-controls">
              {/* Date Range Filter */}
              <div
                className="date-container"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays
                  className="calendar-icon"
                  style={{ fontSize: "16px" }}
                />
                <span className="user-date">
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the calendar toggle
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredArticles(articles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}
                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>
            </div>

            <br />

            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Title</th>
                      <th
                        onClick={() => handleSort("date")}
                        style={{ cursor: "pointer" }}
                      >
                        Date-Published {getSortIcon(dateSortOrder)}
                      </th>
                      <th>Country</th>
                      <th
                        onClick={() => handleSort("ave")}
                        style={{ cursor: "pointer" }}
                      >
                        AVE {getSortIcon(aveSortOrder)}
                      </th>
                      <th>Sentiment</th>
                      <th
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() =>
                          setSectionFilterVisible(!sectionFilterVisible)
                        }
                      >
                        News Section{" "}
                        <FaFilter
                          style={{
                            marginLeft: "5px",
                            opacity: section ? 1 : 0.5,
                          }}
                        />
                        {sectionFilterVisible && (
                          <div className="section-filter-dropdown">
                            <ul>
                              <li
                                onClick={() => {
                                  setSection("");
                                  setSectionFilterVisible(false);
                                }}
                              >
                                All Sections
                              </li>
                              {sections.map((sec, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setSection(sec);
                                    setSectionFilterVisible(false);
                                  }}
                                >
                                  {sec}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArticles.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No print articles found yet for this organization.
                        </td>
                      </tr>
                    ) : (
                      sortedArticles
                        .slice(0, visibleArticles)
                        .map((article, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "8px",
                                }}
                              >
                                {article.logo_url && (
                                  <img
                                    className="logo-img"
                                    src={article.logo_url}
                                    alt={`${article.publication} logo`}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "1px solid",
                                      padding: "1px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {article.publication}
                                </span>
                              </div>
                            </td>
                            <td style={{ width: "150px" }}>
                              <a
                                href={article.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {article.headline} ⤴
                              </a>
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {new Date(
                                article.publicationDate
                              ).toLocaleDateString()}
                            </td>
                            <td
                              className="source_country"
                              onClick={() => {
                                if (user.role === "super_admin") {
                                  handleCountryEdit(
                                    article._id,
                                    article.country
                                  );
                                }
                              }}
                              title={
                                user.role === "super_admin"
                                  ? "Click to change country"
                                  : ""
                              }
                              style={{
                                cursor:
                                  user.role === "super_admin"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              {article.country || "Unknown"}
                            </td>

                            <td>
                              {article.ave
                                ? `${article.ave.toFixed(2)} BWP`
                                : "-"}
                            </td>
                            <td>-</td>
                            <td>
                              {" "}
                              <div
                                className="station-type"
                                style={{ fontWeight: "500" }}
                              >
                                {article.section}
                              </div>
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() => handleMenuClick(index)}
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() => handleMenuClose(index)}
                                >
                                  <a href={article.url} download>
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                  >
                                    Send Article
                                  </a>
                                  {user.role === "super_admin" && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(article._id);
                                      }}
                                      className="delete-link"
                                    >
                                      Delete
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Show More / Show Less Buttons */}
              {filteredArticles.length > 20 && (
                <div className="show-more-container">
                  <p className="articles-table-count">
                    Showing {Math.min(visibleArticles, filteredArticles.length)}{" "}
                    of {filteredArticles.length} articles
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "inline-flex", gap: "8px" }}
                  >
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-more-button"
                        onClick={showMoreArticles}
                      >
                        <IoIosAddCircle size={30} />
                      </button>
                    )}
                    {visibleArticles > 20 && (
                      <button
                        className="show-less-button"
                        onClick={showLessArticles}
                      >
                        <IoIosRemoveCircle size={30} />
                      </button>
                    )}
                    {visibleArticles < filteredArticles.length && (
                      <button
                        className="show-all-button"
                        onClick={showAllArticles}
                      >
                        Show All
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Print;
