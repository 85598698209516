import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../Components/Modal';
import '../App.css';
import '../CSS/login.css';
import { CircleLoader } from 'react-spinners';

function Login() {
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [showOrgSelect, setShowOrgSelect] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);  // Loading state for the spinner
    const navigate = useNavigate();

    const handleChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.id]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Show loading spinner when login is submitted
        try {
            const response = await axios.post('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/login', credentials);

            if (response && response.data) {
                console.log('Login successful', response.data);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', JSON.stringify(response.data.user));

                if (response.data.user.role === 'org_admin') {
                    localStorage.setItem('organizationId', response.data.user.organizationId);
                    localStorage.setItem('selectedOrg', response.data.user.organizationId); // Ensure selectedOrg is set for org_admin
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    setLoading(false);
                    navigate(`/dashboard/${response.data.user.organizationId}`);
                } else if (response.data.user.role === 'super_admin') {
                    try {
                        const orgResponse = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations', {
                            headers: { Authorization: `Bearer ${response.data.token}` }
                        });
                        if (orgResponse.data) {
                            setOrganizations(orgResponse.data);
                            setShowOrgSelect(true); // Open organization selection modal
                        } else {
                            throw new Error('Failed to load organizations');
                        }
                    } catch (orgError) {
                        console.error('Failed to fetch organizations', orgError);
                        alert(orgError.message);
                    }
                    setLoading(false);
                }
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Failed to login', error);
            alert(`Login failed: ${error.message || 'An unexpected error occurred'}`);
            setLoading(false);
        }
    };

    const handleOrgChange = (e) => {
        setSelectedOrg(e.target.value);
    };


    const handleContinue = async () => {
        console.log(`You have selected: ${selectedOrg}`);
        if (!selectedOrg) {
            alert('Please select an organization');
            return;
        }

        localStorage.setItem('organizationId', selectedOrg); // Store selected organization ID
        localStorage.setItem('selectedOrg', selectedOrg); // Ensure consistency for Dashboard fetching
        setShowOrgSelect(false);

        // Show loading spinner before redirecting to the selected org's dashboard
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate loading
        setLoading(false);
        navigate(`/dashboard/${selectedOrg}`);
    };


    return (
        <div className="background-container">
            <div className="left-content">
                <h1>Welcome Back!</h1>
                <p>Login to continue accessing your account.</p>
            </div>
            <div className="right-content">
                <form className="glass-card" onSubmit={handleSubmit}>
                    <img src='/app/social.jpg' className="logo" alt="social-light-logo" />
                    <div className="login-form">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={credentials.email} onChange={handleChange} required />
                    </div>
                    <div className="login-form">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" value={credentials.password} onChange={handleChange} required />
                    </div>
                    <button type="submit" disabled={loading}>Login</button>
                    <div className="separator">
                        <span>OR</span>
                    </div>
                    <div className="sign-in-link">
                        <p>Don't have an account? <Link to="/register">Sign Up</Link></p>
                    </div>
                </form>
            </div>

            {/* Organization selection modal for super_admins */}
            <Modal show={showOrgSelect} onClose={() => setShowOrgSelect(false)}>
                <h2>Select Your Organization</h2>
                <select onChange={handleOrgChange} value={selectedOrg} required>
                    <option value="">Select an organization</option>
                    {organizations.map(org => (
                        <option key={org._id} value={org._id}>{org.organizationName}</option>
                    ))}
                </select>
                <button className="loggingIn" onClick={handleContinue}>Continue</button>
            </Modal>

            {/* Full-page loading overlay */}
            {loading && (
                <div className="loading-overlay">
                    <CircleLoader color="#0056b3" size={100} />
                    <p>Loading...</p>
                </div>
            )}
        </div>
    );
}

export default Login;
