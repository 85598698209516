// src/Components/UserAvatar.js

import React from 'react';
import "../CSS/Header.css";


const UserAvatar = ({ userName }) => {
    const getInitials = (name) => {
        return name.split(' ').map(n => n[0]).join('').toUpperCase();
    };

    const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    };

    const initials = getInitials(userName);
    const baseColor = stringToColor(userName);
    const backgroundColor = `${baseColor}33`;
    const borderColor = `${baseColor}FF`;
    const textColor = `${baseColor}FF`;

    return (
        <div
            className="user-avatar"
            style={{
                backgroundColor,
                borderColor: textColor,
                color: textColor,
                borderWidth: '3px',
            }}
        >
            {initials}
        </div>
    );
};

export default UserAvatar;
