import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../Components/Header";
import jsPDF from "jspdf";
import "../CSS/Article.css";
import "../App.css";
import Modal from "./Modal";

function AddArticle() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [organizationData, setOrganizationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const selectedOrg = localStorage.getItem('selectedOrg'); // Get selected organization ID
    const { orgId } = useParams(); // Access the orgId from the URL


    const [articleData, setArticleData] = useState({
        publishedDate: "",
        harvestedDate: "",
        title: "",
        author: "",
        extract: "",
        content: "",
        language: "",
        topic: "",
        sourceFeedType: "",
        sourceMediaType: "",
        sourceMediaOptions: [],
        sourceGroup: "",
        reach: "",
        ave: "",
        sourceName: "",
        url: "",
        sourceURL: "",
        imageURL: "",
        rssFeedTag: "",
        uniqueID: ""
    });
    const [modalShow, setModalShow] = useState(false); // For controlling the modal visibility
    const [modalMessage, setModalMessage] = useState(""); // For showing the message inside the modal

    const mediaOptions = {
        Broadcast: ["TV", "Radio", "Advert", "Live Read"],
        Print: ["Newspaper", "Magazine", "Journal", "Newsletter"],
        Social: ["Facebook", "Twitter", "Instagram", "LinkedIn"],
        Online: ["News Website", "Blogs", "Podcasts", "Streaming"]
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArticleData(prev => ({
            ...prev,
            [name]: value,
            ...(name === "sourceFeedType" && {
                sourceMediaOptions: mediaOptions[value] || [],
                sourceMediaType: ""  // Reset source media type when feed type changes
            })
        }));
    };
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setArticleData((prev) => ({
                ...prev,
                imageURL: reader.result // Store the base64 string of the image
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Generate the PDF
        const doc = new jsPDF();
        doc.setFontSize(22);
        doc.text(articleData.title, 20, 20); // Title of the article
        doc.setFontSize(12);
        doc.text(`By ${articleData.author}`, 20, 30); // Author

        // Insert the image if available
        if (articleData.imageURL) {
            doc.addImage(articleData.imageURL, 'JPEG', 20, 40, 150, 100); // Adjust size as needed
        }

        // Add content below the image
        doc.text(`Published Date: ${articleData.publishedDate}`, 20, 150);
        // doc.text(`Content: ${articleData.content}`, 20, 160);

        // Convert the PDF to Blob format
        const pdfBlob = doc.output('blob');

        // Create a FormData object to hold the PDF and article data
        const formData = new FormData();
        formData.append("pdf", pdfBlob, `${articleData.title}.pdf`);
        formData.append("articleData", JSON.stringify(articleData));

        // Automatically append the organization ID (from localStorage)
        const selectedOrg = localStorage.getItem("selectedOrg");
        formData.append("organizationId", selectedOrg); // Add the selected organization ID

        // Send the PDF and article data to the backend
        fetch("https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/uploadArticle", {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                setModalMessage('Article created successfully!'); // Set success message
                setModalShow(true); // Show the modal
            })
            .catch((error) => {
                setModalMessage('There was an error creating the article.'); // Set error message
                setModalShow(true); // Show the modal
                console.error("Error:", error);
            });
    };

    const formatRole = (role) => {
        switch (role) {
            case 'super_admin':
                return 'Super Admin';
            case 'org_admin':
                return 'Organizational Admin';
            case 'user':
                return 'User';
            default:
                return role;
        }
    };
    const userName = `${user.firstName} ${user.lastName}`;
    // const userRole = "Admin";

    return (
        <div>
            <div className="container">
                <Header userName={userName} userRole={formatRole(user.role)} />{" "}
            </div>
            <div className="content">
                <h2>Add Article</h2>
                <form className="form-container" onSubmit={handleSubmit}>
                    <label>
                        Published Date
                        <input type="date" name="publishedDate" value={articleData.publishedDate} onChange={handleChange} />
                    </label>
                    <label>
                        Harvested Date
                        <input type="date" name="harvestedDate" value={articleData.harvestedDate} onChange={handleChange} />
                    </label>
                    <label>
                        Title
                        <input type="text" name="title" value={articleData.title} onChange={handleChange} />
                    </label>
                    <label>
                        Author
                        <input type="text" name="author" value={articleData.author} onChange={handleChange} />
                    </label>
                    <label>
                        Extract
                        <textarea name="extract" value={articleData.extract} onChange={handleChange}></textarea>
                    </label>
                    <label>
                        Content
                        <textarea name="content" value={articleData.content} onChange={handleChange}></textarea>
                    </label>
                    <label>
                        Language
                        <select name="language" value={articleData.language} onChange={handleChange}>
                            <option value="English">English</option>
                            <option value="Setswana">Setswana</option>
                        </select>
                    </label>
                    <label>
                        Topic
                        <select name="media" value={articleData.topic} onChange={handleChange}>
                            <option value="Media">Media</option>
                            <option value="Competitors">Competitors</option>
                        </select>
                    </label>
                    <label>
                        Source Feed Type
                        <select name="sourceFeedType" value={articleData.sourceFeedType} onChange={handleChange}>
                            <option value="">Select Source Feed Type</option>
                            <option value="Broadcast">Broadcast</option>
                            <option value="Print">Print</option>
                            <option value="Social">Social</option>
                            <option value="Online">Online</option>
                        </select>
                    </label>
                    {articleData.sourceFeedType && (
                        <label>
                            Source Media Type
                            <select name="sourceMediaType" value={articleData.sourceMediaType} onChange={handleChange}>
                                <option value="">Select Source Media Type</option>
                                {articleData.sourceMediaOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </label>
                    )}
                    {/* <label>
                        Source Group
                        <input type="text" name="sourceGroup" value={articleData.sourceGroup} onChange={handleChange} />
                    </label> */}
                    <label>
                        Reach
                        <input type="text" name="reach" value={articleData.reach} onChange={handleChange} />
                    </label>
                    <label>
                        AVE
                        <input type="text" name="ave" value={articleData.ave} onChange={handleChange} />
                    </label>
                    <label>
                        Source Name
                        <input type="text" name="sourceName" value={articleData.sourceName} onChange={handleChange} />
                    </label>
                    <label>
                        URL
                        <input type="text" name="url" value={articleData.url} onChange={handleChange} />
                    </label>
                    <label>
                        Source URL
                        <input type="text" name="sourceURL" value={articleData.sourceURL} onChange={handleChange} />
                    </label>
                    <label>
                        Image URL
                        <input type="text" name="imageURL" value={articleData.imageURL} onChange={handleChange} />
                    </label>
                    <label>
                        RSS Feed Tag
                        <input type="text" name="rssFeedTag" value={articleData.rssFeedTag} onChange={handleChange} />
                    </label>
                    <label>
                        Unique ID
                        <input type="text" name="uniqueID" value={articleData.uniqueID} onChange={handleChange} />
                    </label>
                    <label>
                        Image Upload
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                    </label>
                    <button type="submit" className="save-button">
                        Save
                    </button>

                </form>
            </div>
            {/* Modal for showing success or error messages */}
            <Modal show={modalShow} onClose={() => setModalShow(false)}>
                <p>{modalMessage}</p>
            </Modal>
        </div>
    );
}

export default AddArticle;
