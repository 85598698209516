import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoBell } from "react-icons/go";
import { CgOrganisation } from "react-icons/cg";
import { AiOutlineUserSwitch } from "react-icons/ai";
import Modal from '../Components/Modal';
import axios from 'axios';
import "../CSS/Header.css";
import "../App.css";
import UserAvatar from "../Components/UserAvatar";
import { Toaster, toast } from "sonner";

function Header({ userName, userRole, onSearch }) { // onSearch passed as a prop
    const [showOrgSelect, setShowOrgSelect] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search
    const navigate = useNavigate();
    const { orgId } = useParams();

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const orgResponse = await axios.get('https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations');
                setOrganizations(orgResponse.data);
            } catch (error) {
                console.error('Error fetching organizations:', error);
                toast.error("Error fetching selected organization");
            }
        };
        fetchOrganizations();
    }, []);

    const handleOrgChange = (e) => {
        setSelectedOrg(e.target.value);
    };

    const toggleOrgSelect = () => {
        setShowOrgSelect(prev => !prev);
    };

    const handleContinue = () => {
        console.log(`You have selected: ${selectedOrg}`);
        localStorage.setItem('selectedOrg', selectedOrg);
        localStorage.removeItem('someOtherOrganizationSpecificData');
        setShowOrgSelect(false);
        navigate(`/dashboard/${selectedOrg}`);
    };

    const getInitials = (name) => {
        return name.split(' ').map(n => n[0]).join('').toUpperCase();
    };

    const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        onSearch(e.target.value); // Pass the search query to the parent
    };

    return (
        <div className="header-container">
            <div className="user-info">
                <UserAvatar userName={userName} />
                <div className="user-details">
                    <div className="user-deets">
                        <span className="user-name">{userName}</span>
                        <span className="user-role">{userRole}</span>
                    </div>
                    <span className="divider"></span>
                    <div className="search-input-container">
                        {/* <FiSearch className="search-icon" /> */}
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                    </div>
                </div>
            </div>

            <div className="right-container">
                <span className="divider"></span>
                {userRole === 'super_admin' && (
                    <div className="orgs" onClick={toggleOrgSelect} data-tooltip="Switch organizations">
                        <AiOutlineUserSwitch />
                    </div>
                )}
                <Modal show={showOrgSelect} onClose={() => setShowOrgSelect(false)}>
                    <h2>Switch Organization</h2>
                    <select onChange={handleOrgChange} value={selectedOrg} required>
                        <option value="">Choose organization</option>
                        {organizations.map(org => (
                            <option key={org._id} value={org._id}>{org.organizationName}</option>
                        ))}
                    </select>
                    <button className="continuebutt" onClick={handleContinue}>Continue</button>
                </Modal>
                <div className="notifications">
                    <GoBell />
                </div>
            </div>
        </div>
    );
}

export default Header;
