/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import "chart.js/auto";
import { Line, Pie } from "react-chartjs-2";
import {
  FaChartBar,
  FaRegCalendarAlt,
  FaKey,
  FaRegListAlt,
  FaEllipsisV,
} from "react-icons/fa";
import { LuCalendarDays } from "react-icons/lu";
import { Player } from "@lottiefiles/react-lottie-player";
import { IoInformationCircle } from "react-icons/io5";
import { TfiReload } from "react-icons/tfi";
import "../CSS/Dashboard.css";
import "../App.css";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { ImConfused } from "react-icons/im";
import { MdOutlineSentimentNeutral } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { Toaster, toast } from "sonner";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import Header from "../Components/Header";
import loadingAnimation from "../lottie/loading.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "../Components/ThemeContext";
import CountryModal from "../Components/CountryModal"; // Correct import path
import "../CSS/CountryModal.css";

Chart.register(ChartDataLabels);

function Dashboard() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedOrg = localStorage.getItem("selectedOrg");
  const { orgId } = useParams();
  const [facebookPosts, setFacebookPosts] = useState([]);
  const [scraping, setScraping] = useState(false);
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalPostsAndArticles, setTotalPostsAndArticles] = useState(0);
  const [articles, setArticles] = useState([]);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [monthlyArticles, setMonthlyArticles] = useState(0);
  // const [pieData, setPieData] = useState({});
  // const [monthlyPosts, setMonthlyPosts] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [articlesThisMonth, setArticlesThisMonth] = useState(0);
  const [editingSource, setEditingSource] = useState(null);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [editingSentiment, setEditingSentiment] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [keywordDistribution, setKeywordDistribution] = useState({});
  // const [sourceDistribution, setSourceDistribution] = useState({});
  const [printMediaArticles, setPrintMediaArticles] = useState([]);
  const [filteredPrintMediaArticles, setFilteredPrintMediaArticles] = useState(
    []
  );
  const [broadcastArticles, setBroadcastArticles] = useState([]);
  const [filteredBroadcastArticles, setFilteredBroadcastArticles] = useState(
    []
  );
  const { theme } = useContext(ThemeContext);
  const [totalTopics, setTotalTopics] = useState(0);
  const [infoTooltip, setInfoTooltip] = useState(null);
  const tooltipRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [editingCountry, setEditingCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sourceCountryMap, setSourceCountryMap] = useState({});
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);

  // Generate colors for chart sections
  const getGradient = (ctx, index) => {
    const gradient = ctx.createRadialGradient(100, 100, 50, 100, 100, 200);
    const hue = (index * 47) % 360;

    gradient.addColorStop(0, `hsl(${hue}, 90%, 40%)`); // Darker center
    gradient.addColorStop(1, `hsl(${hue}, 90%, 75%)`); // Lighter edge

    return gradient;
  };

  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 2,
      },
    ],
  });

  // Process keyword distribution
  useEffect(() => {
    if (articles.length > 0) {
      // Determine the selected month and year from the filter
      const selectedMonth = startDate
        ? new Date(startDate).getMonth()
        : new Date().getMonth();
      const selectedYear = startDate
        ? new Date(startDate).getFullYear()
        : new Date().getFullYear();

      const keywordMap = {};

      articles.forEach((article) => {
        const articleDate = new Date(article.publication_date);
        if (
          articleDate.getMonth() === selectedMonth &&
          articleDate.getFullYear() === selectedYear
        ) {
          article.matched_keywords.forEach((keyword) => {
            if (!keywordMap[keyword]) {
              keywordMap[keyword] = { count: 0, sources: new Set() };
            }
            keywordMap[keyword].count += 1;
            keywordMap[keyword].sources.add(article.source);
          });
        }
      });

      // Convert sources Set to an array
      Object.keys(keywordMap).forEach((key) => {
        keywordMap[key].sources = Array.from(keywordMap[key].sources);
      });

      setKeywordDistribution(keywordMap);
    }
  }, [articles, startDate]); // Update when articles or selected date changes

  //geolocation useeffect
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data
          .map((country) => country.name.common)
          .sort(); // Sort alphabetically
        setCountries([
          "Botswana",
          ...countryNames.filter((c) => c !== "Botswana"),
        ]); // Keep Botswana at top
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Pie chart data preparation
  useEffect(() => {
    if (Object.keys(keywordDistribution).length > 0) {
      const labels = Object.keys(keywordDistribution);
      const data = labels.map((key) => keywordDistribution[key].count);

      setPieData({
        labels,
        datasets: [
          {
            data,
            backgroundColor: (context) => {
              const chart = context.chart;
              const ctx = chart.ctx;
              return labels.map((_, index) => getGradient(ctx, index));
            },
            borderColor: "transparent",
            borderWidth: 2,
            borderRadius: 5,
            spacing: 5,
          },
        ],
      });
    } else {
      // Default placeholder data when no data is available
      setPieData({
        labels: ["No Data Available For This Month"], // Consistent placeholder label
        datasets: [
          {
            data: [1], // A small dummy value to prevent chart errors
            backgroundColor: ["#d3d3d3"],
            borderColor: "transparent",
            borderWidth: 2,
          },
        ],
      });
    }
  }, [keywordDistribution]);

  // Pie chart options
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: theme === "light" ? "#7a7a7a" : "#fff",
          font: {
            family: "Raleway",
            size: 13,
          },
          padding: 15,
        },
      },
      tooltip: {
        enabled: pieData.labels[0] !== "No Data Available For This Month", // Disable tooltip for placeholder
        callbacks: {
          label: (tooltipItem) => {
            if (pieData.labels[0] === "No Data Available For This Month")
              return null; // Prevent showing tooltip
            const keyword = tooltipItem.label;
            const count = keywordDistribution[keyword]?.count;
            const sources = keywordDistribution[keyword]?.sources.join(", ");
            return `${keyword}: ${count} (Sources: ${sources})`;
          },
        },
      },
      datalabels: {
        display: pieData.labels[0] !== "No Data Available For This Month", // Hide labels for placeholder
        color: theme === "light" ? "#fff" : "#fff",
        font: { weight: "bold", size: 12, family: "Raleway" },
        formatter: (value) => `${value}`,
        anchor: "end",
        align: "start",
        offset: 15,
      },
    },
    cutout: "50%", // Donut shape
  };

  // Generate dataset for the current year
  const currentYear = new Date().getFullYear();
  const monthLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Initialize arrays to hold counts for each category
  const onlineArticlesByMonth = new Array(12).fill(0);
  const socialMediaPostsByMonth = new Array(12).fill(0);
  const broadcastArticlesByMonth = new Array(12).fill(0);
  const printArticlesByMonth = new Array(12).fill(0);

  // Count the number of articles/posts per month
  articles.forEach((article) => {
    const articleDate = new Date(article.publication_date);
    if (articleDate.getFullYear() === currentYear) {
      onlineArticlesByMonth[articleDate.getMonth()] += 1;
    }
  });

  facebookPosts.forEach((post) => {
    const postDate = new Date(post.createdTime);
    if (postDate.getFullYear() === currentYear) {
      socialMediaPostsByMonth[postDate.getMonth()] += 1;
    }
  });

  broadcastArticles.forEach((article) => {
    const articleDate = new Date(article.mentionDT);
    if (articleDate.getFullYear() === currentYear) {
      broadcastArticlesByMonth[articleDate.getMonth()] += 1;
    }
  });

  printMediaArticles.forEach((article) => {
    const articleDate = new Date(article.publicationDate);
    if (articleDate.getFullYear() === currentYear) {
      printArticlesByMonth[articleDate.getMonth()] += 1;
    }
  });

  // Create the dataset for the line graph
  const lineDatasets = [
    {
      label: "Online Articles",
      data: onlineArticlesByMonth,
      borderColor: "rgba(54, 162, 235, 1)", // Blue
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderWidth: 2,
      tension: 0.4, // Smooth curve
      pointRadius: 3,
      pointHoverRadius: 7,
      fill: true,
    },
    {
      label: "Social Media Posts",
      data: socialMediaPostsByMonth,
      borderColor: "rgba(255, 99, 132, 1)", // Red
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 3,
      pointHoverRadius: 7,
      fill: true,
    },
    {
      label: "Broadcast Articles",
      data: broadcastArticlesByMonth,
      borderColor: "rgba(75, 192, 192, 1)", // Green
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 3,
      pointHoverRadius: 7,
      fill: true,
    },
    {
      label: "Print Media Articles",
      data: printArticlesByMonth,
      borderColor: "rgba(255, 206, 86, 1)", // Yellow
      backgroundColor: "rgba(255, 206, 86, 0.2)",
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 3,
      pointHoverRadius: 7,
      fill: true,
    },
  ];

  const lineData = {
    labels: monthLabels,
    datasets: lineDatasets,
  };

  const lineOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 200, // Stretch y-axis
        stepSize: 200,
        grid: { color: "rgba(200, 200, 200, 0.2)" },
        ticks: {
          font: { family: "Raleway" },
          color: theme === "light" ? "#7a7a7a" : "#fff",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // Forces circle style
          font: { family: "Raleway", size: 13 },
          color: theme === "light" ? "#7a7a7a" : "#fff",
          paddingBottom: 25,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} Articles`,
        },
      },
      datalabels: { display: false },
    },
    animation: { duration: 1000 },
  };

  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchOrgData = async () => {
      if (hasFetched.current) return; // Prevent duplicate fetches

      try {
        const orgId =
          user.role === "super_admin"
            ? selectedOrg || localStorage.getItem("selectedOrg")
            : user.organizationId;

        if (!orgId) {
          throw new Error("No organization selected.");
        }

        const response = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const orgData = response.data;
        setOrganizationData(orgData);

        // Calculate the total number of keywords from the keywords array
        const totalKeywordsCount = orgData.organization.keywords?.length || 0;
        setTotalKeywords(totalKeywordsCount); // Update state

        hasFetched.current = true; // Prevent further fetch attempts
      } catch (error) {
        console.error("Error fetching organization data:", error.message);
        setError("Failed to load organization data.");
        toast.error("Failed to fetch organization data. Please try again.");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (user && (user.role === "super_admin" || user.role === "org_admin")) {
      fetchOrgData();
    }
  }, []);

  // Fetch articles for the organization and set displayedArticles
  const fetchArticles = async () => {
    setLoading(true); // Start loading spinner
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`
      );
      const articlesData = response.data.articles || [];

      const updatedArticles = articlesData.map((article) => ({
        ...article,
        country: sourceCountryMap[article.source] || article.country, // Apply mapped country
      }));

      setArticles(updatedArticles); // Store all articles
      setDisplayedArticles(updatedArticles.slice(0, 8)); // Display the first 8 articles
      setTotalArticles(updatedArticles.length);

      // Calculate articles for the current month
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const monthlyCount = updatedArticles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return (
          articleDate.getMonth() === currentMonth &&
          articleDate.getFullYear() === currentYear
        );
      }).length;

      setMonthlyArticles(monthlyCount); // Update state for articles in the current month
    } catch (error) {
      console.error("Error fetching articles:", error);
      toast.error("Failed to load articles");
      setError("Failed to load articles");
    } finally {
      setLoading(false); // Stop loading spinner after fetch
    }
  };

  // Use fetchArticles inside useEffect
  useEffect(() => {
    if (selectedOrg) fetchArticles();
  }, [selectedOrg, sourceCountryMap]);

  // Fetch Broadcast articles for the selected organization
  const fetchBroadcastArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/broadcastMedia`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const broadcastData = response.data; // Use the entire response, not .articles
      console.log("Broadcast Articles:", broadcastData); // Debugging
      setBroadcastArticles(broadcastData);
      setFilteredBroadcastArticles(broadcastData.slice(0, 8));
    } catch (error) {
      console.error("Error fetching broadcast articles:", error);
      toast.error("Failed to load broadcast articles");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchBroadcastArticles(); // Fetch broadcast articles on organization change
    }
  }, [selectedOrg]);

  // Fetch Print articles for the selected organization
  const fetchPrintMediaArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/api/organizations/${selectedOrg}/printMedia`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const printData = response.data; // Use the entire response, not .articles
      console.log("Print Media Articles:", printData); // Debugging
      setPrintMediaArticles(printData);
      setFilteredPrintMediaArticles(printData.slice(0, 8));
    } catch (error) {
      console.error("Error fetching print media articles:", error);
      toast.error("Failed to load print media articles");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchPrintMediaArticles(); // Fetch broadcast articles on organization change
    }
  }, [selectedOrg]);

  // Fetch Facebook posts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgUrl = `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`;
        const [orgResponse, articlesResponse, postsResponse] =
          await Promise.all([
            axios.get(orgUrl),
            axios.get(`${orgUrl}/articles`),
            axios.get(`${orgUrl}/posts`),
          ]);

        setOrganizationData(orgResponse.data);
        setArticles(articlesResponse.data.articles || []);
        setFacebookPosts(postsResponse.data.posts || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) fetchData();
  }, [selectedOrg, navigate]); //if you run into problems later, remember to just put back the dependencies, we're only doing this for chrome not to crash

  // Fetch organization data and articles/posts in parallel
  useEffect(() => {
    async function fetchData() {
      try {
        const orgUrl = `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}`;
        const articlesUrl = `${orgUrl}/articles`;
        const postsUrl = `${orgUrl}/posts`;

        // Fetching data in parallel
        const [orgResponse, articlesResponse, postsResponse] =
          await Promise.all([
            axios.get(orgUrl),
            axios.get(articlesUrl),
            axios.get(postsUrl),
          ]);

        // Setting organization data
        setOrganizationData(orgResponse.data);

        // Handling articles
        const articlesData = articlesResponse.data.articles || [];
        setArticles(articlesData);
        setDisplayedArticles(articlesData.slice(0, 8)); // Display the first 8 articles
        setTotalArticles(articlesData.length);

        // Calculate articles this month
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const monthlyArticlesCount = articlesData.filter((article) => {
          const articleDate = new Date(article.publication_date);
          return (
            articleDate.getMonth() === currentMonth &&
            articleDate.getFullYear() === currentYear
          );
        });
        setArticlesThisMonth(monthlyArticlesCount.length);

        // Handling posts
        setFacebookPosts(postsResponse.data.posts);
        setTotalPosts(postsResponse.data.posts.length);

        // ✅ Calculate Total Topics
        const activeTopics = [
          articlesData.length > 0 ? "Online Articles" : null,
          postsResponse.data.posts.length > 0 ? "Social Media" : null,
          broadcastArticles.length > 0 ? "Broadcast Media" : null,
          printMediaArticles.length > 0 ? "Print Media" : null,
        ].filter(Boolean); // Removes null values

        setTotalTopics(activeTopics.length); // Set total topics count
      } catch (error) {
        setError("Failed to load data");
        console.error("Error fetching data:", error);
        navigate("/login");
      } finally {
        setLoading(false);
      }
    }

    if (selectedOrg) fetchData();
  }, [selectedOrg, broadcastArticles, printMediaArticles, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editingSentiment && !event.target.closest(".sentiment-popup")) {
        setEditingSentiment(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [editingSentiment]);

  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query state

    const lowerCaseQuery = query.toLowerCase();

    setFilteredArticles(
      articles.filter(
        (article) =>
          article.title.toLowerCase().includes(lowerCaseQuery) ||
          article.source.toLowerCase().includes(lowerCaseQuery)
      )
    );

    setFilteredPosts(
      facebookPosts.filter((post) =>
        post.message?.toLowerCase().includes(lowerCaseQuery)
      )
    );

    // Filter broadcast articles
    setFilteredBroadcastArticles(
      broadcastArticles.filter(
        (article) =>
          article.station.toLowerCase().includes(lowerCaseQuery) ||
          article.mention.toLowerCase().includes(lowerCaseQuery)
      )
    );

    // Filter print media articles
    setFilteredPrintMediaArticles(
      printMediaArticles.filter(
        (article) =>
          article.publication.toLowerCase().includes(lowerCaseQuery) ||
          article.headline.toLowerCase().includes(lowerCaseQuery)
      )
    );
  };

  // Fetch articles, Facebook posts, and update counts
  useEffect(() => {
    const fetchArticlesAndPosts = async () => {
      setLoading(true);
      try {
        const articlesResponse = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`
        );
        const postsResponse = await axios.get(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/posts`
        );

        const articlesData = articlesResponse.data.articles || [];
        const postsData = postsResponse.data.posts || [];

        setArticles(articlesData); // Store all articles
        setFacebookPosts(postsData); // Store all posts

        // Initialize filtered arrays with full data
        setFilteredArticles(articlesData);
        setFilteredPosts(postsData);

        setTotalArticles(articlesData.length);
        setTotalPosts(postsData.length);
        setTotalPostsAndArticles(articlesData.length + postsData.length);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) fetchArticlesAndPosts();
  }, [selectedOrg]);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = articles.filter((article) => {
        const articleDate = new Date(article.publication_date);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles); // Reset to show all articles
    }
  }, [startDate, endDate, articles]);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = broadcastArticles.filter((article) => {
        const articleDate = new Date(article.mentionDT);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setFilteredBroadcastArticles(filtered);
    } else {
      setFilteredBroadcastArticles(broadcastArticles);
    }
  }, [startDate, endDate, broadcastArticles]);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = printMediaArticles.filter((article) => {
        const articleDate = new Date(article.publicationDate);
        return articleDate >= startDate && articleDate <= endDate;
      });
      setPrintMediaArticles(filtered);
    } else {
      setFilteredPrintMediaArticles(printMediaArticles);
    }
  }, [startDate, endDate, printMediaArticles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
        !event.target.closest(".react-datepicker") &&
        !event.target.closest(".date-container")
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isCalendarOpen]);

  // Render loading spinner if data is loading
  if (loading)
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );

  // Handle delete article
  const handleDelete = async (id, type) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        let endpoint = "";

        switch (type) {
          case "articles":
            endpoint = `organizations/${selectedOrg}/articles/${id}`;
            break;
          case "posts":
            endpoint = `organizations/${selectedOrg}/posts/${id}`;
            break;
          case "broadcasts":
            endpoint = `organizations/${selectedOrg}/broadcastMedia/${id}`;
            break;
          case "printmedias":
            endpoint = `organizations/${selectedOrg}/printMedia/${id}`;
            break;
          default:
            console.error("Invalid type for deletion:", type);
            return;
        }

        const response = await axios.delete(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Article deleted successfully");

          // Remove the deleted item from the corresponding state
          if (type === "articles") {
            setArticles((prev) => prev.filter((item) => item._id !== id));
            setFilteredArticles((prev) =>
              prev.filter((item) => item._id !== id)
            );
          } else if (type === "posts") {
            setFacebookPosts((prev) => prev.filter((item) => item._id !== id));
            setFilteredPosts((prev) => prev.filter((item) => item._id !== id));
          } else if (type === "broadcasts") {
            setBroadcastArticles((prev) =>
              prev.filter((item) => item._id !== id)
            );
            setFilteredBroadcastArticles((prev) =>
              prev.filter((item) => item._id !== id)
            );
          } else if (type === "printmedias") {
            setPrintMediaArticles((prev) =>
              prev.filter((item) => item._id !== id)
            );
            setFilteredPrintMediaArticles((prev) =>
              prev.filter((item) => item._id !== id)
            );
          }
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        toast.error("Failed to delete article");
      }
    }
  };

  const handleScrape = async () => {
    setScraping(true); // Show overlay
    try {
      await axios.get(`http://54.163.230.129:5001/scrape`, {
        params: {
          organizationName: organizationData?.organization.organizationName,
        },
      });

      toast.success("Scraping completed!");

      // Fetch the latest articles after scraping
      const response = await axios.get(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles`
      );

      const newArticles = response.data.articles || [];

      setArticles(newArticles); // Update articles in real-time
      setFilteredArticles(newArticles); // Update filtered articles if a search is applied
      setDisplayedArticles(newArticles.slice(0, 8)); // Update displayed articles

      // Optionally update the count or other related states
      setTotalArticles(newArticles.length);
    } catch (error) {
      toast.error("Scraping failed. Please try again.");
      console.error("Error during scraping or fetching new articles:", error);
    } finally {
      setScraping(false); // Hide overlay
    }
  };

  // edit source name on articles table
  const handleSourceEdit = (articleId, currentSource) => {
    setEditingSource({ articleId, value: currentSource });
  };

  const handleSourceChange = (e) => {
    setEditingSource((prev) => ({ ...prev, value: e.target.value }));
  };

  const confirmSourceUpdate = async (articleId, newSource) => {
    if (
      window.confirm(
        `Are you sure you want to change the source to "${newSource}"?`
      )
    ) {
      try {
        const response = await axios.put(
          `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}`,
          { newSource },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Update the articles in local state
          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in articles
                : article
            )
          );

          // Update the displayedArticles state
          setDisplayedArticles((prevDisplayedArticles) =>
            prevDisplayedArticles.map((article) =>
              article._id === articleId
                ? { ...article, source: newSource } // Update source in displayedArticles
                : article
            )
          );

          toast.success("Source updated successfully");
          setEditingSource(null); // Close the input box
        }
      } catch (error) {
        console.error("Error updating source:", error);
        toast.error("Failed to update source");
      }
    }
  };

  const handleKeyPress = (e, articleId, newSource) => {
    if (e.key === "Enter") {
      confirmSourceUpdate(articleId, newSource);
    }
  };

  const handleBlur = () => {
    setEditingSource(null); // Close editing box when clicking away
  };

  const handleMenuClick = (table, index) => {
    const menuId = `${table}-menu-${index}`;
    document.getElementById(menuId).style.display = "block";
  };

  const handleMenuClose = (table, index) => {
    const menuId = `${table}-menu-${index}`;
    document.getElementById(menuId).style.display = "none";
  };

  // Map numerical sentiment score from MongoDB to label
  const mapSentimentToLabel = (score) => {
    if (score >= 0.75) return "positive"; // Allow for slight variations
    if (score <= -0.5) return "negative";
    if (score > 0 && score < 0.5) return "mixed"; // Catch small positive values
    if (score === 0) return "neutral";

    console.warn(`Unknown sentiment score received: ${score}`);
    return "neutral"; // Fallback to neutral
  };

  // Map sentiment label to numerical score for MongoDB
  const mapLabelToSentiment = (label) => {
    const lowerLabel = label.toLowerCase(); // Normalize for consistency

    if (lowerLabel === "positive") return 1;
    if (lowerLabel === "negative") return -1;
    if (lowerLabel === "mixed") return 0.25;
    if (lowerLabel === "neutral") return 0;

    console.warn(`Unknown sentiment label received: ${label}`);
    return 0; // Fallback to neutral
  };

  // Handle change in sentiment popup
  const handleSentimentChange = (e) => {
    const newSentiment = e.target.value;
    console.log("User selected sentiment:", newSentiment);

    setEditingSentiment((prev) => ({
      ...prev,
      value: newSentiment, // Keep it as a string
    }));
  };

  const confirmSentimentUpdate = async (articleId, label) => {
    try {
      console.log(
        `Attempting to update article ${articleId} with sentiment:`,
        label
      );

      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}/sentiment`,
        { sentiment: label },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Updated article from backend:", response.data);

        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article._id === articleId
              ? { ...article, sentiment: response.data.sentiment } // Use the actual updated sentiment
              : article
          )
        );

        toast.success("Sentiment updated successfully");
      } else {
        throw new Error("Failed to update sentiment");
      }
    } catch (error) {
      console.error("Error updating sentiment:", error);
      toast.error("Failed to update sentiment");
    } finally {
      setEditingSentiment(null);
    }
  };

  // Exit editing mode without saving changes
  const handleSentimentCancel = () => {
    setEditingSentiment(null);
  };

  // Trigger editing mode
  const handleSentimentEdit = (articleId, currentLabel) => {
    setEditingSentiment({ articleId, value: currentLabel });
  };

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const generateLogoUrl = (platform) => {
    const socialMediaMapping = {
      Facebook: "facebook.com",
      Twitter: "twitter.com",
      Instagram: "instagram.com",
      LinkedIn: "linkedin.com",
      YouTube: "youtube.com",
      Other: "generic.com",
    };

    const domain = socialMediaMapping[platform] || socialMediaMapping["Other"];
    return `https://img.logo.dev/${domain}?token=pk_TR6h_8JIQjaZVVtiC3TeJA`;
  };

  const confirmCountryUpdate = async (articleId) => {
    if (!selectedCountry || !editingCountry) return;

    try {
      const response = await axios.put(
        `https://sociallightbw-backend-34f7586fa57c.herokuapp.com/organizations/${selectedOrg}/articles/${articleId}/country`,
        { country: selectedCountry },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setArticles((prevArticles) =>
          prevArticles.map((article) =>
            article._id === articleId
              ? { ...article, country: selectedCountry }
              : article
          )
        );
        toast.success("Country updated successfully!");
        setIsCountryModalOpen(false); // Close modal after updating
      } else {
        throw new Error("Failed to update country in MongoDB");
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("Failed to update country.");
    } finally {
      setEditingCountry(null);
    }
  };

  const handleCountryEdit = (articleId, currentCountry) => {
    setEditingCountry(articleId);
    setSelectedCountry(currentCountry || "Botswana");
    setCurrentArticleId(articleId);
    setIsCountryModalOpen(true);
  };

  return (
    <div>
      {scraping && (
        <div className="loading-overlay">
          {/* Replace spinner with Lottie animation */}
          <Player
            autoplay
            loop
            src={loadingAnimation}
            style={{ height: "220px", width: "220px" }}
          />
        </div>
      )}

      <CountryModal
        isOpen={isCountryModalOpen}
        onClose={() => setIsCountryModalOpen(false)}
      >
        <h2 style={{ fontFamily: "Raleway" }}>Edit Country</h2>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <button
          onClick={() => confirmCountryUpdate(currentArticleId)}
          className="modalSave"
        >
          Save Changes
        </button>
      </CountryModal>

      <div className="container">
        <Header
          userName={`${user.firstName} ${user.lastName}`}
          userRole={user.role}
          onSearch={handleSearch}
        />
      </div>
      <div className="content">
        <h2>{organizationData?.organization?.alias}'s Dashboard</h2>
        <div className="holder">
          {/* Card Section */}
          <div className="grid">
            <div className="card">
              <FaChartBar className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() =>
                  setInfoTooltip("totalArticles")
                } /* Show tooltip */
                onMouseLeave={() => setInfoTooltip(null)} /* Hide tooltip */
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalArticles" && (
                  <div className="tooltips">
                    Total number of online articles found over time.
                  </div>
                )}
              </div>
              <h4>Total Online Articles Overtime</h4>
              <p>{totalArticles}</p>
            </div>

            <div className="card">
              <FaRegCalendarAlt className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("monthlyArticles")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "monthlyArticles" && (
                  <div className="tooltips">
                    Number of online articles found this month.
                  </div>
                )}
              </div>
              <h4>Total Online Articles this Month</h4>
              <p>{monthlyArticles}</p>
            </div>

            <div className="card">
              <FaKey className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("totalKeywords")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalKeywords" && (
                  <div className="tooltips">
                    Total number of keyphrases matched across articles.
                  </div>
                )}
              </div>
              <h4>Total Keyphrases</h4>
              <p>{totalKeywords}</p>
            </div>

            <div className="card">
              <FaRegListAlt className="card-icon" />
              <div
                className="tooltip-container"
                onMouseEnter={() => setInfoTooltip("totalTopics")}
                onMouseLeave={() => setInfoTooltip(null)}
              >
                <IoInformationCircle className="tool-icon" />
                {infoTooltip === "totalTopics" && (
                  <div className="tooltips">
                    The number of different media types where this organization
                    was mentioned.
                  </div>
                )}
              </div>
              <h4>Total Media Types</h4>
              <p>{totalTopics}</p>
            </div>
          </div>

          {/* Chart Section */}
          <div className="charts">
            <div className="piechart">
              <h3>This Month's Top Keyword Trends</h3>
              <Pie data={pieData} options={pieOptions} />
            </div>
            <div className="chart-container">
              <h3>
                Aggregated Media Mentions Across Platforms ({currentYear})
              </h3>
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>

          <div className="newsArea">
            <h3 className="underline" style={{ fontSize: "25px" }}>
              Latest News
            </h3>

            <div className="table-controls">
              <div
                className="date-container"
                onClick={() => setIsCalendarOpen(true)}
              >
                <LuCalendarDays className="calendar-icon" />
                <span className="user-date">
                  {startDate && endDate
                    ? `${startDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })} - ${endDate.toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                      })}`
                    : "Select Date"}
                </span>

                {/* Clear button */}
                {(startDate || endDate) && (
                  <button
                    className="clear-dates-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the calendar toggle
                      setStartDate(null);
                      setEndDate(null);
                      setFilteredArticles(articles); // Reset the filter
                    }}
                  >
                    ✕
                  </button>
                )}

                {isCalendarOpen && (
                  <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);

                        // Close calendar when both dates are selected
                        if (start && end) {
                          setIsCalendarOpen(false);
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      showYearDropdown
                      scrollableYearDropdown
                      // showMonthDropdown
                    />
                  </div>
                )}
              </div>

              {/* Reload button for super_admin */}
              {user.role === "super_admin" && (
                <div className="reload-button-container">
                  <button
                    className={`reload-button ${
                      scraping ? "scraping" : "active"
                    }`}
                    onClick={handleScrape}
                    disabled={scraping}
                  >
                    {scraping ? (
                      "Scraping..."
                    ) : (
                      <>
                        <TfiReload />
                        Reload Articles
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>

            {/* Table Section for Online Media Articles */}
            <h3 style={{ fontSize: "16px" }}>Online Articles</h3>
            <div className="section">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <div className="table-container">
                  <table className="articles-table">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>Title</th>
                        <th>Summary</th>
                        <th>Date Published</th>
                        <th>Country</th>
                        <th>Sentiment</th>
                        <th>AVE</th>
                        {/* <th>Rank</th> */}
                        <th>Relevancy Score</th>
                        <th>Reach</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredArticles.length === 0 ? (
                        <tr>
                          <td colSpan="9" style={{ textAlign: "center" }}>
                            No articles found for this organization.
                          </td>
                        </tr>
                      ) : (
                        [...filteredArticles] // Make a copy of the array to avoid mutating the original state
                          .sort(
                            (a, b) =>
                              new Date(b.publication_date) -
                              new Date(a.publication_date)
                          ) // Sort by publication_date (latest first)
                          .slice(0, 8) // Show the first 8 articles
                          .map((article, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  className="table-source"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    gap: "8px",
                                  }}
                                >
                                  {article.logo_url && (
                                    <img
                                      className="logo-img"
                                      src={article.logo_url}
                                      alt={`${article.source} logo`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid",
                                        padding: "1px",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      cursor:
                                        user.role === "super_admin"
                                          ? "pointer"
                                          : "default",
                                    }}
                                    title={
                                      user.role === "super_admin"
                                        ? "Click to edit source"
                                        : ""
                                    }
                                    onClick={() =>
                                      user.role === "super_admin" &&
                                      handleSourceEdit(
                                        article._id,
                                        article.source
                                      )
                                    }
                                  >
                                    {editingSource &&
                                    editingSource.articleId === article._id ? (
                                      <input
                                        type="text"
                                        className="sourceInput"
                                        value={editingSource.value}
                                        onChange={handleSourceChange}
                                        onKeyDown={(e) =>
                                          handleKeyPress(
                                            e,
                                            article._id,
                                            editingSource.value
                                          )
                                        }
                                        onBlur={handleBlur}
                                        autoFocus
                                      />
                                    ) : (
                                      article.source
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="url">
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.title} ⤴
                                </a>
                              </td>
                              <td className="snippet">
                                "{article.snippet || article.title}"
                              </td>
                              <td style={{ fontWeight: "500" }}>
                                {new Date(
                                  article.publication_date
                                ).toLocaleDateString()}
                              </td>
                              <td
                                className="source_country"
                                onClick={() => {
                                  if (user.role === "super_admin") {
                                    handleCountryEdit(
                                      article._id,
                                      article.country
                                    );
                                  }
                                }}
                                title={
                                  user.role === "super_admin"
                                    ? "Click to change country"
                                    : ""
                                }
                                style={{
                                  cursor:
                                    user.role === "super_admin"
                                      ? "pointer"
                                      : "default",
                                }}
                              >
                                {article.country || "Unknown"}
                              </td>

                              <td>
                                {editingSentiment &&
                                editingSentiment.articleId === article._id ? (
                                  user.role === "super_admin" ? (
                                    <div className="sentiment-popup">
                                      <h4>Modify Sentiment</h4>
                                      <form>
                                        {/* Radio buttons for sentiment selection */}
                                        <label>
                                          <input
                                            type="radio"
                                            value="positive"
                                            checked={
                                              editingSentiment.value ===
                                              "positive"
                                            }
                                            onChange={handleSentimentChange}
                                          />
                                          Positive
                                        </label>
                                        <label>
                                          <input
                                            type="radio"
                                            value="neutral"
                                            checked={
                                              editingSentiment.value ===
                                              "neutral"
                                            }
                                            onChange={handleSentimentChange}
                                          />
                                          Neutral
                                        </label>
                                        <label>
                                          <input
                                            type="radio"
                                            value="negative"
                                            checked={
                                              editingSentiment.value ===
                                              "negative"
                                            }
                                            onChange={handleSentimentChange}
                                          />
                                          Negative
                                        </label>
                                        <label>
                                          <input
                                            type="radio"
                                            value="mixed"
                                            checked={
                                              editingSentiment.value === "mixed"
                                            }
                                            onChange={handleSentimentChange}
                                          />
                                          Mixed
                                        </label>
                                        {/* Action buttons */}
                                        <div className="popup-actions">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              confirmSentimentUpdate(
                                                article._id,
                                                editingSentiment.value
                                              )
                                            }
                                          >
                                            Confirm
                                          </button>
                                          <button
                                            type="button"
                                            onClick={handleSentimentCancel}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  ) : (
                                    <span className="sentiment-text">
                                      {mapSentimentToLabel(article.sentiment)}{" "}
                                      {/* Just display the sentiment */}
                                    </span>
                                  )
                                ) : (
                                  <div
                                    className={`sentiment-container sentiment ${mapSentimentToLabel(
                                      article.sentiment
                                    )}`}
                                    title={
                                      user.role === "super_admin"
                                        ? "Click to change sentiment"
                                        : ""
                                    }
                                    onClick={() =>
                                      user.role === "super_admin" &&
                                      handleSentimentEdit(
                                        article._id,
                                        mapSentimentToLabel(article.sentiment)
                                      )
                                    }
                                    style={{
                                      cursor:
                                        user.role === "super_admin"
                                          ? "pointer"
                                          : "default",
                                    }}
                                  >
                                    <div className="sentiment-icon-container">
                                      {mapSentimentToLabel(
                                        article.sentiment
                                      ) === "positive" ? (
                                        <LuThumbsUp className="sentiment-icon" />
                                      ) : mapSentimentToLabel(
                                          article.sentiment
                                        ) === "negative" ? (
                                        <LuThumbsDown className="sentiment-icon" />
                                      ) : mapSentimentToLabel(
                                          article.sentiment
                                        ) === "mixed" ? (
                                        <ImConfused className="sentiment-icon" />
                                      ) : (
                                        <MdOutlineSentimentNeutral className="sentiment-icon" />
                                      )}
                                    </div>
                                    <span className="sentiment-text">
                                      {mapSentimentToLabel(article.sentiment)}
                                    </span>
                                  </div>
                                )}
                              </td>
                              {/* <td style={{ color: "#07398c", fontWeight: "500" }}>
                            </td> */}
                              <td style={{ fontWeight: "500" }}>
                                {article.ave
                                  ? article.ave.toFixed(2) + " BWP"
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {article.rank}/5
                              </td>
                              <td
                                style={{
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {article.reach}
                              </td>
                              <td>
                                {/* Action buttons */}
                                <div className="icon-menu">
                                  <button
                                    className="icon-button"
                                    onClick={() =>
                                      handleMenuClick("online-media", index)
                                    }
                                  >
                                    <FaEllipsisV />
                                  </button>
                                  <div
                                    id={`online-media-menu-${index}`}
                                    className="menu-options"
                                    onMouseLeave={() =>
                                      handleMenuClose("online-media", index)
                                    }
                                  >
                                    <a href={article.url} download>
                                      Download
                                    </a>
                                    <a
                                      href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                    >
                                      Send Article
                                    </a>
                                    {user.role === "super_admin" && (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDelete(article._id, "articles");
                                        }}
                                        className="delete-link"
                                      >
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div style={{ textAlign: "center", marginTop: "25px" }}>
                <Link to={`/media/online/${orgId}`} className="see-more-button">
                  More <FaAngleDown className="more-icon" />
                </Link>
              </div>
            </div>

            {/* Table Section for Social Media Posts */}
            <h3 style={{ fontSize: "16px", marginTop: "50px" }}>
              Social Media
            </h3>
            <div className="section">
              <div className="table-container">
                <table className="articles-table">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Consumer Group</th>
                      <th>Date Posted</th>
                      <th>Country</th>
                      <th>Sentiment</th>
                      <th>AVE</th>
                      <th>Relevancy Score</th>
                      <th>Reach</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPosts.length === 0 ? (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          No posts found yet for this organization.
                        </td>
                      </tr>
                    ) : (
                      [...filteredPosts] // Make a copy of the array to avoid mutating the original state
                        .sort(
                          (a, b) =>
                            new Date(b.createdTime) - new Date(a.createdTime)
                        ) // Sort by publication_date (latest first)
                        .slice(0, 8) // Show the first 8 articles
                        .map((post, index) => (
                          <tr key={`fb-post-${index}`}>
                            <td style={{ fontWeight: "600" }}>
                              <div
                                className="table-source"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <img
                                  src={generateLogoUrl("Facebook")}
                                  alt="Facebook logo"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    border: "0.5px solid",
                                    padding: "1px",
                                  }}
                                />
                                Facebook
                              </div>
                            </td>
                            <td
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "13px",
                                textTransform: "capitalize",
                              }}
                            >
                              <a
                                href={`https://facebook.com/${post.postId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {post.message && post.message.length > 50
                                  ? `${post.message.substring(0, 50)}...`
                                  : post.message || "-"}{" "}
                                ⤴
                              </a>
                            </td>
                            <td>
                              <div className="station-type">
                                {post.pageName || "-"}
                              </div>
                            </td>
                            <td>{post.group ? post.group : "none"}</td>
                            <td
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {new Date(post.createdTime).toLocaleDateString()}
                            </td>
                            <td>{post.country}</td>
                            <td>
                              {/* Display sentiment icon with text on the right side */}
                              <div className="sentiment-container">
                                {post.sentiment > 0 ? (
                                  <div className="sentiment positive">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsUp className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Positive
                                    </span>
                                  </div>
                                ) : post.sentiment < 0 ? (
                                  <div className="sentiment negative">
                                    <div className="sentiment-icon-container">
                                      <LuThumbsDown className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Negative
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sentiment neutral">
                                    <div className="sentiment-icon-container">
                                      <MdOutlineSentimentNeutral className="sentiment-icon" />
                                    </div>
                                    <span className="sentiment-text">
                                      Neutral
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {post.ave ? `${post.ave.toFixed(2)} BWP` : "-"}
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {post.rank
                                ? `${parseFloat(post.rank).toFixed(1)}/5`
                                : "-"}
                            </td>
                            <td
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {post.reach ? post.reach : "-"}
                            </td>
                            <td>
                              <div className="icon-menu">
                                <button
                                  className="icon-button"
                                  onClick={() =>
                                    handleMenuClick("social-media", index)
                                  }
                                >
                                  <FaEllipsisV />
                                </button>
                                <div
                                  id={`social-media-menu-${index}`}
                                  className="menu-options"
                                  onMouseLeave={() =>
                                    handleMenuClose("social-media", index)
                                  }
                                >
                                  <a
                                    href={
                                      post.link ||
                                      `https://facebook.com/${post.postId}`
                                    }
                                    download
                                  >
                                    Download
                                  </a>
                                  <a
                                    href={`mailto:?subject=Interesting Post&body=${
                                      post.link ||
                                      `https://facebook.com/${post.postId}`
                                    }`}
                                  >
                                    Send Post
                                  </a>
                                  {user.role === "super_admin" && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(post._id, "posts");
                                      }}
                                      className="delete-link"
                                    >
                                      Delete
                                    </a>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Link to="/media/social/:orgId" className="see-more-button">
                  More <FaAngleDown className="more-icon" />
                </Link>
              </div>
            </div>

            {/* Table Section for Broadcast Media articles */}
            <h3 style={{ fontSize: "16px" }}>Broadcast Media Articles</h3>
            <div className="section">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <div className="table-container">
                  <table className="articles-table">
                    <thead>
                      <tr>
                        <th> Source</th>
                        <th>Summary</th>
                        <th>URL</th>
                        <th>Station Type</th>
                        <th>Country</th>
                        <th>Date Posted</th>
                        <th>Sentiment</th>
                        <th>AVE</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBroadcastArticles.length === 0 ? (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            No broadcast articles found yet for this
                            organization.
                          </td>
                        </tr>
                      ) : (
                        [...filteredBroadcastArticles] // Make a copy of the array to avoid mutation
                          .sort(
                            (a, b) =>
                              new Date(b.mentionDT) - new Date(a.mentionDT) // Sort by latest date
                          )
                          .slice(0, 8) // Limit to 8 entries
                          .map((article, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  className="table-source"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    gap: "8px",
                                  }}
                                >
                                  {article.logo_url && (
                                    <img
                                      className="logo-img"
                                      src={article.logo_url}
                                      alt={`${article.station} logo`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid",
                                        padding: "1px",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    {article.station}
                                  </span>
                                </div>
                              </td>
                              <td
                                className="snippet"
                                style={{ width: "150px" }}
                              >
                                "
                                {article.mention
                                  ? `${article.mention
                                      .replace(/^Summary:\s*/, "")
                                      .split(" ")
                                      .slice(0, 15)
                                      .join(" ")}...`
                                  : "No content available"}
                                "
                              </td>
                              <td>
                                <div>
                                  <a
                                    href={article.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    "
                                    {article.mention
                                      ? (() => {
                                          const cleanedText =
                                            article.mention.replace(
                                              /^Summary:\s*/,
                                              ""
                                            ); // Remove "Summary:"
                                          const words = cleanedText.split(" "); // Split into words
                                          const shortenedText = words
                                            .slice(3, 8)
                                            .join(" "); // Remove first 2 words, keep next 5
                                          return (
                                            shortenedText ||
                                            "No content available"
                                          ); // Ensure fallback text
                                        })()
                                      : "No content available"}
                                    ..."⤴
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="station-type">
                                  {article.stationType}
                                </div>
                              </td>
                              <td>{article.country}</td>
                              <td style={{ fontWeight: "500" }}>
                                {new Date(
                                  article.mentionDT
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                <div className="sentiment-container">
                                  {article.sentiment > 0 ? (
                                    <div className="sentiment positive">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsUp className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Positive
                                      </span>
                                    </div>
                                  ) : article.sentiment < 0 ? (
                                    <div className="sentiment negative">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsDown className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Negative
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="sentiment neutral">
                                      <div className="sentiment-icon-container">
                                        <MdOutlineSentimentNeutral className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Neutral
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                {article.ave
                                  ? `${article.ave.toFixed(2)} BWP`
                                  : "-"}
                              </td>
                              <td>
                                <div className="icon-menu">
                                  <button
                                    className="icon-button"
                                    onClick={() =>
                                      handleMenuClick("broadcast-media", index)
                                    }
                                  >
                                    <FaEllipsisV />
                                  </button>
                                  <div
                                    id={`broadcast-media-menu-${index}`}
                                    className="menu-options"
                                    onMouseLeave={() =>
                                      handleMenuClose("broadcast-media", index)
                                    }
                                  >
                                    <a href={article.url} download>
                                      Download
                                    </a>
                                    <a
                                      href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                    >
                                      Send Article
                                    </a>
                                    {user.role === "super_admin" && (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDelete(
                                            article._id,
                                            "broadcasts"
                                          );
                                        }}
                                        className="delete-link"
                                      >
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div style={{ textAlign: "center", marginTop: "25px" }}>
                <Link
                  to={`/media/broadcast/${orgId}`}
                  className="see-more-button"
                >
                  More <FaAngleDown className="more-icon" />
                </Link>
              </div>
            </div>

            {/* Table Section for Print Media Articles */}
            <h3 style={{ fontSize: "16px" }}>Print Media Articles</h3>
            <div className="section">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <div className="table-container">
                  <table className="articles-table">
                    <thead>
                      <tr>
                        <th> Source</th>
                        <th>Title</th>
                        <th>Country</th>
                        <th>Date Posted</th>
                        <th>Sentiment</th>
                        <th>News Section</th>
                        <th>AVE</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPrintMediaArticles.length === 0 ? (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            No print articles found yet for this organization.
                          </td>
                        </tr>
                      ) : (
                        [...filteredPrintMediaArticles]
                          .sort(
                            (a, b) =>
                              new Date(b.publicationDate) -
                              new Date(a.publicationDate)
                          )
                          .slice(0, 8) // Limit to 8 entries
                          .map((article, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  className="table-source"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    gap: "8px",
                                  }}
                                >
                                  {article.logo_url && (
                                    <img
                                      className="logo-img"
                                      src={article.logo_url}
                                      alt={`${article.publication} logo`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid",
                                        padding: "1px",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    {article.publication}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <a
                                  href={article.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.headline}⤴
                                </a>
                              </td>
                              <td>{article.country}</td>
                              <td>
                                {new Date(
                                  article.publicationDate
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                <div className="sentiment-container">
                                  {article.sentiment > 0 ? (
                                    <div className="sentiment positive">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsUp className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Positive
                                      </span>
                                    </div>
                                  ) : article.sentiment < 0 ? (
                                    <div className="sentiment negative">
                                      <div className="sentiment-icon-container">
                                        <LuThumbsDown className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Negative
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="sentiment neutral">
                                      <div className="sentiment-icon-container">
                                        <MdOutlineSentimentNeutral className="sentiment-icon" />
                                      </div>
                                      <span className="sentiment-text">
                                        Neutral
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div
                                  className="station-type"
                                  style={{ fontWeight: "500" }}
                                >
                                  {article.section}
                                </div>
                              </td>
                              <td>
                                {article.ave
                                  ? `${article.ave.toFixed(2)} BWP`
                                  : "-"}
                              </td>
                              <td>
                                <div className="icon-menu">
                                  <button
                                    className="icon-button"
                                    onClick={() =>
                                      handleMenuClick("print-media", index)
                                    }
                                  >
                                    <FaEllipsisV />
                                  </button>
                                  <div
                                    id={`print-media-menu-${index}`}
                                    className="menu-options"
                                    onMouseLeave={() =>
                                      handleMenuClose("print-media", index)
                                    }
                                  >
                                    <a href={article.url} download>
                                      Download
                                    </a>
                                    <a
                                      href={`mailto:?subject=Interesting Article&body=${article.url}`}
                                    >
                                      Send Article
                                    </a>
                                    {user.role === "super_admin" && (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDelete(
                                            article._id,
                                            "printmedias"
                                          );
                                        }}
                                        className="delete-link"
                                      >
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div style={{ textAlign: "center", marginTop: "25px" }}>
                <Link to={`/media/print/${orgId}`} className="see-more-button">
                  More <FaAngleDown className="more-icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //{" "}
  );
}

export default Dashboard;
